import { Component, OnInit, Input, Inject } from '@angular/core';
import { Equipment } from 'src/app/model/mdtdb/models';
import { MatTableDataSource, MatCheckboxChange, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { DeleteObjectComponent } from '../delete-object/delete-object.component';
import { DialogResponse } from 'src/app/view/dialogResponse';
import { DialogService } from 'src/app/service/dialog.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-equipment-selection',
  templateUrl: './equipment-selection.component.html',
  styleUrls: ['./equipment-selection.component.css']
})
export class EquipmentSelectionComponent implements OnInit {
  private _equipments: Equipment[] = new Array();
  private _selectedEquipments: Equipment[] = new Array();

  private _addList: Equipment[] = new Array();
  private _removeList: Equipment[] = new Array();

  private _dataSource = new MatTableDataSource<Equipment>();
  private _displayedColumns = ['select', 'licencePlate'];
  selection = new SelectionModel<Equipment>(true, []);

  constructor(@Inject(MAT_DIALOG_DATA) private _data: any,
              private _dialogRef: MatDialogRef<DeleteObjectComponent>,
              public globals: Globals) {
                this._equipments = _data.equipments;
                this._selectedEquipments = _data.selectedEquipments;
               }

  ngOnInit() {
    if (this.equipments) {
      // add equipments to datasource for table
      this._dataSource.data = this.equipments;

      this.initializeArrays();

      // Initialize checkboxes for selected equipments
      this.initializeCheckboxes();
    }
  }

  /** Return the selected ids for given equipments. */
  public static GetSelectedEquipmentIds(equipments: Equipment[]): string[] {
    var result = new Array();

    if (equipments && equipments.length) {
      result = equipments.map(e => e.id);
    }

    return result;
  }

  public GetSelectedEquipments(): Equipment[] {
    return this.selectedEquipments;
  }

  /*
   *  Method for adding and deleting logic.
   */
  public analyzeCheckboxClick(event: MatCheckboxChange, row: Equipment) {
    if (event.checked) {
      this.selection.select(row);
      // selected
      if (!this.selectedEquipments.find(e => e.id === row.id)) {
        this._addList.push(row);
      }

      // remove item from remove list
      this._removeList = this._removeList.filter(obj => obj.id !== row.id);
    } else {
      this.selection.deselect(row);
      // deselected
      if (this.selectedEquipments.find(e => e.id === row.id)) {
        this._removeList.push(row);
      }

      // remove item from add list
      this._addList = this._addList.filter(obj => obj.id !== row.id);
    }
  }

  public cancel() {
    var response: DialogResponse = {
      responseCode: DialogService.RESPONSE_CODE_CANCEL,
      data: null
    }

    // close dialog an return false to signalize that deletion was aborted
    this._dialogRef.close(response);
  }

  /** The label for the checkbox on the passed row */
  public checkboxLabel(row?: Equipment): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${this.equipments.indexOf(row) + 1}`;
  }

  /** Set checkbox check if equipment is already selected */
  private initializeCheckboxes() {
    if (this.selectedEquipments && this.selectedEquipments.length) {
      for (const e of this.selectedEquipments) {
        const checked = this._dataSource.data.find(equip => equip.id === e.id);
        this.selection.select(checked);
      }
    }
  }

  private initializeArrays() {
    if (!this.equipments) {
      this._equipments = new Array();
    }

    if (!this.selectedEquipments) {
      this._selectedEquipments = new Array();
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this._dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection.
   *  Add new selected equipments to addList and add unselected equipments to removeList
   */
  public masterToggle() {
    if (this.isAllSelected()) {
      // remove all items from addList
      this._addList = new Array();

      // add selected equipments to remove list
      this._removeList = this.selectedEquipments;

      this.selection.clear();
    } else {
      // remove all items from remove list
      this._removeList = new Array(),
      // remove all items from add list
      this._addList = new Array();

      this._dataSource.data.forEach(row => {
        // check if selected row is not already in selected equipments
        if (!this.selectedEquipments.find(x => x.id == row.id)) {
          // add new selected equipment do add list
          this._addList.push(row);
        }

        this.selection.select(row);
      })
    }
  }

  /** Delete selected equipment group */
  public save() {
    var response: DialogResponse = {
      responseCode: DialogService.RESPONSE_CODE_SUCCESS,
      data: this.selection.selected
    }

    // close dialog an return true to signalize that deletion was submitted
    this._dialogRef.close(response);
  }

  // getters
  public get addList(): Equipment[] {
    return this._addList;
  }

  public get dataSource(): MatTableDataSource<Equipment> {
    return this._dataSource;
  }

  public get displayedColumns(): string[] {
    return this._displayedColumns;
  }

  public get equipments(): Equipment[] {
    return this._equipments;
  }

  public get removeList(): Equipment[] {
    return this._removeList;
  }

  public get selectedEquipments(): Equipment[] {
    return this._selectedEquipments
  }
}
