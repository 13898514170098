import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { EditLicenceplateComponent } from 'src/app/component/dialogs/edit-licenceplate/edit-licenceplate.component';
import { TachographDataComponent } from 'src/app/component/dialogs/tachograph-data/tachograph-data.component';
import { RadiusAlarmComponent } from 'src/app/component/dialogs/tracking/radius-alarm/radius-alarm.component';
import { RoutingComponent } from 'src/app/component/dialogs/tracking/routing/routing.component';
import { SimTrackingComponent } from 'src/app/component/dialogs/tracking/sim-tracking/sim-tracking.component';
import { Definitions } from 'src/app/definitions';
import { Status } from 'src/app/enumeration/status';
import { DeviceService } from 'src/app/generated-services/device.service';
import { GeozoneService } from 'src/app/generated-services/geozone.service';
import { GroupService } from 'src/app/generated-services/group.service';
import { Globals } from 'src/app/globals';
import { Geometry } from 'src/app/model/Geometry';
import { TripDataPosition } from 'src/app/model/mdtdatabase/tripDataPosition';
import { Equipment } from 'src/app/model/mdtdb/equipment';
import { EquipmentGroup } from 'src/app/model/mdtdb/equipmentGroup';
import { EquipmentGroupContext } from 'src/app/model/mdtdb/equipmentGroupContext';
import { Geozone } from 'src/app/model/mdtdb/geozone';
import { TripDataLast } from 'src/app/model/mdtdb/tripDataLast';
import { TripDataPositionLast } from 'src/app/model/mdtdb/tripDataPositionLast';
import { ProjectCommon } from 'src/app/project-common.class';
import { AuthService } from 'src/app/service/auth.service';
import { DialogService } from 'src/app/service/dialog.service';
import { InfoService } from 'src/app/service/info.service';
import { LanguageService } from 'src/app/service/language.service';
import { NavigationService } from 'src/app/service/navigation.service';
import { RoutingService } from 'src/app/service/routing.service';
import { ValidationService } from 'src/app/service/validation.service';
import { DialogResponse } from 'src/app/view/dialogResponse';
import { TrackingEquipmentEntry } from 'src/app/view/trackingEquipmentEntry';
import { GoogleMapComponent } from '../../google-map/google-map.component';
import { FleetComponent } from '../fleet.component';
declare var MarkerClusterer:any;

interface TableEntry {
  value?: any;
  description?: any;
  color?: string;
  title?: string;
}

interface TrackingEquipmentTableEntry {
  entry: TrackingEquipmentEntry;
  licencePlate?: TableEntry;
  ignitionIdentifier?: TableEntry;
  // address?: TableEntry;
  position?: TableEntry;
  lastIncomingData?: TableEntry;
  lastStart?: TableEntry;
  lastStop?: TableEntry;
  mileage?: TableEntry;
  // batteryVoltage?: TableEntry;
}

@Component({
  selector: 'app-fleet-list',
  templateUrl: './fleet-list.component.html',
  styleUrls: ['./fleet-list.component.css']
})
export class FleetListComponent implements OnInit {
  @Input() refreshData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() mapComponent: any;

  showAddress: boolean = false;
  mileagePermission: boolean = false;
  tachographPermission: boolean = false;
  positionsPermission: boolean = true;
  addressPermission: boolean = false;

  private _style = getComputedStyle(document.body);
  public showGeozones: boolean = false;
  // Side navigation params
  public selectedEntry: TrackingEquipmentEntry;
  public oldSelectedEntry: TrackingEquipmentEntry;
  dataTableContainerToogled: boolean = true;
  // @ViewChild(MatSort, {static: false}) sort: MatSort;
  private sort: MatSort;
  @ViewChild(MatSort, {static: false}) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }
  private _dateCompareList: Date[] = new Array();

  // auto refresh data after 100 seconds
  // statusInfosLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private static MAX_ZOOM_LEVEL = 17;

  // map components
  // Google maps params
  markerCluster: any;
  clusterActive: boolean = true;
  bounds: any;
  markers: any[];
  trafficActive: boolean = true;

  // Equipment parameters
  private _equipmentGroups: EquipmentGroup[] = new Array();
  private _data: TrackingEquipmentEntry[] = new Array();
  private _tableData: TrackingEquipmentTableEntry[] = new Array();
  private _selectedEntries: TrackingEquipmentTableEntry[] = new Array();
  private _selectedEntriesSearchCopy: TrackingEquipmentTableEntry[] = new Array();
  private _geozones: Geozone[] = new Array();

  deviceDataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  addressesLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // test commend
  // tracking table
  // public trackingDataSource = new MatTableDataSource<TrackingEquipmentEntry>();
  public trackingDataSource = new MatTableDataSource<TrackingEquipmentTableEntry>();
  public displayedTrackingColumns = ['edit', 'licencePlate', 'deviceSerial', 'position', 'lastIncomingData', 'lastStart', 'lastStop', 'batteryVoltage', 'actions'];

  constructor(private _authService: AuthService,
              private _dialogService: DialogService,
              private _deviceService: DeviceService,
              private _editLicencePlateDialog: MatDialog,
              private _geozoneService: GeozoneService,
              public globals: Globals,
              private _groupService: GroupService,
              private _infoService: InfoService,
              private _languageService: LanguageService,
              private _navigationService: NavigationService,
              private _radiusAlarm: MatDialog,
              private _routingDialog: MatDialog,
              private _tachographDialog: MatDialog,
              private _validationService: ValidationService) {
              }

  async ngOnInit() {
    this.refreshData.subscribe((result: boolean) => {
      if (result) {
        this.mileagePermission = this._authService.hasPermission(Globals.ID_ACL_MILEAGE);
        this.addressPermission = this._authService.hasPermission(Globals.ID_ACL_REVERSE_GEOCODING);
        console.log(this.addressPermission);
        if (this.mileagePermission && !this.displayedTrackingColumns.includes('mileage')) this.displayedTrackingColumns.splice(7, 0, "mileage");
        if (this.addressPermission && !this.displayedTrackingColumns.includes('address')) this.displayedTrackingColumns.splice(2, 0, "address");
        this.tachographPermission = this._authService.hasPermission(Globals.ID_ACL_TACHOGRAPH);
        this.positionsPermission = !this._authService.permissionDenied(Globals.ID_ACL_POSITIONS);
        this.initDateCompareList();
        this.initializeEquipmentWorkflow(true);
      }
    });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }

  private initDateCompareList() {
    this._dateCompareList = new Array();
    var today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    this._dateCompareList.push(today);
    var lastSevenDays = new Date();
    lastSevenDays.setDate(lastSevenDays.getDate() - 7);
    lastSevenDays.setHours(0);
    lastSevenDays.setMinutes(0);
    lastSevenDays.setSeconds(0);
    this._dateCompareList.push(lastSevenDays);
  }

  setDataSourceAttributes() {
    this.trackingDataSource.sortingDataAccessor = (item, property) => {
      switch(property) {
        case 'licencePlate': return item.entry.licencePlate;
        case 'position': if (item.position) return new Date(item.entry.position.recordingDatetime).getTime();
        case 'lastIncomingData': if (item.entry.lastIncomingData) return new Date(item.entry.lastIncomingData).getTime();
        case 'lastStart': if (item.entry.lastTrip) return item.entry.lastTrip.beginDatetime;
        case 'batteryVoltage': if (item.entry.deviceStatus) return item.entry.deviceStatus.batteryVoltage;
        case 'mileage': if (item.entry.lastTrip) return item.entry.lastTrip.mileageEnd;
        default: return item[property];
      }
    };
    this.trackingDataSource.sort = this.sort;
  }

  public selectView() {
    var fleetList = document.getElementById("fleet-data-container");
    this.showGeozones = !this.showGeozones;

    if (this.showGeozones) {
      fleetList.style.display = "none";

      if (!this._geozones || this._geozones.length < 1) {
        this.addGeozones();
      }
    } else {
      fleetList.style.display = "block";
    }
  }

  public selectCluster() {
    console.log('select cluster');

    if (this.clusterActive) {
      this.markerCluster.clearMarkers();
      this.markerCluster = undefined;
      this.markers.forEach(x => x.setMap(this.mapComponent.map));
      this.clusterActive = false;
    } else {
      this.createCluster(this.mapComponent.map, this.markers);
    }
  }

  public selectTraffic() {
    console.log('select traffic');

    this.trafficActive = !this.trafficActive;
    this.mapComponent.setTrafficLayer(this.trafficActive);
  }

  private addGeozones() {
    this._geozoneService.geozoneGetForCustomer(this._authService.session.customerId, Status.ACTIVE).toPromise().then((zones: Geozone[]) => {
      if (zones) {
        this._geozones = zones;

        this.printZones();
      }
    });
  }

  private setSelectedEntries(entries: TrackingEquipmentTableEntry[]) {
    this._selectedEntries = entries;
    this._selectedEntriesSearchCopy = this._selectedEntries;
  }

  public toggleDataTableContainer() {
    this.dataTableContainerToogled = !this.dataTableContainerToogled;
  }

  /*
    Zoom at specific marker in google map, open info window, set equipment as selected and mark licence plate in fleet list colored.
    If entry is deselected, remove selected entry variable, zoom to fleet and remove color highlight.
  */
  public selectEntry(entry: TrackingEquipmentEntry) {
    if (entry && entry.position) {
      this.selectedEntry = entry;
      var newEl = document.getElementById(entry.equipmentId);
      var oldEl = this.oldSelectedEntry != null ? document.getElementById(this.oldSelectedEntry.equipmentId) : null;

      if (oldEl) oldEl.style.color = '';
      if (this.oldSelectedEntry && (this.oldSelectedEntry.deviceId === this.selectedEntry.deviceId)) {
        // zoom out and add old marker to cluster again
        if (entry.marker) this.markerCluster.addMarker(entry.marker);
        this.mapComponent.map.fitBounds(this.bounds);
      } else {
          if (this.oldSelectedEntry && this.oldSelectedEntry.marker) this.markerCluster.addMarker(this.oldSelectedEntry.marker);
          // change row color to blue to highlight selected entry
          newEl.style.color = this._style.getPropertyValue('--md_primary');

          // workaround to avoid marker in cluster for searching purpose
          this.markerCluster.removeMarker(entry.marker);
          entry.marker.setMap(undefined);
          this.zoomOnMap(entry.position);
          entry.marker.setMap(this.mapComponent.map);
      }
      this.oldSelectedEntry = (this.oldSelectedEntry === entry) ? null : entry;
    }
  }

  public getColor(value: any, identifier: string): string {
    if (identifier && value) {
      if (identifier === 'accurancy') {
        if (value === '3D Fix') return "green";
        else if (value === '2D Fix') return "orange";
        else if (value === 'No Fix') return "red";
      } else if (identifier === 'satellites') {
        if (value === 0 || value === 1 || value === 2) return "red";
        else if (value === 3 || value === 4 || value === 5) return "orange";
        else if (value >= 6) return "green";
      } else if (identifier === 'dateTime') {
        if (this._languageService.compareDates(value, this._dateCompareList[0]) === 1) return "green";
        else if (this._languageService.compareDates(value, this._dateCompareList[1]) === 0) return "red";
        else if (this._languageService.compareDates(value, this._dateCompareList[0]) === 0) return "orange";
      } else if (identifier === 'ignition') {
        if (value && value.lastIgnitionOn && value.lastIgnitionOff) {
          if (value.lastIgnitionOff < value.lastIgnitionOn) return "green";
          else return "red";
        }
      }
    }
  }

  public openGeozoneAlarmDialog(entry: TrackingEquipmentEntry) {
    this._geozoneService.geozoneGetForEquipment(entry.equipmentId, Status.ACTIVE).toPromise().then((geozones: Geozone[]) => {
      var alarmZone: Geozone = null;

      // filter geozones for 'RadiusAlarm' in identifier to exclude standard geozones
      if (geozones && geozones.length) {
        var alarmZones = geozones.filter(x => x.description.includes(GeozoneService.ALARM_IDENTIFIER));

        if (alarmZones && alarmZones.length) {
          alarmZone = alarmZones[0];
        }
      }

      const dialogRef = this._radiusAlarm.open(RadiusAlarmComponent, {
        data: {
          geozone: alarmZone,
          entry: entry
        }
      });
    }).catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
    });
  }

  private reloadDataSource() {
    this.trackingDataSource.data = this._selectedEntries;
  }

  /** Open existing equipment group form */
  public openLicencePlateEditingDialog(entry: TrackingEquipmentEntry) {
    // open customer selection dialog
    const dialogRef = this._editLicencePlateDialog.open(EditLicenceplateComponent, {
      data: {
        entry: entry
      }
    });

    // wait for dialog is closed and action is done
    dialogRef.afterClosed().subscribe((response: DialogResponse) => {
      if (this._dialogService.evaluateResponse(response)) {
        this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_SUCCESS);
        entry.licencePlate = response.data;
        this.reloadDataSource();
      }
    });
  }

  /* Open dialog for routing options for selected equipment */
  public openRoutingDialog(entry: TrackingEquipmentEntry) {
    const dialogRef = this._routingDialog.open(RoutingComponent, {
      data: entry
    });
  }

  public openTachographData(entry: TrackingEquipmentEntry) {
    const dialogRef = this._tachographDialog.open(TachographDataComponent, {
      data: entry.equipmentId
    });
  }

  public openSimTracking(entry: TrackingEquipmentEntry) {
    const dialogRef = this._tachographDialog.open(SimTrackingComponent, {
      data: {
        serial: entry.deviceSerial,
        licencePlate: entry.licencePlate,
        deviceId: entry.deviceId,
        endpointId: entry.whereverSimEndpointId
      }
    });
  }

  public zoomOnMap(position: TripDataPosition) {
    var bounds = new google.maps.LatLngBounds();
    var zoom = FleetListComponent.MAX_ZOOM_LEVEL;

    if (position) {
      // create bound for single marker
      const marker = new google.maps.LatLng(position.latitude, position.longitude);
      bounds.extend(marker);

      // zoom to bounds
      this.mapComponent.map.fitBounds(bounds);
      if (position) this.mapComponent.map.setZoom(zoom);
    } else {
      this.mapComponent.map.fitBounds(this.bounds);
    }
  }

  private printZones() {
    for (const zone of this._geozones) {
      var geometry: Geometry = new Geometry();
      geometry.setGeozone(zone);

      for(let p of geometry.coordinates) {
        this.bounds.extend(p);
      }

      // Construct the polygon.
      var myPolygon = new google.maps.Polygon({
        paths: geometry.coordinates,
        draggable: false, // turn off if it gets annoying
        editable: false,
        strokeColor: Definitions.DEFAULT_MAP_POLY_COLOR,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: Definitions.DEFAULT_MAP_POLY_COLOR,
        fillOpacity: 0.35
      });

      var infoWindow = new google.maps.InfoWindow({
        content: zone.description,
        position: geometry.coordinates[0]
      });

      var map = this.mapComponent.map;
      myPolygon.addListener('click', function() {
        infoWindow.open(map);
      });
      infoWindow.open(this.mapComponent.map);

      myPolygon.setMap(this.mapComponent.map);
      this.mapComponent.shapes.push(myPolygon);
      this.mapComponent.zoneCoordinates = geometry.coordinates;
    }

    this.mapComponent.map.fitBounds(this.bounds);
  }

  /** Handle input of search box for selecting entries. */
  public searchVehicle(filterValue: string) {
    if (filterValue) {
      this._selectedEntries = this._selectedEntriesSearchCopy.filter(x => x.entry.licencePlate.toLocaleLowerCase().includes(filterValue.trim().toLowerCase()));
    } else this._selectedEntries = this._selectedEntriesSearchCopy;

    this.reloadDataSource();
  }

  /** Handle input of search box for selecting entries. */
  public searchDevice(filterValue: string) {
    if (filterValue) {
      this._selectedEntries = this._selectedEntriesSearchCopy.filter(x => x.entry && x.entry.deviceSerial && x.entry.deviceSerial.toLocaleLowerCase().includes(filterValue.trim().toLowerCase()));
    } else this._selectedEntries = this._selectedEntriesSearchCopy;

    this.reloadDataSource();
  }

  /* Create default equipment group containing all equipments before loading the groups */
  private createDefaultEquipmentGroup() {
    // create group object with id = 0
    const group: EquipmentGroup = {
      id: '0',
      groupName: this.globals.languageTable_res.get(2038),
      equipmentHasEquipmentGroup: new Array()
    }

    // add all equipments of customer to default group
    if (this._data && this._data.length) {
      for (const e of this._data) {
        const eheg: any = {
          equipmentGroup: group,
          equipmentId: e.equipmentId
        };

        group.equipmentHasEquipmentGroup.push(eheg);
      }
    }

    // add default group to equipment groups array
    this._equipmentGroups.push(group);
  }

  /*
    Create a list of entry data for all equipments.
  */
  private createEquipmentEntries() {
    var selectedIds = new Array();
    // store selected equip ids for filtering after refresh
    selectedIds = this._selectedEntries.map(x => x.entry.equipmentId);
    this._selectedEntries = new Array();
    this._tableData = new Array();

    // create new table entries for performance issues to avoid calling methods in html template
    this._data.forEach(x => {
      var n: TrackingEquipmentTableEntry = {
        entry: x
      };

      n.licencePlate = {
        value: x.licencePlate,
        description: '',
        color: '',
        title: x.deviceSerial
      };

      if (x.position) {
        n.position = {
          value: x.position.recordingDatetime,
          description: this._languageService.getLocaleDateTime(x.position.recordingDatetime),
          color: this.getColor(x.position.recordingDatetime, 'dateTime'),
          title: this.getAccurancyTitle(x.position.accurancy, x.position.satellites)
        };
      }

      if (x.lastTrip) {
        n.lastStart = {
          value: x.lastTrip.beginDatetime,
          description: this._languageService.getLocaleDateTime(x.lastTrip.beginDatetime),
          color: '',
          title: ''
        };

        n.lastStop = {
          value: this.getTripEnd(x.lastTrip),
          description: '',
          color: '',
          title: ''
        };

        n.mileage = {
          value: this._languageService.numberWithCommas(this._languageService.round(x.lastTrip.mileageEnd, 0)),
          description: '',
          color: '',
          title: ''
        };
      }

      this._tableData.push(n);
    });

    // set selected entries
    if (selectedIds && selectedIds.length > 0) {
      this.setSelectedEntries(this._tableData.filter(x => selectedIds.includes(x.entry.equipmentId)));
    } else {
      this.setSelectedEntries(this._tableData);
    }

    this.dataLoaded.next(true);
    this.reloadDataSource();
  }

  private loadAddresses() {
    this.addressesLoaded.next(false);
    if (this.addressPermission) {
      this._deviceService.deviceGetTrackingAddresses(this._authService.session.customerId)
      .toPromise().then((data: TrackingEquipmentEntry[]) => {
        if (data && data.length) {
          data.forEach(x => {
            if (x.address) {
              var f = this._data.find(t => t.equipmentId === x.equipmentId);
              if (f) f.address = x.address;
            }
          });

          this.reloadDataSource();
        }
        this.addressesLoaded.next(true);
      }).catch((err: HttpErrorResponse) => {
        this.addressesLoaded.next(true);
        this._validationService.validateHttpErrorResponse(err);
      });
    } else {
      this.addressesLoaded.next(true);
    }
  }

  /* Create google maps Marker from position infos */
  private createMarker(licencePlate: string, latLng: google.maps.LatLng, position: any, address: string, deviceType: string): google.maps.Marker {

    var arrowRight = 'M18,10c0-4.42-3.58-8-8-8s-8,3.58-8,8s3.58,8,8,8S18,14.42,18,10z M10,10.75H7v-1.5h3V7l3,3l-3,3V10.75z';

    const infoWindow = new google.maps.InfoWindow({
      content: '<div id="content">' + '<h3><u><strong>' + licencePlate + '</strong></u></h3>'
                + '<br>' + this.globals.languageTable_res.get(2047) + ': '+ Math.round(position.speed? position.speed : 0) + ' km/h'
                + '<br>' + this.globals.languageTable_res.get(2046) + ': ' + position.height + ' m'
                + '<br>' + position.latitude + ' - ' + position.longitude
                + '<br>' + this.globals.languageTable_res.get(2033) + ': ' + position.satellites
                + '<br>' + this.globals.languageTable_res.get(2034) + ': ' + position.accurancy + '</div>'
                + '<br><strong>' + this._languageService.getLocaleDateTime(position.recordingDatetime) + '</strong></div>'
                // + '<br><strong>' + address + '</strong></div>'
    });

    var marker;

    if (deviceType && deviceType.toLowerCase() === 'fmc640') {
      var color = 'red';
      if (position.speed == 0) color = 'red';
      else if (position.speed < 20) color = 'orange';
      else if (position.speed < 50) color = 'blue';
      else if (position.speed > 50) color = 'green';

      var markerImage = {
        rotation: position.course - 90,
        path: arrowRight,
        fillOpacity: 1,
        fillColor: color,
        strokeWeight: 1,
        strokeColor: color,
        scale: 1.5
      };

      marker = new google.maps.Marker({
        position: latLng,
        title: licencePlate,
        icon: markerImage
      });
    } else {
      marker = new google.maps.Marker({
        position: latLng,
        title: licencePlate
      });
    }

    // add info window to marker
    var infoWindowLicencePlate = new google.maps.InfoWindow({
      content: licencePlate
    });

    marker.addListener('click', function() {
      infoWindowLicencePlate.open(this.getMap(), marker);
    });
    infoWindowLicencePlate.open(this.mapComponent.map,marker);

    marker.addListener('mouseover', function() {
      infoWindowLicencePlate.close();
      infoWindow.open(this.getMap(), marker);
    });
    marker.addListener('mouseout', function() {
      infoWindow.close();
      infoWindowLicencePlate.open(this.getMap(), marker);
    });

    return marker;
  }

  /* Get all equipment groups for customer */
  private initializeEquipmentGroups() {
    this._equipmentGroups = new Array();
    this.createDefaultEquipmentGroup();

    this._groupService.groupGetEquipmentGroupContext(RoutingService.GROUP_CONTEXT_TRACKING).subscribe((context: EquipmentGroupContext) => {
      if (context) {
        this._groupService.groupGetEquipmentGroupsForCustomer(this._authService.session.customerId, context.id, Status.ACTIVE).toPromise().then((groups: EquipmentGroup[]) => {
          if (groups && groups.length > 0) {
            // concat requested array of groups to already existing array of groups containing the default group with all equipments
            this._equipmentGroups = this._equipmentGroups.concat(groups);
            this._equipmentGroups.sort((a, b) => a.groupName.localeCompare(b.groupName));
          }
        }).catch((err: HttpErrorResponse) => {
          this._validationService.validateHttpErrorResponse(err);
        });
      }
    });
  }

  /*
    Load device and position infos for each equipment.
  */
  private initializeEquipmentWorkflow(fitBounds: boolean) {
    // receive equipments of customer from equipment service and load positions for all equipments
    this._deviceService.deviceGetTrackingData(this._authService.session.customerId)
    .toPromise().then((data: TrackingEquipmentEntry[]) => {
      if (data && data.length) {

        // remove positions
        if (!this.positionsPermission) {
          data.forEach(x => {
            x.position = undefined;
          });
        }
        this._data = data;

        if (this.addressPermission) {
          console.log('load addresses');
          this.loadAddresses();
        }

        this.loadDeviceData();
        this.createEquipmentEntries();
        this.initializeEquipmentGroups();
        this.initializeMap(fitBounds);
      }
    }).catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
    });
  }

  private loadDeviceData() {
    this.deviceDataLoaded.next(false);
    this._deviceService.deviceGetTrackingDeviceData(this._authService.session.customerId)
    .toPromise().then((data: TrackingEquipmentEntry[]) => {
      if (data && data.length) {
        data.forEach(x => {
          if (x) {
            var f = this._tableData.find(t => t.entry.deviceId === x.deviceId);
            if (f && f.entry) {
              f.entry.deviceStatus = x.deviceStatus;
              if (f.entry.deviceStatus && f.entry.deviceStatus.recordingTimestamp) f.entry.deviceStatus.recordingTimestamp = this._languageService.getLocaleDateTime(f.entry.deviceStatus.recordingTimestamp);
              f.entry.lastIgnitionOff = x.lastIgnitionOff,
              f.entry.lastIgnitionOn = x.lastIgnitionOn,
              f.entry.lastConnection = x.lastConnection,
              f.entry.lastIncomingData = x.lastIncomingData,
              f.ignitionIdentifier = {
                value: '',
                title: this.getIgnitionTitle(f.entry.lastIgnitionOn, f.entry.lastIgnitionOff),
                color: this.getColor(f.entry, 'ignition'),
                description: ''
              };

              if (f.entry.lastIncomingData) {
                f.lastIncomingData = {
                  value: x.lastIncomingData,
                  description: this._languageService.getLocaleDateTime(x.lastIncomingData),
                  color: '',
                  title: ''
                };
              }
            }
          }
        });

        this.reloadDataSource();
      }

      this.deviceDataLoaded.next(true);
    }).catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
	  this.deviceDataLoaded.next(true);
    });
  }

  /*
    Initialize google map component depending on given positions
    to fit map bounds and set marker clusterer.
  */
  private initializeMap(fitBounds: boolean) {
    var map = this.mapComponent.map;

    if (!this.bounds) this.bounds = new google.maps.LatLngBounds();

    if (map && this._selectedEntries) {
      // remove markers from map
      if (this.markerCluster) {
        this.markerCluster.clearMarkers();
        // this.markerCluster.setMap(null);
      }

      this.bounds = new google.maps.LatLngBounds();
      if (this.markers && this.markers.length) {
        this.markers.forEach(x => x.setMap(undefined));
      }
      this.markers = new Array();

      for (var e of this._selectedEntries) {
        if (e && e.entry && e.entry.position) {
          var ltLng = new google.maps.LatLng(e.entry.position.latitude, e.entry.position.longitude)
          this.bounds.extend(ltLng);
          // console.log(e.licencePlate + ' ' + e.position.course);
          e.entry.marker = this.createMarker(e.entry.licencePlate, ltLng, e.entry.position, e.entry.address, e.entry.deviceType);
          this.markers.push(e.entry.marker);
        }
      }

      if (this.clusterActive) {
        this.createCluster(map, this.markers);
      } else {
        if (this.markers && this.markers.length) {
          this.markers.forEach(x => x.setMap(this.mapComponent.map));
        }
      }

      if (fitBounds && this.positionsPermission) {
        this.mapComponent.map.fitBounds(this.bounds);
      }

    }
  }

  public createCluster(map: any, markers: any[]) {
    var infoWindow = new google.maps.InfoWindow();

    this.markerCluster = new MarkerClusterer(map, markers,
      {
        imagePath: 'assets/images/marker-clustering/m',
        zoomOnClick: false
      });

      // print vehicles in cluster on click
      google.maps.event.addListener(this.markerCluster, "clusterclick", function(cluster) {

        var markers = cluster.getMarkers();
        var content = "";
        for(var i = 0; i < markers.length; i++) {
            content = content + markers[i].getTitle() + '<br>';
        }

        infoWindow.setContent(content);
        infoWindow.setPosition(cluster.getCenter());
        infoWindow.open(map);
    });

    this.clusterActive = true;
  }

  /* Method that loads equipments of selected group and replaces the current selected equipments array with loaded equipments */
  public selectGroup(group: EquipmentGroup) {
    // remove the current map location to recalculate bounds of new equipment list
    ProjectCommon.RemoveMapLocation();
    if (group) {
      // check if selected group is default group
      if(group.id == '0') {
        // default group is selected - show all equipments
        this.setSelectedEntries(this._tableData)
        this.reloadDataSource();
        this.initializeMap(true);
      } else {
        // load equipments of selected group
        this._groupService.groupGetEquipmentsOfEquipmentGroup(group.id, Status.ACTIVE).toPromise().then((equipmentsOfGroup: Equipment[]) => {
          if (equipmentsOfGroup) {
            // show only equipments of group
            this.setSelectedEntries(equipmentsOfGroup.map(x => this._tableData.find(e => e.entry.equipmentId == x.id)));
            this.reloadDataSource();
            this.initializeMap(true);
          }
        }).catch((err: HttpErrorResponse) => {
          this._validationService.validateHttpErrorResponse(err);
        });
      }
    }
  }

  // getters
  public get equipmentGroups(): EquipmentGroup[] {
    return this._equipmentGroups;
  }

  public get languageService(): LanguageService {
    return this._languageService;
  }

  public get navigationService(): NavigationService {
    return this._navigationService;
  }

  public get selectedEntries(): TrackingEquipmentTableEntry[] {
    return this._selectedEntries;
  }

  public getTripEnd(trip: TripDataLast) {
    if (trip) {
      var end: Date = new Date(trip.beginDatetime);
      end.setSeconds(end.getSeconds() + trip.sumDuration);
      end.setMinutes(end.getMinutes() - end.getTimezoneOffset());

      return this._languageService.getLocaleDateTime(end);
    }
  }

  public getIgnitionTitle(on: Date, off: Date) {
    if (on && off) {
      return this.globals.languageTable_res.get(2155) + ": " + this._languageService.getLocaleDateTime(on) + ", "
      + this.globals.languageTable_res.get(2156) + ": " + this._languageService.getLocaleDateTime(off);
    }
  }

  public getAccurancyTitle(accurancy: string, satellites: any) {
    if (accurancy) return accurancy + " - " + satellites + " " + this.globals.languageTable_res.get(2033);
  }

  public showMileage(lastTrip: TrackingEquipmentEntry) {
    if (lastTrip && this.mileagePermission) return true;
    return false;
  }

  public showTachograph(deviceType: string) {
    if (deviceType && deviceType.toLowerCase() === 'fmc640' && this.tachographPermission) return true;
    return false;
  }

  public getExportExcludingColumns() {
    var result = new Array();
    result.push(0);
    result.push(this.displayedTrackingColumns.length - 1);

    return result;
  }

  /* Check if whereversim endpoint id is available for whereversim API tracking or device type is oyster. */
  deviceHasSimTracking(entry: TrackingEquipmentEntry) {
    var result: boolean = false;

    if (entry) {
      if (entry.whereverSimEndpointId) result = true;
      else if (entry.deviceType && entry.deviceType.toLowerCase().includes('oyster')) result = true;
    }

    return result;
  }
}
