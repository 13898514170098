import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor() {}

    canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<boolean> {
      // Default - Method not called
      // Security proccess in auth.service (SessionValidation)
      return Observable.create(false);
      /*
      return this._authService.isLoggedIn
        .pipe(
          // check the value of the observable only once
          take(1),
          map((isLoggedIn: boolean) => {
            if (!isLoggedIn) {
              // false - redirect to login
              this._routingService.navigateToLogin();
              return false;
            }
            // access granted
            return true;
          })
        );
        */
    }
}
