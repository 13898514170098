import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Globals } from 'src/app/globals';
import * as $ from 'jquery';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css']
})
export class TimePickerComponent implements OnInit {

  @Input() interval: boolean = false;
  selectedInterval = 'day';
  intervalForm = new FormControl(this.selectedInterval);
  fromDate: Date = new Date(this.basicMinDate);
  toDate: Date = new Date(this.basicMaxDate);
  fromTime;
  toTime;

  constructor(public globals: Globals) { }

  ngOnInit() {
    this.fromTime = '00:00';
    this.toTime = '23:59';
  }

  // getters
  public getFromDate(): Date {
    return this.createDate($('#fromTime').val(), $('#fromDate').val());
  }

  public getToDate(): Date {
    return this.createDate($('#toTime').val(), $('#toDate').val());
  }

  public getInterval(): string {
    return this.intervalForm.value;
  }

  public getFromDateAsUnixTimestamp(): number {
    const result = Math.round(this.getFromDate().getTime() / 1000);

    return result;
  }

  public getToDateAsUnixTimestamp(): number {
    const result = Math.round(this.getToDate().getTime() / 1000);

    return result;
  }

  public get basicMinDate(): string {
    return this.dateToString(new Date('2017-01-01'));
  }

  public get basicMaxDate(): string {
    return this.dateToString(new Date());
  }

  /* If to date is already selected, maxDate = toDate to avoid wrong user input.
  If not, maxDate = basicMaxDate (today) */
  public get maxDate(): string {
    return this.dateToString(this.toDate);
  }

  /* If from date is already selected, minDate = fromDate.
      If not, minDate = basicMinDate (01.01.2017) */
  public get minDate(): string {
    return this.dateToString(this.fromDate);
  }

  public dateToString(date: Date): string {
    return date.toISOString().slice(0,10);
  }

  public createDate(time: string, date: string): Date {
    const tTime = time.split(':');
    // insert end time
    // tslint:disable-next-line:radix
    var result = new Date(date);
    result.setHours(parseInt(tTime[0]), parseInt(tTime[1]), 0);
    return result;
  }
}
