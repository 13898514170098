import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Globals } from 'src/app/globals';
import { ProjectCommon } from 'src/app/project-common.class';

@Component({
  selector: 'app-update-news',
  templateUrl: './update-news.component.html',
  styleUrls: ['./update-news.component.css']
})
export class UpdateNewsComponent implements OnInit {
  currentVersion: string = ProjectCommon.PORTAL_VERSION;

  constructor(private _dialogRef: MatDialogRef<UpdateNewsComponent>,
              public globals: Globals) { }

  ngOnInit() {
  }

  public cancel() {
    this._dialogRef.close();
  }
}
