/**
 * MdtDbWebService
 * This is a service for basic communication with database
 *
 * OpenAPI spec version: 1.0.0.0
 * Contact: info@moderndrive.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from './encoder';
import { Observable } from 'rxjs';
import { EquipmentGroup } from '../model/mdtdb/equipmentGroup';
import { EquipmentGroupContext } from '../model/mdtdb/equipmentGroupContext';
import { Configuration } from './configuration';
import { Equipment } from '../model/mdtdb//models';
import { AppConfigService } from '../app-config.service';
import { SessionHelper } from './sessionhelper';

@Injectable()
export class GroupService {

    protected basePath = Configuration.getServiceBasePath();
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, private appConfig: AppConfigService) {
        this.basePath = appConfig.getConfig().webservice.url;
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

	/**
     * Add an array of Equipment to the given EquipmentGroup.
     *
     * @param body
     * @param equipmentGroupId
     * @param creator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupAddEquipmentsToEquipmentGroup(body: Array<string>, equipmentGroupId: string, creator?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public groupAddEquipmentsToEquipmentGroup(body: Array<string>, equipmentGroupId: string, creator?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public groupAddEquipmentsToEquipmentGroup(body: Array<string>, equipmentGroupId: string, creator?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public groupAddEquipmentsToEquipmentGroup(body: Array<string>, equipmentGroupId: string, creator?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupAddEquipmentsToEquipmentGroup.');
        }

        if (equipmentGroupId === null || equipmentGroupId === undefined) {
            throw new Error('Required parameter equipmentGroupId was null or undefined when calling groupAddEquipmentsToEquipmentGroup.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (creator !== undefined && creator !== null) {
            queryParameters = queryParameters.set('creator', <any>creator);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Blob>(`${this.basePath}/api/Group/AddEquipmentsToEquipmentGroup/${encodeURIComponent(String(equipmentGroupId))}`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * @param body body
     * @param equipmentGroupContextId equipmentGroupContextId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupCreateEquipmentGroup(body: EquipmentGroup, equipmentGroupContextId?: number, observe?: 'body', reportProgress?: boolean): Observable<EquipmentGroup>;
    public groupCreateEquipmentGroup(body: EquipmentGroup, equipmentGroupContextId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EquipmentGroup>>;
    public groupCreateEquipmentGroup(body: EquipmentGroup, equipmentGroupContextId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EquipmentGroup>>;
    public groupCreateEquipmentGroup(body: EquipmentGroup, equipmentGroupContextId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupCreateEquipmentGroup.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (equipmentGroupContextId !== undefined && equipmentGroupContextId !== null) {
            queryParameters = queryParameters.set('equipmentGroupContextId', equipmentGroupContextId.toString());
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<EquipmentGroup>(`${this.basePath}/api/Group/CreateEquipmentGroup`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     *
     * @param equipmentGroupId equipmentGroupId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupDeleteEquipmentGroup(equipmentGroupId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public groupDeleteEquipmentGroup(equipmentGroupId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public groupDeleteEquipmentGroup(equipmentGroupId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public groupDeleteEquipmentGroup(equipmentGroupId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (equipmentGroupId === null || equipmentGroupId === undefined) {
            throw new Error('Required parameter equipmentGroupId was null or undefined when calling groupDeleteEquipmentGroup.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Blob>(`${this.basePath}/api/Group/DeleteEquipmentGroup/${encodeURIComponent(String(equipmentGroupId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Physical delete an array of Equipment from the given EquipmentGroup.
     *
     * @param body
     * @param equipmentGroupId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupDeleteEquipmentsFromEquipmentGroup(body: Array<string>, equipmentGroupId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public groupDeleteEquipmentsFromEquipmentGroup(body: Array<string>, equipmentGroupId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public groupDeleteEquipmentsFromEquipmentGroup(body: Array<string>, equipmentGroupId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public groupDeleteEquipmentsFromEquipmentGroup(body: Array<string>, equipmentGroupId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupDeleteEquipmentsFromEquipmentGroup.');
        }

        if (equipmentGroupId === null || equipmentGroupId === undefined) {
            throw new Error('Required parameter equipmentGroupId was null or undefined when calling groupDeleteEquipmentsFromEquipmentGroup.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Blob>(`${this.basePath}/api/Group/DeleteEquipmentsFromEquipmentGroup/${encodeURIComponent(String(equipmentGroupId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * @param context context
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupGetEquipmentGroupContext(context: string, observe?: 'body', reportProgress?: boolean): Observable<EquipmentGroupContext>;
    public groupGetEquipmentGroupContext(context: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EquipmentGroupContext>>;
    public groupGetEquipmentGroupContext(context: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EquipmentGroupContext>>;
    public groupGetEquipmentGroupContext(context: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (context === null || context === undefined) {
            throw new Error('Required parameter context was null or undefined when calling groupGetEquipmentGroupContext.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<EquipmentGroupContext>(`${this.basePath}/api/Group/GetEquipmentGroupContext/${encodeURIComponent(String(context))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     *
     * @param customerId customerId
     * @param equipmentGroupContextId equipmentGroupContextId
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupGetEquipmentGroupsForCustomer(customerId: string, equipmentGroupContextId?: number, status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EquipmentGroup>>;
    public groupGetEquipmentGroupsForCustomer(customerId: string, equipmentGroupContextId?: number, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EquipmentGroup>>>;
    public groupGetEquipmentGroupsForCustomer(customerId: string, equipmentGroupContextId?: number, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EquipmentGroup>>>;
    public groupGetEquipmentGroupsForCustomer(customerId: string, equipmentGroupContextId?: number, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling groupGetEquipmentGroupsForCustomer.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (equipmentGroupContextId !== undefined && equipmentGroupContextId !== null) {
            queryParameters = queryParameters.set('equipmentGroupContextId', equipmentGroupContextId.toString());
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', status);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<EquipmentGroup>>(`${this.basePath}/api/Group/GetEquipmentGroupsForCustomer/${encodeURIComponent(String(customerId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     *
     * @param equipmentGroupId equipmentGroupId
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupGetEquipmentsOfEquipmentGroup(equipmentGroupId: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Equipment>>;
    public groupGetEquipmentsOfEquipmentGroup(equipmentGroupId: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Equipment>>>;
    public groupGetEquipmentsOfEquipmentGroup(equipmentGroupId: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Equipment>>>;
    public groupGetEquipmentsOfEquipmentGroup(equipmentGroupId: string, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (equipmentGroupId === null || equipmentGroupId === undefined) {
            throw new Error('Required parameter equipmentGroupId was null or undefined when calling groupGetEquipmentsOfEquipmentGroup.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', status);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Equipment>>(`${this.basePath}/api/Group/GetEquipmentsOfEquipmentGroup/${encodeURIComponent(String(equipmentGroupId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Updates existing EquipmentGroup object.
     *
     * @param body body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupUpdateEquipmentGroup(body: EquipmentGroup, observe?: 'body', reportProgress?: boolean): Observable<EquipmentGroup>;
    public groupUpdateEquipmentGroup(body: EquipmentGroup, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EquipmentGroup>>;
    public groupUpdateEquipmentGroup(body: EquipmentGroup, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EquipmentGroup>>;
    public groupUpdateEquipmentGroup(body: EquipmentGroup, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupUpdate.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<EquipmentGroup>(`${this.basePath}/api/Group/UpdateEquipmentGroup`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

	/**
     * Updates equipment relations of existing EquipmentGroup object. Attribute modifiedBy is set from User modifier.
     *
     * @param body
     * @param geozoneId
     * @param equipmentGroupId
     * @param modifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupUpdateEquipmentRelationsOfEquipmentGroup(body: Array<string>, equipmentGroupId?: string, modifier?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public groupUpdateEquipmentRelationsOfEquipmentGroup(body: Array<string>, equipmentGroupId?: string, modifier?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public groupUpdateEquipmentRelationsOfEquipmentGroup(body: Array<string>, equipmentGroupId?: string, modifier?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public groupUpdateEquipmentRelationsOfEquipmentGroup(body: Array<string>, equipmentGroupId?: string, modifier?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupUpdateEquipmentRelationsOfEquipmentGroup.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (equipmentGroupId !== undefined && equipmentGroupId !== null) {
            queryParameters = queryParameters.set('equipmentGroupId', <any>equipmentGroupId);
        }
        if (modifier !== undefined && modifier !== null) {
            queryParameters = queryParameters.set('modifier', <any>modifier);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<boolean>(`${this.basePath}/api/Group/UpdateEquipmentRelationsOfEquipmentGroup/${encodeURIComponent(String(equipmentGroupId))}`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
