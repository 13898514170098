<div class="container">
  <mat-form-field class="date-container">
    <mat-label>{{globals.languageTable_res.get(2039)}}</mat-label>
    <span class="dark-text">
      <input matInput [matDatepicker]="fromPicker" [formControl]="fromDateForm" [min]="basicMinDate" [max]="maxDate" onkeydown="return false">
    </span>
    <mat-datepicker-toggle matSuffix [for]="fromPicker">
      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #fromPicker></mat-datepicker>
  </mat-form-field>
  <br>
  <input type="time" id="input" class="form-control" mdbInput [(ngModel)]="fromTime" class="time-container"/>
  <br>
  <br>
  <mat-form-field class="date-container">
    <mat-label>{{globals.languageTable_res.get(2040)}}</mat-label>
    <span class="dark-text">
      <input matInput [matDatepicker]="toPicker" [formControl]="toDateForm" [min]="minDate" [max]="basicMaxDate" onkeydown="return false">
    </span>
    <mat-datepicker-toggle matSuffix [for]="toPicker">
      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #toPicker></mat-datepicker>
  </mat-form-field>
  <br>
  <input type="time" id="input" class="form-control" mdbInput [(ngModel)]="toTime" class="time-container"/>
</div>
