import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { MatDialog, MatDrawer } from '@angular/material';
import { Customer } from 'src/app/model/mdtdb/models';
import { AuthService, LanguageService, RoutingService } from 'src/app/service/api';
import { CustomerService } from 'src/app/generated-services/api';
import { CustomerSelectionComponent } from 'src/app/component/dialogs/customer-selection/customer-selection.component';
import { ProjectCommon } from 'src/app/project-common.class';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidationService } from 'src/app/service/validation.service';
import { Globals } from 'src/app/globals';
import { UpdateNewsComponent } from 'src/app/component/dialogs/general/update-news/update-news.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent implements OnInit {
  @Input() appDrawer: MatDrawer;

  constructor(private _authService: AuthService,
              private _customerSelectionDialog: MatDialog,
              private _customerService: CustomerService,
              public globals: Globals,
              public languageService: LanguageService,
              private _routingService: RoutingService,
              private _validationService: ValidationService,
              private _updateNewsDialog: MatDialog,) {}

  ngOnInit() {
    console.log(this.languageService.getSelectedLang());
    // check if header is loaded without login before (session still active)
    // so language table has to be initialized
    if (this.globals.languageTable_res.size <= 0) {
      console.log('init language table;');
      this.languageService.initializeLanguageTables(this.languageService.getSelectedLang());
    }
  }


  public openUpdateNews() {
    const dialogRef = this._updateNewsDialog.open(UpdateNewsComponent);
  }


  public logout() {
    this._authService.logout();
  }

  public openCustomerSelection() {
    this._customerService.customerGetCustomersForUser(this._authService.loggedInUser.id).toPromise().then((data: any) => {
      const customers: Customer[] = data as Customer[];

      if (customers && customers.length) {
        // open customer selection dialog
        const dialogRef = this._customerSelectionDialog.open(CustomerSelectionComponent, {
          data: {
            customers
          }
        });
      }
    }).catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
    });
  }

  // getters
  public get loggedInUserName(): string {
    return this._authService.loggedInUser != undefined ?
     this._authService.loggedInUser.username : "";
  }

  public get routingService(): RoutingService {
    return this._routingService;
  }

  public get selectedCustomerName(): string {
    return this._authService.selectedCustomer != undefined ?
    this._authService.selectedCustomer.customerName : "";
  }

  public getVersion(): string {
    return ProjectCommon.PORTAL_VERSION;
  }

  public chooseLanguage(value: string) {
    this.languageService.setSelectedLang(value);

    // refresh page
    window.location.reload();
  }
}
