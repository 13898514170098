<div class="background-container">
  <div class="content">
    <div class="widget-container" style="width: 500px;">
      <mat-expansion-panel [expanded]="true" style="width: 100%">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2>{{globals.languageTable_res.get(2107)}}</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div style="width: 400px !important;">
          <div fxLayout="row" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-select [formControl]="reportCtrl" placeholder="{{globals.languageTable_res.get(2137)}}" #reportSelect>

                <mat-option *ngFor="let report of reports" [value]="report">
                  {{globals.languageTable_res.get(report)}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-select [formControl]="equipCtrl" placeholder="{{globals.languageTable_res.get(2136)}}" #select>
                <mat-option>
                  <ngx-mat-select-search [formControl]="equipFilterCtrl"></ngx-mat-select-search>
                </mat-option>

                <mat-option *ngFor="let equip of filteredEquips | async" [value]="equip">
                  {{equip.licencePlate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <br>

          <div fxLayout="row" fxLayoutAlign="space-around center">
            <input type="date" id="fromDate" name="trip-start" style="width: 130px;" value="{{basicFromDate}}" onkeydown="return false">
            <span style="width: 15px;"></span>
            -
            <span style="width: 15px;"></span>
            <input type="date" id="toDate" name="trip-end" style="width: 130px;" value="{{basicToDate}}" onkeydown="return false">
          </div>

          <br>

          <div fxLayout="column" fxLayoutAlign="space-around center" >
            <h3>{{globals.languageTable_res.get(2041)}}</h3>
            <button mat-icon-button>
              <mat-icon (click)="createReport()">refresh</mat-icon>
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </div>

    <div class="widget-container">
      <mat-expansion-panel [expanded]="true" style="width: 100%">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2>{{globals.languageTable_res.get(selectedReport)}}</h2>
          </mat-panel-title>
          <mat-panel-description>
            <h3>{{reportDescription}}</h3>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div fxLayout="row" fxLayoutAlign="space-around center">
          <button class="dialog-controll-button unclickable" style="width: 220px; border-radius: 0px;">{{globals.languageTable_res.get(2140)}}: {{sumDuration}}</button>
          <button class="dialog-controll-button unclickable" style="width: 220px; border-radius: 0px;">{{globals.languageTable_res.get(2139)}}: {{sumDistance}} [km]</button>
          <button class="dialog-controll-button unclickable" style="width: 220px; border-radius: 0px;">{{globals.languageTable_res.get(2141)}}: {{sumStandStill}}</button>
        </div>
        <br>
        <table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" class="mat-elevation-z8">
          <ng-container matColumnDef="begin">
            <th mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2039)}}</th>
            <td mat-cell *matCellDef="let e">
              <span class="dark-text">
                {{languageService.getLocaleDateTime(e.begin)}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="end">
            <th mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2040)}}</th>
            <td mat-cell *matCellDef="let e">
              <span class="dark-text">
                {{languageService.getLocaleDateTime(e.end)}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2057)}}</th>
            <td mat-cell *matCellDef="let e">
              <span class="dark-text">
                {{e.duration}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="distance">
            <th mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2074)}} [km]</th>
            <td mat-cell *matCellDef="let e">
              <span class="dark-text">
                {{e.distance}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="startAddress">
            <th mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2133)}}</th>
            <td mat-cell *matCellDef="let e">
              <span class="dark-text">
                {{e.startAddress}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="endAddress">
            <th mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2134)}}</th>
            <td mat-cell *matCellDef="let e">
              <span class="dark-text">
                {{e.endAddress}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="standStill">
            <th mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2135)}}</th>
            <td mat-cell *matCellDef="let e">
              <span class="dark-text">
                {{e.standStill}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="standing">
            <th mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2171)}}</th>
            <td mat-cell *matCellDef="let e">
              <span class="dark-text">
                {{e.standing}}
              </span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator #paginator
                        [pageSize]="5"
                        [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>

        <div>
          <button mat-raised-button (click)="exporter.exportTable('xlsx')">Excel</button>
          <button mat-raised-button (click)="exporter.exportTable('csv')">Csv</button>
          <button mat-raised-button (click)="exporter.exportTable('txt')">Txt</button>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
</div>
