import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Globals } from 'src/app/globals';
import { InfoService } from 'src/app/service/info.service';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.css']
})
export class InfoDialogComponent implements OnInit {
  private _message: string;
  // life cycle of info window
  protected TIME_SPAN = 3500;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
              private dialogRef: MatDialogRef<InfoDialogComponent>,
              public globals: Globals) {
                this._message = data.message;
  }

  async ngOnInit() {
    await this.waitUntil(this.TIME_SPAN);

    if (!this.isTypeError()) this.close();
  }

  public close() {
    this.dialogRef.close();
  }

  private waitUntil(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  // getters
  public isTypeError(): boolean {
    if (this.data.type) return this.data.type === Globals.TYPE_ERROR;
  }

  public get message(): string {
    return this._message;
  }
}
