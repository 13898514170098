<h2 mat-dialog-title>
  {{globals.languageTable_res.get(2143)}} - {{licencePlate}}
</h2>

<mat-dialog-content class="dialog-content">
  <table id="details-table">
    <!-- <tr>
      <td>
        <a mat-button class="unclickable">
          <mat-icon class="header-icon">settings_input_antenna</mat-icon>
          <span class="menu-details-item">{{globals.languageTable_res.get(2144)}}</span>
        </a>
      </td>
      <td>
        <a mat-button class="unclickable">
          <mat-icon class="header-icon">date_range</mat-icon>
          <span class="menu-details-item">{{getLastLocationUpdate()}}</span>
        </a>
      </td>
    </tr> -->
    <tr>
      <td>
        <a mat-button class="unclickable">
          <mat-icon class="header-icon">room</mat-icon>
          <span class="menu-details-item">{{globals.languageTable_res.get(2144)}}</span>
        </a>
      </td>
      <td>
        <a mat-button class="unclickable">
          <mat-icon class="header-icon">date_range</mat-icon>
          <span class="menu-details-item">{{getLastGprsUpdate()}}</span>
        </a>
      </td>
    </tr>
  </table>
  <br>
  <div class="google-map">
    <app-google-map #mapComponent [trafficLayer]="false" [searchBox]="false" [drawingManager]="false" [mapType]="'roadmap'" [mapReadyState]="mapReadyState"></app-google-map>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button class="dialog-controll-button"(click)="close()">{{globals.languageTable_res.get(2035)}}</button>
</mat-dialog-actions>
