export class Definitions {

  // general
  public static HTTP_RESPONSE_200 = 200;
  public static HTTP_RESPONSE_401 = 401;
  public static HTTP_RESPONSE_404 = 404;
  public static HTTP_RESPONSE_500 = 500;
  public static SESSION_ID = 'md_webportal_sessionId';
  public static USER_LOGGED_IN = 'UserLoggedIn';
  public static SELECTED_LANG = 'md_selected_language';

  // map
  public static TRACKING_CURRENT_LOCATION = 'md_portal_tracking_current_location';
  public static TRACKING_MAP_ZOOM = 'md_portal_tracking_map_zoom';
  public static MAP_TYPE_ROADMAP = 'roadmap';
  public static MAP_TYPE_SATELLITE = 'satellite';
  public static DEFAULT_MAP_POLY_COLOR = '#f21a1a';

  // language
  public static ID_2001 = 'customerSelection';
}
