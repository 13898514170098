export * from './applicationText.service';
import { ApplicationTextService } from './applicationText.service';
export * from './customer.service';
import { CustomerService } from './customer.service';
export * from './device.service';
import { DeviceService } from './device.service';
export * from './driver.service';
import { DriverService } from './driver.service';
export * from './equipment.service';
import { EquipmentService } from './equipment.service';
export * from './geozone.service';
import { GeozoneService } from './geozone.service';
export * from './group.service';
import { GroupService } from './group.service';
export * from './session.service';
import { SessionService } from './session.service';
export * from './tachograph.service';
import { TachographService } from './tachograph.service';
export * from './tripData.service';
import { TripDataService } from './tripData.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [ApplicationTextService, CustomerService, DeviceService, DriverService, EquipmentService, GeozoneService,
                      GroupService, SessionService, TachographService, TripDataService, UserService];
