<h2 mat-dialog-title>
  {{globals.languageTable_res.get(2078)}}<mat-icon class="small-icon dark-icon clickable" title="{{globals.languageTable_res.get(2079)}}">info</mat-icon>
</h2>

<mat-dialog-content class="dialog-content">
  <a mat-button class="unclickable" *ngIf="!new">
    <mat-icon class="header-icon" [style.color]="'green'">check_circle</mat-icon>
    <span class="menu-details-item">{{getActivationDescription()}}</span>
  </a>
  <form [formGroup]="radiusAlarmFg">
    <div>
      <input type="number" formControlName="radius" required placeholder="{{globals.languageTable_res.get(2080)}}" min="200" step="50" required="true" title="{{globals.languageTable_res.get(2081)}}" />
    </div>
    <div>
      <input type="email" formControlName="firstMail" required placeholder="{{globals.languageTable_res.get(2082)}}" required="true" />
    </div>
    <div>
      <input type="email" formControlName="secondMail" placeholder="{{globals.languageTable_res.get(2083)}}" />
    </div>
    <div>
      <input type="email" formControlName="thirdMail" placeholder="{{globals.languageTable_res.get(2084)}}" />
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button [disabled]="new" class="dialog-controll-button"(click)="openDeleteObjectDialog()">{{globals.languageTable_res.get(2012)}}</button>
  <button [disabled]="disableSaveButton()"
    class="dialog-controll-button"(click)="save()">{{globals.languageTable_res.get(2019)}}</button>
</mat-dialog-actions>

