
<div class="container">
  <circle-progress
  [percent]="timeLeft"
  [maxPercent]="refreshTime"
  [radius]="45"
  [outerStrokeWidth]="1"
  [innerStrokeWidth]="1"
  [animation]="false"
  [responsive]="false"
  [showSubtitle]="true"
  [subtitle]="subtitle"
  >
  </circle-progress>
  <div class="operation-buttons-container">
    <div>
      <button button class="dialog-controll-button mini"  (click)="handleTimer()">
        <mat-icon class="primary-icon clickable">{{iconName}}</mat-icon>
      </button>
    </div>
    <div>
      <button button class="dialog-controll-button mini"  (click)="refresh()">
        <mat-icon class="primary-icon clickable">cached</mat-icon>
      </button>
    </div>
  </div>
<div>
