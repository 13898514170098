<mat-toolbar>
  <mat-toolbar-row>
    <div fxShow="true" fxHide.lt-md="true">
      <a mat-button class="unclickable"><span class="menu-item">{{globals.languageTable_res.get(2036)}}</span></a>
      <span class="menu-spacer"></span>
    </div>
    <button mat-icon-button>
      <mat-icon (click)="appDrawer.toggle()">menu</mat-icon>
    </button>
    <span class="right-spacer"></span>
    <span class="menu-spacer"></span>
      <a mat-button class="clickable" title="{{globals.languageTable_res.get(2075)}}" (click)="openUpdateNews()">
        <span class="menu-details-item version">{{getVersion()}}</span>
      </a>
    <span flex></span>
      <a mat-button (click)="chooseLanguage('de_DE');" title="{{languageService.getTranslation(2067)}}">
          <img class="header-icon" src="assets/images/de-flag.png">
        </a>
        <a mat-button (click)="chooseLanguage('en_GB');" title="{{languageService.getTranslation(2068)}}">
          <img class="header-icon" src="assets/images/uk-flag.png">
        </a>
    <div fxShow="true" fxHide.lt-md="true">
      <a mat-button class="unclickable" title="English">
        <mat-icon class="header-icon">account_circle</mat-icon>
        <span class="menu-details-item">{{loggedInUserName}}</span>
      </a>
      <span class="menu-spacer"></span>
      <a mat-button (click)="openCustomerSelection()" title="{{globals.languageTable_res.get(2001)}}">
        <mat-icon class="header-icon">supervisor_account</mat-icon>
        <span class="menu-details-item">{{selectedCustomerName}}</span>
      </a>
      <span class="menu-spacer"></span>
      <button mat-icon-button>
        <mat-icon (click)="logout()" title="{{globals.languageTable_res.get(2007)}}">exit_to_app</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
