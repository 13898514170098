<div class="map-container">
  <agm-map #map class="google-map" (mapReady)="mapReady($event)" [fullscreenControl]="true" [mapTypeControl]="true">
    <input
      id="pac-input"
      class="controls"
      type="text"
      placeholder="{{globals.languageTable_res.get(2076)}}"
    />
  </agm-map>
</div>
