import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LogInComponent } from './component/log-in/log-in.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './routes';

import { HomeComponent } from './component/home/home.component';
import { AuthGuard } from './auth/auth.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule, MatIconModule, MatSidenavModule, MatListModule, MatButtonModule, MatGridListModule, MatTableModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter, MatExpansionModule, MatPaginatorModule, MatButtonToggleModule, MatSortModule, MatFormFieldModule } from '@angular/material';
import { MatSlideToggleModule, MatSelectModule, MatOptionModule, MatDialogModule, MatInputModule, MatCheckboxModule, MatTabsModule } from '@angular/material';
import { MatProgressSpinnerModule, MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { FooterComponent } from './layout/general/footer/footer.component';
import { HeaderComponent } from './layout/general/header/header.component';
import { SiteLayoutComponent } from './layout/site-layout/site-layout.component';
import { FleetComponent } from './component/tracking/fleet/fleet.component';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { ChartsModule, MDBBootstrapModule } from 'angular-bootstrap-md';
import { ColorPickerModule } from 'ngx-color-picker';
// Firebase services + environment module
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { CustomerSelectionComponent } from './component/dialogs/customer-selection/customer-selection.component';
import { ApplicationTextService, CustomerService, DeviceService, EquipmentService, GroupService, SessionService, TripDataService, UserService } from './generated-services/api';
import { AuthService, NavigationService, RoutingService, UserServiceStandard } from './service/api';
import { EquipmentGroupsOverviewComponent } from './component/tracking/equipment-groups/equipment-groups-overview/equipment-groups-overview.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InfoDialogComponent } from './component/dialogs/general/info-dialog/info-dialog.component';
import { SidenavMenuListItemComponent } from './layout/site-layout/sidenav-menu-list-item/sidenav-menu-list-item.component';
import { AppConfigService } from './app-config.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { GeoZonesOverviewComponent } from './component/tracking/geo-zones/geo-zones-overview/geo-zones-overview.component';
import { GeozoneService } from './generated-services/geozone.service';
import { DeleteObjectComponent } from './component/dialogs/delete-object/delete-object.component';
import { GeozoneFormComponent } from './component/dialogs/tracking/geozone-form/geozone-form.component';
import { EquipmentGroupFormComponent } from './component/dialogs/tracking/equipment-group-form/equipment-group-form.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { RoutingComponent } from './component/dialogs/tracking/routing/routing.component';
import { GeoZoneEvaluationComponent } from './component/dialogs/tracking/geo-zone-evaluation/geo-zone-evaluation.component';
import { DatetimeSelectionComponent } from './component/filter/datetime-selection/datetime-selection.component';
import { EquipmentSelectionComponent } from './component/dialogs/equipment-selection/equipment-selection.component';
import { GeoZoneReportComponent } from './component/dialogs/tracking/geo-zone-report/geo-zone-report.component';
import { TimerComponent } from './component/filter/timer/timer.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { OnlyContentComponent } from './layout/only-content/only-content.component';
import { GoogleMapComponent } from './component/tracking/google-map/google-map.component';
import { RadiusAlarmComponent } from './component/dialogs/tracking/radius-alarm/radius-alarm.component';
import { Globals } from './globals';
import { EditLicenceplateComponent } from './component/dialogs/edit-licenceplate/edit-licenceplate.component';
import { GeoZonesInsideComponent } from './component/tracking/geo-zones/geo-zones-inside/geo-zones-inside.component';
import { GeoZonesModuleComponent } from './component/tracking/geo-zones/geo-zones-module/geo-zones-module.component';
import { InsideEntryComponent } from './component/tracking/geo-zones/geo-zones-inside/inside-entry/inside-entry.component';
import { TableComponent } from './component/webcomponents/table/table.component';
import { TimePickerComponent } from './component/webcomponents/time-picker/time-picker.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TachographDataComponent } from './component/dialogs/tachograph-data/tachograph-data.component';
import { LoadingSpinnerComponent } from './component/filter/loading-spinner/loading-spinner.component';
import { EquipmentReportComponent } from './component/reporting/equipment-report/equipment-report.component';
import { FleetReportComponent } from './component/reporting/fleet-report/fleet-report.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SimTrackingComponent } from './component/dialogs/tracking/sim-tracking/sim-tracking.component';
import { FleetListComponent } from './component/tracking/fleet/fleet-list/fleet-list.component';
import { UpdateNewsComponent } from './component/dialogs/general/update-news/update-news.component';
import { PasswordForgottenComponent } from './component/administration/password-forgotten/password-forgotten.component';
import { PasswordRecoveryComponent } from './component/administration/password-recovery/password-recovery.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { SecurityService } from './generated-services/security.service';

const fAppInitializer = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  }
};

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LogInComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    SiteLayoutComponent,
    FleetComponent,
    CustomerSelectionComponent,
    EquipmentGroupsOverviewComponent,
    InfoDialogComponent,
    SidenavMenuListItemComponent,
    GeoZonesOverviewComponent,
    DeleteObjectComponent,
    EquipmentSelectionComponent,
    GeozoneFormComponent,
    EquipmentGroupFormComponent,
    GeoZoneReportComponent,
    RoutingComponent,
    GeoZoneEvaluationComponent,
    DatetimeSelectionComponent,
    GeoZoneReportComponent,
    TimerComponent,
    OnlyContentComponent,
    GoogleMapComponent,
    RadiusAlarmComponent,
    EditLicenceplateComponent,
    GeoZonesInsideComponent,
    GeoZonesModuleComponent,
    InsideEntryComponent,
    TableComponent,
    TimePickerComponent,
    TachographDataComponent,
    LoadingSpinnerComponent,
    EquipmentReportComponent,
    FleetReportComponent,
    SimTrackingComponent,
    FleetListComponent,
    UpdateNewsComponent,
    PasswordForgottenComponent,
    PasswordRecoveryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    BrowserAnimationsModule,
    ChartsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatCheckboxModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTableExporterModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    MatInputModule,
    MatGridListModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    NgApexchartsModule,
    NgxMatSelectSearchModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AgmCoreModule.forRoot({
      apiKey: environment.mapsApiKey,
      libraries: ['drawing', 'geometry', 'places']
    }),
    AgmJsMarkerClustererModule,
    AgmSnazzyInfoWindowModule,
    MDBBootstrapModule.forRoot(),
    FlexLayoutModule,
    ColorPickerModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      backgroundColor: "var(--icon_primary)",
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "var(--md_primary)",
      innerStrokeColor: "var(--md_primary)",
      showSubtitle: true,
      subtitle: "Refresh",
      animation: false,
      renderOnClick: false,
      units: "s",
      showUnits: true
    }),
    MatPasswordStrengthModule
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: fAppInitializer,
      multi: true,
      deps: [AppConfigService]
    },
    ApplicationTextService,
    AuthGuard,
    AuthService,
    CustomerService,
    DeviceService,
    EquipmentService,
    GeozoneService,
    GroupService,
    NavigationService,
    RoutingService,
    SecurityService,
    SessionService,
    TripDataService,
    UserService,
    UserServiceStandard,
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    Globals
  ],
  bootstrap: [AppComponent],
  entryComponents: [CustomerSelectionComponent, DatetimeSelectionComponent, DeleteObjectComponent, EditLicenceplateComponent,
                    EquipmentGroupFormComponent, EquipmentSelectionComponent, GeoZoneEvaluationComponent, GeozoneFormComponent,
                    GeoZoneReportComponent, InfoDialogComponent, LoadingSpinnerComponent, RadiusAlarmComponent, RoutingComponent,
                    SimTrackingComponent, TachographDataComponent, UpdateNewsComponent ]
})

export class AppModule {

 }
