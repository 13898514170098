/**
 * MdtDbWebService
 * This is a service for basic communication with database
 *
 * OpenAPI spec version: 1.0.0.0
 * Contact: info@moderndrive.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from './encoder';
import { Observable } from 'rxjs';
import { SessionInfo } from '../model/mdtdb/sessionInfo';
import { BASE_PATH, COLLECTION_FORMATS } from './variables';
import { Configuration } from './configuration';
import { ProjectCommon } from '../project-common.class';
import { Customer } from '../model/mdtdb//models';
import { AppConfigService } from '../app-config.service';
import { SessionHelper } from './sessionhelper';
import { SessionInfoView } from '../view/sessionInfoView';


@Injectable()
export class SessionService {

    protected basePath = Configuration.getServiceBasePath();
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, private appConfig: AppConfigService) {
        this.basePath = appConfig.getConfig().webservice.url;
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    /**
     *
     * @param sessionId sessionId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sessionAuthenticate(sessionId: string, observe?: 'body', reportProgress?: boolean): Observable<SessionInfo>;
    public sessionAuthenticate(sessionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SessionInfo>>;
    public sessionAuthenticate(sessionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SessionInfo>>;
    public sessionAuthenticate(sessionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      if (sessionId === null || sessionId === undefined) {
          throw new Error('Required parameter sessionId was null or undefined when calling sessionAuthenticate.');
      }

      let headers = SessionHelper.getStandardSessionHeader();

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
          'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected !== undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.get<SessionInfo>(`${this.basePath}/api/Session/Authenticate/${encodeURIComponent(String(sessionId))}`,
          {
              withCredentials: this.configuration.withCredentials,
              headers,
              observe,
              reportProgress
          }
      );
    }

	/**
     * Return a valid SessionInfoView-Object containing session, username and customerName if authentication was successfull, else NULL.
     *
     * @param sessionId to authenticate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sessionAuthenticateWithCredentials(sessionId: string, observe?: 'body', reportProgress?: boolean): Observable<SessionInfoView>;
    public sessionAuthenticateWithCredentials(sessionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SessionInfoView>>;
    public sessionAuthenticateWithCredentials(sessionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SessionInfoView>>;
    public sessionAuthenticateWithCredentials(sessionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling sessionAuthenticateWithCredentials.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SessionInfoView>(`${this.basePath}/api/Session/AuthenticateWithCredentials/${encodeURIComponent(String(sessionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * @param sessionId sessionId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sessionDelete(sessionId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public sessionDelete(sessionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public sessionDelete(sessionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public sessionDelete(sessionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling sessionDelete.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Blob>(`${this.basePath}/api/Session/Delete/${encodeURIComponent(String(sessionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     *
     * @param body body
     * @param sessionId sessionId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sessionUpdateCustomer(body: Customer, sessionId: string, observe?: 'body', reportProgress?: boolean): Observable<SessionInfo>;
    public sessionUpdateCustomer(body: Customer, sessionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SessionInfo>>;
    public sessionUpdateCustomer(body: Customer, sessionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SessionInfo>>;
    public sessionUpdateCustomer(body: Customer, sessionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling sessionUpdateCustomer.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling sessionUpdateCustomer.');
        }

        //let headers = this.defaultHeaders;
        //headers = headers.set('SessionId', ProjectCommon.GetSessionId());
        let headers = SessionHelper.getStandardSessionHeader();


        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SessionInfo>(`${this.basePath}/api/Session/UpdateCustomer/${encodeURIComponent(String(sessionId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
