<h2 mat-dialog-title>Kundenauswahl</h2>

<mat-dialog-content [formGroup]="customerSelectionFg" class="dialog-content">
  <div>
    <button mat-button (click)="chooseLanguage('de_DE');">
      <img class="header-icon" src="assets/images/de-flag.png">
    </button>
    <button mat-button (click)="chooseLanguage('en_GB');" style="margin-left: 20px">
      <img class="header-icon" src="assets/images/uk-flag.png">
    </button>
  </div>
  <div>
    <mat-form-field>
      <form #customerSelectionForm ="ngForm">
        <mat-select (selectionChange)="doCustomerLogin($event.value)">
          <mat-option *ngFor="let customer of customers" [value]="customer">
            {{customer.customerName}}
          </mat-option>
        </mat-select>
      </form>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button class="dialog-controll-button" (click)="close()">Cancel</button>
</mat-dialog-actions>

