import { Component, OnInit, Input } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit {
  @Input() refreshTime: number;
  @Input() refreshDataBool: BehaviorSubject<boolean>;

  timeLeft: number;
  interval;
  running: boolean = true;
  subtitle: string = "Refresh";

  constructor() { }

  ngOnInit() {
    this.timeLeft = this.refreshTime;
    this.startTimer();
  }

  /* Method call when component gets destroyed */
  ngOnDestroy() {
    // kill refresh action at route change
    clearInterval(this.interval);
  }

  /* Handle click event for play/pause */
  handleTimer() {
    if (this.running) {
      this.pauseTimer();
      this.running = false;
    } else {
      this.startTimer();
      this.running = true;
    }
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  /* Set timer to refreshTime and activate refresh bool */
  refresh() {
    this.refreshDataBool.next(true);
    this.timeLeft = this.refreshTime;
  }

  /* Set timer to refreshTime and activate refresh bool */s
  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.refreshDataBool.next(true);
        this.timeLeft = this.refreshTime;
      }
    },1000)
  }

  public get iconName(): string {
    if (this.running) {
      return 'pause';
    } else {
      return 'play_arrow';
    }
  }
}
