/**
 * MdtDbWebService
 * This is a service for basic communication with database
 *
 * OpenAPI spec version: 1.0.0.0
 * Contact: info@moderndrive.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpHandler } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from './encoder';
import { Observable } from 'rxjs';
import { ApplicationText } from '../model/mdtdb/applicationText';
import { BASE_PATH, COLLECTION_FORMATS } from './variables';
import { Configuration } from './configuration';
import { AppConfigService } from '../app-config.service';
import { SessionHelper } from './sessionhelper';

@Injectable()
export class ApplicationTextService {

    protected basePath = Configuration.getServiceBasePath();
    public defaultHeaders = new HttpHeaders();
    private configuration = new Configuration();

    constructor(protected httpClient: HttpClient, private appConfig: AppConfigService) {
        this.basePath = appConfig.getConfig().webservice.url;
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    /**
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public applicationTextGetAll(observe?: 'body', reportProgress?: boolean): Observable<Array<ApplicationText>>;
    public applicationTextGetAll(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApplicationText>>>;
    public applicationTextGetAll(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApplicationText>>>;
    public applicationTextGetAll(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      let headers = SessionHelper.getStandardSessionHeader();

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
          'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected !== undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.get<Array<ApplicationText>>(`${this.basePath}/api/ApplicationText/GetAll`,
          {
              withCredentials: this.configuration.withCredentials,
              headers,
              observe,
              reportProgress
          }
      );
    }
}
