<div class="container">
  <input type="date" id="fromDate" name="trip-start" style="width: 130px;" value="{{basicMaxDate}}" min="{{basicMinDate}}" max="{{maxDate}}" onkeydown="return false">
  <span style="width: 15px;"></span>
  <input type="time" id="fromTime" name="time-start" style="width: 80px;" value="{{fromTime}}" required>
  <span style="width: 15px;"></span>
  <h2>-</h2>
  <span style="width: 15px;"></span>
  <input type="date" id="toDate" name="trip-end" style="width: 130px;" value="{{basicMaxDate}}" min="{{minDate}}" max="{{basicMaxDate}}" onkeydown="return false">
  <span style="width: 15px;"></span>
  <input type="time" id="toTime" name="time-end" style="width: 80px;" value="{{toTime}}" required>
  <ng-container *ngIf="interval">
    <span style="width: 15px;"></span>
    <h2>-</h2>
    <span style="width: 15px;"></span>
    <mat-form-field class="interval" appearance="outline">
      <mat-label>{{globals.languageTable_res.get(2099)}}</mat-label>
      <mat-select name="interval" [formControl]="intervalForm" [(value)]="selectedInterval">
        <mat-option [value]="'day'">{{globals.languageTable_res.get(2108)}}</mat-option>
        <mat-option [value]="'month'">{{globals.languageTable_res.get(2109)}}</mat-option>
        <mat-option [value]="'year'">{{globals.languageTable_res.get(2110)}}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</div>

