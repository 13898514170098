<mat-dialog-content class="message-box">
  <span>
    {{message}}
  </span>
</mat-dialog-content>

<mat-dialog-actions *ngIf="isTypeError()">
  <button class="dialog-controll-button" (click)="close()">{{globals.languageTable_res.get(2035)}}</button>
  <!-- <button class="dialog-controll-button" (click)="close()">x</button> -->
</mat-dialog-actions>


