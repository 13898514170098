import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DialogResponse } from 'src/app/view/dialogResponse';
import { DialogService } from 'src/app/service/dialog.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-delete-object',
  templateUrl: './delete-object.component.html',
  styleUrls: ['./delete-object.component.css']
})
export class DeleteObjectComponent implements OnInit {
  private _description: string;
  response: DialogResponse = {};

  constructor(@Inject(MAT_DIALOG_DATA) private _data: any,
              private _dialogRef: MatDialogRef<DeleteObjectComponent>,
              public globals: Globals) {
                this._description = _data.description
               }

  ngOnInit() {
  }

  /** Cancel and kill dialog life cycle */
  public cancel() {
    this.response.responseCode = DialogService.RESPONSE_CODE_CANCEL;
    // close dialog an return that deletion was aborted
    this._dialogRef.close(this.response);
  }

  /** Delete selected equipment group */
  public delete() {
    this.response.responseCode = DialogService.RESPONSE_CODE_SUCCESS;
    this.response.data = true;
    // close dialog an return true to signalize that deletion was submitted
    this._dialogRef.close(this.response);
  }

  // getters
  public get description(): string {
    return this._description;
  }
}
