import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/*
  Global class for language stuff access.
*/
@Injectable()
export class Globals {
  public static TYPE_SUCCESS = 'success';
  public static TYPE_ERROR = 'error';
  public static TYPE_INFO = 'info';

  public static ID_SUCCESS = 2069;
  public static ID_ERROR = 2070;
  public static ID_INFO = 2071;

  public static ID_ACL_REVERSE_GEOCODING = 'ef04ad65-5c14-a9ae-41ba-ba719d376e3c';
  public static ID_ACL_EVALUATION = '40043508-2f2a-2cfc-a924-446999df8149';
  public static ID_ACL_MILEAGE = '61d9372e-31a9-2640-a93e-461202f408c9';
  public static ID_ACL_POSITIONS = 'f3624ebc-7353-6284-b710-4d42ac395766';
  public static ID_ACL_TACHOGRAPH = '95dbbfb8-8c49-ab33-1d2d-e2d866e04a30';

  // marks if language table has been initialized successful so other components can
  // decide to load attributes
  public languageTableInitialized = new BehaviorSubject<boolean>(false);
  // storing words in selected language given by language service
  public languageTable_res = new Map<number, string>();
}
