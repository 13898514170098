import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { Globals } from 'src/app/globals';
import { LanguageService } from 'src/app/service/api';
import { DialogService } from 'src/app/service/dialog.service';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {
  @Output() onSuggest: EventEmitter<any> = new EventEmitter();
  @Input() header: string[];
  @Input() data: any[];
  @Input() delete: boolean;
  @Input() title: string;
  @Input() paginatorBool: boolean;
  @Input() id: string;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  public dataSource = new MatTableDataSource<any>();

  constructor(private _deleteObjectDialog: MatDialog,
              private _dialogService: DialogService,
              public _languageService: LanguageService,
              public globals: Globals) { }

  ngOnInit() {
    this.dataSource.data = this.data;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public downloadPdf() {
    let doc = new jsPDF();
    doc.setFontSize(10);
    doc.setTextColor(64, 64, 64);

    if (this.title) doc.text(this.title, 8, 8);

    autoTable(doc, { html: '#' + this.id });
    doc.save('Report.pdf')
  }

  /** Delete selected equipment group */
  public openDeleteObjectDialog(item: any) {
    console.log(JSON.stringify(item));
  }

  public get languageService(): LanguageService {
    return this._languageService;
  }
}
