/**
 * MdtDbWebService
 * This is a service for basic communication with database
 *
 * OpenAPI spec version: 1.0.0.0
 * Contact: info@moderndrive.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from './encoder';
import { Observable } from 'rxjs';
import { Customer } from '../model/mdtdb/customer';
import { Configuration } from './configuration';
import { SessionHelper } from './sessionhelper';
import { AppConfigService } from '../app-config.service';
import { AclObject } from '../model/mdtdb/aclObject';

@Injectable()
export class CustomerService {

    protected basePath = Configuration.getServiceBasePath();
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, private appConfig: AppConfigService) {
        this.basePath = appConfig.getConfig().webservice.url;
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    /**
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public customerGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public customerGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public customerGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public customerGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      if (id === null || id === undefined) {
          throw new Error('Required parameter id was null or undefined when calling customerGet.');
      }

      let headers = SessionHelper.getStandardSessionHeader();

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
          'application/octet-stream'
      ];
      const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected !== undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.get<Customer>(`${this.basePath}/api/Customer/${encodeURIComponent(String(id))}`,
          {
              withCredentials: this.configuration.withCredentials,
              headers,
              observe,
              reportProgress
          }
      );
    }

    /**
     * Get all AclObject for the given customer ordered by the acl object name.
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public customerGetAclObjectsOfCustomer(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AclObject>>;
     public customerGetAclObjectsOfCustomer(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AclObject>>>;
     public customerGetAclObjectsOfCustomer(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AclObject>>>;
     public customerGetAclObjectsOfCustomer(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (id === null || id === undefined) {
             throw new Error('Required parameter id was null or undefined when calling customerGetAclObjectsOfCustomer.');
         }

         let headers = SessionHelper.getStandardSessionHeader();

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.get<Array<AclObject>>(`${this.basePath}/api/Customer/GetAclObjectsOfCustomer/${encodeURIComponent(String(id))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

    /**
     *
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public customerGetAllCustomers(status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Customer>>;
    public customerGetAllCustomers(status?: string, observe?: 'response', reportProgress?: boolean):
                                   Observable<HttpResponse<Array<Customer>>>;
    public customerGetAllCustomers(status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Customer>>>;
    public customerGetAllCustomers(status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', status);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // authentication (MDT Login) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Customer>>(`${this.basePath}/api/Customer/GetAllCustomers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     *
     * @param userId userId
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public customerGetCustomersForUser(userId: string, status?: string, observe?: 'body', reportProgress?: boolean):
                                       Observable<Array<Customer>>;
    public customerGetCustomersForUser(userId: string, status?: string, observe?: 'response', reportProgress?: boolean):
                                       Observable<HttpResponse<Array<Customer>>>;
    public customerGetCustomersForUser(userId: string, status?: string, observe?: 'events', reportProgress?: boolean):
                                       Observable<HttpEvent<Array<Customer>>>;
    public customerGetCustomersForUser(userId: string, status?: string, observe: any = 'body', reportProgress: boolean = false ):
                                       Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling customerGetCustomersForUser.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', status);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Customer>>
        (`${this.basePath}/api/Customer/GetCustomersForUser/${encodeURIComponent(String(userId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }
}
