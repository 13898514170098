import { HttpHeaders, HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app-config.service';
import { Driver } from '../model/mdtdb/driver';
import { Configuration } from './configuration';
import { SessionHelper } from './sessionhelper';

@Injectable({
  providedIn: 'root'
})
export class DriverService {

  protected basePath = Configuration.getServiceBasePath();
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, private appConfig: AppConfigService) {
      this.basePath = appConfig.getConfig().webservice.url;
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
      const form = 'multipart/form-data';
      for (const consume of consumes) {
          if (form === consume) {
              return true;
          }
      }
      return false;
  }

  /**
   *
   * @param identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
   public driverGet(identifier: string, observe?: 'body', reportProgress?: boolean): Observable<Driver>;
   public driverGet(identifier: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Driver>>;
   public driverGet(identifier: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Driver>>;
   public driverGet(identifier: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

       if (identifier === null || identifier === undefined) {
           throw new Error('Required parameter identifier was null or undefined when calling driverGet.');
       }

       let headers = SessionHelper.getStandardSessionHeader();

       // to determine the Accept header
       let httpHeaderAccepts: string[] = [
           'application/json'
       ];
       const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
       if (httpHeaderAcceptSelected != undefined) {
           headers = headers.set('Accept', httpHeaderAcceptSelected);
       }

       // to determine the Content-Type header
       const consumes: string[] = [
       ];

       return this.httpClient.get<Driver>(`${this.basePath}/api/Driver/${encodeURIComponent(String(identifier))}`,
           {
               withCredentials: this.configuration.withCredentials,
               headers: headers,
               observe: observe,
               reportProgress: reportProgress
           }
       );
   }
}
