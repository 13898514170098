import { Injectable, NgZone, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '../app-config.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  private static basePath : string;

  constructor(private router: Router, private ngZone: NgZone, private appConfig: AppConfigService) {
    RoutingService.basePath = appConfig.getConfig().webservice.url;
  }

  public static readonly PASSWORD_FORGOTTEN = '/password-forgotten';
  public static readonly PASSWORD_RECOVERY = '/password-recovery';

  // user controller
  public static readonly USER_PF = '/User';
  public static readonly SIGN_IN = '/SignIn';

  // customer controller
  public static readonly CUSTOMER_PF = '/Customer';
  public static readonly GET_CUSTOMERS_FOR_USER = '/GetCustomersForUser';

  // equipment controller
  public static readonly EQUIPMENT_PF = '/Equipment';
  public static readonly GET_EQUIPMENTS_FOR_CUSTOMER = '/GetEquipmentsForCustomer';

  // group controller
  public static readonly GROUP_CONTEXT_TRACKING = 'Tracking';
  public static readonly GROUP_PF = '/Group';

  // trip data controller
  public static readonly TRIP_DATA_PF = '/TripData';

  // routes
  public static readonly ROUTE_CUSTOMER_SELECTION = 'customer-selection';
  public static readonly ROUTE_LOGIN = 'login';

  // tracking
  public static readonly ROUTE_TRACKING = 'tracking';
  public static readonly ROUTE_TRACKING_FLEET = RoutingService.ROUTE_TRACKING + '/fleet';
  public static readonly ROUTE_TRACKING_DEVICES = RoutingService.ROUTE_TRACKING + '/devices';
  public static readonly ROUTE_TRACKING_GROUPS_OVERVIEW = RoutingService.ROUTE_TRACKING +'/equipment-groups/equipment-groups-overview';
  public static readonly ROUTE_TRACKING_GEO_ZONES_EVALUATION = RoutingService.ROUTE_TRACKING +'/geo-zones/geo-zone-report';
  public static readonly ROUTE_TRACKING_GEO_ZONES_OVERVIEW = RoutingService.ROUTE_TRACKING +'/geo-zones/geo-zones-overview';

  // report
  public static readonly ROUTE_REPORTING = 'reporting';
  public static readonly ROUTE_REPORTING_FLEET_REPORT = RoutingService.ROUTE_REPORTING + '/fleet-report'
  public static readonly ROUTE_REPORTING_EQUIPMENT = RoutingService.ROUTE_REPORTING + '/equipment-report'


  public static GetRequestUrl() {
    return RoutingService.basePath + "/api";
  }

  public navigateToPath(path: string) {
    if (path) {
      this.router.navigate(['/' + path]);
    }
  }

  public navigateToLogin() {
    this.router.navigate(['/' + RoutingService.ROUTE_LOGIN]);
  }

  public navigateToCustomerSelection() {
    this.router.navigate(['/' + RoutingService.ROUTE_CUSTOMER_SELECTION]);
  }

  public navigateToHome() {
    this.router.navigate(['/home']);
  }

  public navigateToTrackingFleet() {
    this.router.navigate(['/' + RoutingService.ROUTE_TRACKING_FLEET]);
  }

  public navigateToTrackingDevices() {
    this.router.navigate(['/' + RoutingService.ROUTE_TRACKING_FLEET]);
  }

  public navigateToTrackingGroupsOverview() {
    this.router.navigate(['/' + RoutingService.ROUTE_TRACKING_GROUPS_OVERVIEW]);
  }

  public navigateToTrackingGeoZonesOverview() {
    this.router.navigate(['/' + RoutingService.ROUTE_TRACKING_GEO_ZONES_OVERVIEW]);
  }

  public navigateToTrackingGeoZonesEvaluation() {
    this.router.navigate(['/' + RoutingService.ROUTE_TRACKING_GEO_ZONES_EVALUATION]);
  }

  public redirectTo(uri: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([uri]));
  }
}
