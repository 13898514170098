import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Equipment, EquipmentGroup, EquipmentGroupContext } from 'src/app/model/mdtdb/models';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthService, InfoService, LanguageService } from 'src/app/service/api';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { GroupService } from 'src/app/generated-services/api';
import { Status } from 'src/app/enumeration/status';
import { ValidationService } from 'src/app/service/validation.service';
import { DialogResponse } from 'src/app/view/dialogResponse';
import { DialogService } from 'src/app/service/dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-equipment-group-form',
  templateUrl: './equipment-group-form.component.html',
  styleUrls: ['./equipment-group-form.component.css']
})
export class EquipmentGroupFormComponent implements OnInit {

  private _equipmentsOfCustomer: Equipment[] = new Array();
  private _equipmentsOfGroup: Equipment[] = new Array();
  private _equipmentGroup: EquipmentGroup;
  private _context: EquipmentGroupContext;
  private _descriptions = new Array();

  equipmentGroupFg: FormGroup;
  response: DialogResponse = {
    responseCode: DialogService.RESPONSE_CODE_ERROR,
    data: null
  };

  constructor(private _authService: AuthService,
              @Inject(MAT_DIALOG_DATA) private _data: any,
              private _dialogRef: MatDialogRef<EquipmentGroupFormComponent>,
              private _groupService: GroupService,
              public globals: Globals,
              private _validationService: ValidationService) {
                this._equipmentGroup = _data.equipmentGroup;
                this._context = _data.context;
                this._descriptions = _data.descriptions;
              }

  ngOnInit() {
    this.equipmentGroupFg = new FormGroup({
      description: new FormControl('')
    });

    if (this._equipmentGroup && this._equipmentGroup.id) {
      // initialize geozone attributes
      // map attributes are loaded in method mapReady()
      this.equipmentGroupFg = new FormGroup({
        description: new FormControl(this._equipmentGroup.groupName)
      });
    }
  }

  /** Cancel and kill dialog life cycle */
  public cancel() {
    this.equipmentGroupFg.reset();
    this.response.responseCode = DialogService.RESPONSE_CODE_CANCEL;
    this._dialogRef.close(this.response);
  }

  /** Create equipment group object for user input */
  private createEquipmentGroupObject(): EquipmentGroup {
    var group: EquipmentGroup = {
      customerId: this._authService.session.customerId,
      groupName: this.equipmentGroupFg.value.description,
      status: Status.ACTIVE,
      createdAt: new Date(),
      createdBy: this._authService.loggedInUser.username
    };

    return group;
  }

  /** Store created equipment group object in db */
  private async createNewEquipmentGroup() {
    if(this.inputValidation()) {
      // create group object
      var equipmentGroup = this.createEquipmentGroupObject();

      if (equipmentGroup) {
        // store group object
        await this._groupService.groupCreateEquipmentGroup(equipmentGroup, this._context.id).toPromise().then((result: EquipmentGroup) => {
          if (result) {
            this.response.responseCode = DialogService.RESPONSE_CODE_SUCCESS;
            this.response.data = result;
          }
        }).catch((err: HttpErrorResponse) => {
          this._validationService.validateHttpErrorResponse(err);
        });

        this._dialogRef.close(this.response);
      }
    }
  }

  /** Validate description field not empty and length < 128 (db limit) and
  *   not already in use.
  */
 private inputValidation(): boolean {
   return this._validationService.inputLengthValidation(this.equipmentGroupFg.controls['description'].value, 128)
    && this._validationService.inputAlreadyExistsValidation(this.equipmentGroupFg.controls['description'].value, this._descriptions,
    this._equipmentGroup ? this._equipmentGroup.groupName : undefined);
 }

  /** Save user input if button is clicked. */
  public save() {
    if (this._equipmentGroup == null || this._equipmentGroup == undefined) {
      this.createNewEquipmentGroup();
    } else {
      this.updateEquipmentGroup();
    }
  }

  /**
   * Append user input to already existing geozone and store changes in db.
   */
  private async updateEquipmentGroup() {
    if (this.inputValidation()) {
      this._equipmentGroup.groupName = this.equipmentGroupFg.controls['description'].value;
      this._equipmentGroup.modifiedBy = this._authService.loggedInUser.username;
      this._equipmentGroup.modifiedAt = new Date();

      await this._groupService.groupUpdateEquipmentGroup(this._equipmentGroup).toPromise().then((group: EquipmentGroup) => {
        if (group) {
          this.response.responseCode = DialogService.RESPONSE_CODE_SUCCESS;
          this.response.data = group;
        }
      }).catch((err: HttpErrorResponse) => {
        this._validationService.validateHttpErrorResponse(err);
      })

      this._dialogRef.close(this.response);
    }
  }

  // getters
  public get equipmentsOfCustomer(): Equipment[] {
    return this._equipmentsOfCustomer;
  }

  public get equipmentsOfGroup(): Equipment[] {
    return this._equipmentsOfGroup;
  }

  public get equipmentGroup(): EquipmentGroup {
    return this._equipmentGroup;
  }
}
