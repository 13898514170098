import { ProjectCommon } from '../project-common.class';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppConfigService } from '../app-config.service';
import { Inject } from '@angular/core';

export interface ConfigurationParameters {
    apiKeys?: {[ key: string ]: string};
    username?: string;
    password?: string;
    accessToken?: string | (() => string);
    basePath?: string;
    withCredentials?: boolean;
}

export class Configuration {

    //protected static BASE_PATH = 'https://localhost:5001' || 'https://webservice.moderndrive.com'
    protected static BASE_PATH = environment.webserviceUrl;

    apiKeys?: {[ key: string ]: string};
    username?: string;
    password?: string;
    accessToken?: string | (() => string);
    basePath?: string;
    withCredentials?: boolean;

    constructor(configurationParameters: ConfigurationParameters = {}) {
        this.apiKeys = configurationParameters.apiKeys;
        this.username = configurationParameters.username;
        this.password = configurationParameters.password;
        this.accessToken = configurationParameters.accessToken;
        this.basePath = configurationParameters.basePath;
        this.withCredentials = configurationParameters.withCredentials;
    }

    public static getServiceBasePath() {
      return Configuration.BASE_PATH;
    }
}
