import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-datetime-selection',
  templateUrl: './datetime-selection.component.html',
  styleUrls: ['./datetime-selection.component.css']
})
export class DatetimeSelectionComponent implements OnInit {
  fromDateForm = new FormControl((new Date()).toISOString());
  toDateForm = new FormControl((new Date()).toISOString());
  fromDate: Date = new Date();
  toDate: Date = new Date();
  fromTime = "00:00";
  toTime = "23:59";

  constructor(public globals: Globals) { }

  ngOnInit() {
    this.toDateForm.setValue(new Date());
    this.fromDateForm.setValue(new Date());
  }

  // getters
  public getFromDate(): Date {
    var fTime = this.fromTime.split(':');
    this.fromDate = new Date(this.fromDateForm.value);
    // insert start time
    this.fromDate.setHours(parseInt(fTime[0]), parseInt(fTime[1]), 0);

    return this.fromDate;
  }

  public getToDate(): Date {
    var tTime = this.toTime.split(':');
    this.toDate = new Date(this.toDateForm.value);
    // insert end time
    this.toDate.setHours(parseInt(tTime[0]), parseInt(tTime[1]), 0);

    return this.toDate;
  }

  public getFromDateAsUnixTimestamp(): number {
    var result = Math.round(this.getFromDate().getTime()/1000);

    return result;
  }

  public getToDateAsUnixTimestamp(): number {
    var result = Math.round(this.getToDate().getTime()/1000);

    return result;
  }

  public get basicMinDate(): Date {
    return new Date("2017-01-01");
  }

  public get basicMaxDate(): Date {
    return new Date();
  }

  /* If to date is already selected, maxDate = toDate to avoid wrong user input.
  If not, maxDate = basicMaxDate (today) */
  public get maxDate(): Date {
    return this.toDateForm.value ? this.toDateForm.value : this.basicMaxDate;
  }

  /* If from date is already selected, minDate = fromDate.
      If not, minDate = basicMinDate (01.01.2017) */
  public get minDate(): Date {
    return this.fromDateForm.value ? this.fromDateForm.value : this.basicMinDate;
  }
}
