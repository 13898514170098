<app-header [appDrawer]="appDrawer"></app-header>
<mat-sidenav-container>
  <mat-sidenav #appDrawer fxLayout="column">
    <mat-nav-list>
      <app-sidenav-menu-list-item *ngFor="let item of navItems" [item]="item"></app-sidenav-menu-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="main-container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

