<div class="background-container">
  <div class="content">
    <div class="widget-container" style="width: 800px">
      <mat-expansion-panel [expanded]="expandFilter" style="width: 100%">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2>{{globals.languageTable_res.get(2107)}}</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div style="width: 700px !important;">
          <div fxLayout="row" fxLayoutAlign="space-around center">
            <app-time-picker #timer [interval]="true"></app-time-picker>
          </div>
          <br>
          <div fxLayout="row" fxLayoutAlign="space-around center">
            <div fxLayout="column" fxLayoutAlign="space-around center">
              <h3>{{globals.languageTable_res.get(2028)}}: {{selectedEquipments.length}}</h3>
              <button mat-icon-button>
                <mat-icon (click)="openEquipmentSelection()" title="{{globals.languageTable_res.get(2064)}}">commute</mat-icon>
              </button>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-around center">
              <h3>{{globals.languageTable_res.get(2041)}}</h3>
              <button mat-icon-button title="{{globals.languageTable_res.get(2041)}}">
                <mat-icon (click)="refresh(timer.getFromDate(), timer.getToDate(), timer.getInterval())">refresh</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <br>

    <mat-tab-group (selectedTabChange)="selectTab($event.index)" id="tab">
      <mat-tab *ngFor="let tab of tabs" [label]="globals.languageTable_res.get(tab.translation)">
      </mat-tab>
    </mat-tab-group>
    <br>

    <div fxLayout *ngIf="showAggregation">
      <ng-container *ngFor="let aggregation of aggregations">
        <div class="header-item" [style.border-color]="aggregation.borderColor">
          <h1>{{aggregation.value}} {{aggregation.unit}}</h1>
          <ng-container *ngIf="aggregation.equipment"><h2>{{aggregation.equipment}}</h2></ng-container>
          <h3>{{aggregation.description}}</h3>
        </div>
      </ng-container>
    </div>


    <div class="widget-container">
      <mat-expansion-panel [expanded]="showAggregation">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2>{{globals.languageTable_res.get(2053)}}</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row" fxLayoutAlign="space-around center">
          <div id="sumPerVehicle" style="width: 40%;"></div>
          <div id="sumPerInterval" style="width: 40%;"></div>
        </div>
      </mat-expansion-panel>
    </div>
    <br>

    <div id="section-to-print">
      <div class="widget-container" *ngIf="showIntervalData">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h2>{{globals.languageTable_res.get(2099)}}</h2>
            </mat-panel-title>
            <mat-panel-description>
            <h3>{{globals.languageTable_res.get(2105)}} [{{selectedTab.unit}}]</h3>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="operation-buttons-container" style="width: 100px; position: relative; float: right;">
            <button button class="dialog-controll-button-small" (click)="print()" *ngIf="showTimeline">
              <mat-icon class="primary-icon clickable">print</mat-icon>
            </button>
            <mat-icon class="small-icon dark-icon clickable" title="{{globals.languageTable_res.get(2124)}}">info</mat-icon>
          </div>
          <br>
          <br>
          <div class="widget">
            <div style="width: auto">
              <app-table [id]="'interval'" [paginatorBool]="true" [delete]="false" [header]="intervalDataHeader" [data]="intervalDataColumns"></app-table>
            </div>
          </div>
          <br>
          <div *ngIf="showTimeline">
            <h3>{{globals.languageTable_res.get(2104)}} - {{globals.languageTable_res.get(2106)}}</h3>
            <ng-container *ngFor="let options of timelineChartOptions">
              <apx-chart
                [colors]="options.colors"
                [series]="options.series"
                [chart]="options.chart"
                [fill]="options.fill"
                [legend]="options.legend"
                [plotOptions]="options.plotOptions"
                [xaxis]="options.xaxis">
              </apx-chart>
            </ng-container>
          </div>
        </mat-expansion-panel>
      </div>
      <br>
    </div>

    <div class="widget-container" *ngIf="showEventData">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2>{{globals.languageTable_res.get(2103)}}</h2>
          </mat-panel-title>
          <mat-panel-description>
            <h3>{{globals.languageTable_res.get(2106)}}</h3>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="widget">
          <div style="width: auto">
            <app-table [id]="'event'" [paginatorBool]="true" [delete]="false" [header]="eventDataHeader" [data]="eventDataColumns"></app-table>
          </div>
        </div>
      </mat-expansion-panel>
    </div>

    <br>
    <div class="widget-container" *ngIf="showSingleVehicleData">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h2>{{globals.languageTable_res.get(2130)}}</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="widget">
          <div style="width: auto">
            <app-table [id]="'single'" [paginatorBool]="false" [title]="singleVehicleDataTitle" [delete]="false" [header]="singleVehicleDataHeader" [data]="singleVehicleDataColumns"></app-table>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
</div>
