import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { EquipmentService, TripDataService } from 'src/app/generated-services/api';
import { AuthService } from 'src/app/service/auth.service';
import { Status } from 'src/app/enumeration/status';
import { MatDialog } from '@angular/material';
import { EquipmentSelectionComponent } from '../../dialogs/equipment-selection/equipment-selection.component';
import { DialogResponse } from 'src/app/view/dialogResponse';
import { Equipment } from 'src/app/model/mdtdb/models';
import { DialogService } from 'src/app/service/dialog.service';
import { Globals } from 'src/app/globals';
import { ApexAxisChartSeries, ApexChart, ApexFill, ApexLegend, ApexPlotOptions, ApexXAxis } from 'ng-apexcharts';
import { LanguageService } from 'src/app/service/language.service';
import * as ApexCharts from 'apexcharts';
import { InfoService } from 'src/app/service/info.service';
import { BehaviorSubject } from 'rxjs';
import { LoadingSpinnerComponent } from '../../filter/loading-spinner/loading-spinner.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidationService } from 'src/app/service/validation.service';

class Aggregation {
  value: number;
  description: string;
  unit: string;
  group: string;
  equipment: string;
  borderColor: string;
}

class Tab {
  index: number;
  descriptor: string;
  unit: string;
  translation: number;
  showTimeline: boolean;
}

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  fill: ApexFill;
  legend: ApexLegend;
  xaxis: ApexXAxis;
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: 'app-fleet-report',
  templateUrl: './fleet-report.component.html',
  styleUrls: ['./fleet-report.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FleetReportComponent implements OnInit {
  dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  expandFilter: boolean = true;
  chartColors: string[] = new Array();
  tabs: Tab[] = new Array();
  selectedTab: Tab;
  // filter
  from: Date;
  to: Date;
  interval: string = 'day';
  // equipment selection
  private _equipments: Equipment[] = new Array();
  private _selectedEquipments: Equipment[] = new Array();

  // flags to show selected data
  // showIntervalData = false;
  showAggregation = false;
  showIntervalData = false;
  showEventData = false;
  showTimeline = false;
  showSingleVehicleData = false;

  // data arrays
  aggregatedData: any[];
  intervalData: any[];
  eventData: any[];
  singleVehicleData: any[];

  // aggregated data
  aggregations: Aggregation[] = new Array();
  aggregationCharts: any[] = new Array();

  // interval data
  intervalDataHeader: any[] = new Array();
  intervalDataColumns: any[] = new Array();

  // interval data
  singleVehicleDataTitle: string;
  singleVehicleDataHeader: any[] = new Array();
  singleVehicleDataColumns: any[] = new Array();

  // event data
  eventDataHeader: any[] = new Array();
  eventDataColumns: any[] = new Array();
  timelineChartOptions: any[] = new Array();

  charts: any[] = new Array();

  constructor(private _authService: AuthService,
              private cdref: ChangeDetectorRef,
              private _dialogService: DialogService,
              private _equipmentSelection: MatDialog,
              private _equipmentService: EquipmentService,
              public globals: Globals,
              public _infoService: InfoService,
              private _languageService: LanguageService,
              private _loadingSpinnerDialog: MatDialog,
              private _tripDataService: TripDataService,
              private _validationService: ValidationService) { }

  async ngOnInit() {
    if(await this._authService.sessionValidation()) {
      this.createTabs();

      // load equipments for selected customer
      this._equipmentService.equipmentGetEquipmentsForCustomer(this._authService.selectedCustomer.id, Status.ACTIVE).toPromise()
      .then((result: Equipment[]) => {
        if (result) {
          this._equipments = result;
          this._selectedEquipments = result;
        }
      });
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  /* Calculate amount of random colors for given value */
  public calculateColors(value: number): string[] {
    var result: string[] = new Array();

    if (value) {
      for (var j = 0; j < value; j++) {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        result.push(color);
      }
    }

    return result;
  }

  /* Calculate timespan of dates for given start and end date for time line charts */
  private calculateDaysTimespan(from: Date, to: Date) {
    // To calculate the time difference of two dates
    var diffInTime = (to.getTime() - from.getTime());

    // To calculate the no. of days between two dates
    var daysDiff = this.round(diffInTime / (1000 * 3600 * 24), 0);

    var dates: Date[] = new Array();
    for (var i = 0; i < daysDiff; i++) {
      dates.push(new Date(from.toString()));
      dates[i].setDate(dates[i].getDate() + i);
    }

    return dates;
  }

  /* Cast amount of seconds to hh:mm:ss format - max. 24 hours, else overflow */
  public castToTimeFormat(sec) {
    var hrs = Math.floor(sec / 3600);
    var min = Math.floor((sec - (hrs * 3600)) / 60);
    var seconds = sec - (hrs * 3600) - (min * 60);
    seconds = Math.round(seconds * 100) / 100

    var result = (hrs < 10 ? "0" + hrs : hrs);
    result += ":" + (min < 10 ? "0" + min : min);
    result += ":" + (seconds < 10 ? "0" + seconds : seconds);

    return result.toString();
  }

  /* Convert table entry to specific format */
  public convertFormat(objects: any[]) {
    if (objects && objects.length > 0) {
      objects.forEach(o => {
        Object.keys(o).forEach(key => {
          if (key === 'begin' || key === 'end') o[key] = this._languageService.getLocaleDateTime(o[key]);
          else if (this.selectedTab.unit === 'h' && key != 'interval' && key != 'vehicle' && key != 'counter_begin' && key != 'counter_end') o[key] = this.castToTimeFormat(o[key]);
        });
      });
    }
  }

  /* Create a apex chart for given data */
  private createChart(id: string, labels: string[], data: number[], title: string, type: string, height: number) {
    var unit = this.selectedTab.unit;
    var height = height;
    var options = {};
    options = {
      series: [{
      data: data
      }],
      title: {
        text: title
      },
        chart: {
        type: type,
        height: height
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: labels,
        labels: {
          formatter: function (value) {
            value = Math.round(value * 100) / 100;
            return value + ' ' + unit;
          }
        }
      }
    };

    var chart = new ApexCharts(document.querySelector(id), options);
    this.charts.push(chart);
    chart.render();
    this.aggregationCharts.push(chart);
  }

  /* Create tab values for selected evaluation identifier */
  private createTabs() {
    // TODO check via security service which tabs are available for selected customer
    // operating hours
    var tab: Tab = new Tab();
    tab.index = 0;
    tab.descriptor = 'operating_hours';
    tab.unit = "h";
    tab.translation = 2097;
    tab.showTimeline = true;
    this.tabs.push(tab);

    // distance
    tab = new Tab();
    tab.index = 1;
    tab.descriptor = 'distance';
    tab.unit = "km";
    tab.translation = 2111;
    tab.showTimeline = true;
    this.tabs.push(tab);

    // consume
    tab = new Tab();
    tab.index = 2;
    tab.descriptor = 'consume';
    tab.unit = "l";
    tab.translation = 2098;
    tab.showTimeline = false;
    this.tabs.push(tab);

    // set default tab
    if (this.tabs && this.tabs.length) this.selectedTab = this.tabs[0];
  }

  /* Create Timeline charts */
  private createTimelines() {
    var dates = this.calculateDaysTimespan(this.from, this.to);
    this.timelineChartOptions = new Array();

    if (dates && dates.length > 0) {
      var i = 0;
      dates.forEach(date => {
        var currentDate = new Date();
        var counter = 0;
        var dateDataArray: any[] = new Array();
        var stringDate = date.toLocaleDateString();

        // create equipment data for timespan
        this._selectedEquipments.forEach(equipment => {
          var equipmentDataArray: any[] = new Array();
          this.eventData.forEach(data => {
            if (data['vehicle'] === equipment.licencePlate) {
              var date: Date = new Date(data['begin']);

              if (date.toLocaleDateString() === stringDate) {
                // important to add Z for UTC
                var begin = new Date(data['begin'] + 'Z');
                currentDate = begin;
                var end = new Date(data['end'] + 'Z');
                var array: any[] = new Array();
                array.push(begin.getTime());
                array.push(end.getTime());

                var o = {
                  x: stringDate,
                  y: array
                }
                equipmentDataArray.push(o);
              }
            }
          });

          // check if data is available to create chart options
          if (equipmentDataArray.length > 0) {
            var o = {
              name: equipment.licencePlate,
              data: equipmentDataArray
            };
            counter++;
            dateDataArray.push(o);
          }
        });

        // check if data is available to print chart
        if (dateDataArray.length > 0) {
          var options = this.createTimelineChartOptions(dateDataArray, this.chartColors, (100 + (counter * 20)), currentDate);
          this.timelineChartOptions.push(options);
        }
      });

      this.showTimeline = true;
    }
  }

  public print() {
    window.print();
  }

  /* Create chart options for timeline chart */
  private createTimelineChartOptions(data: any[], colors: string[], height: number, date: Date) {
    var options = {
      series: data,
      colors: colors,
      chart: {
        height: height,
        type: "rangeBar",
        zoom: {
          enabled: true,
          autoScaleXaxis: false,
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "80%"
        }
      },
      yaxis: {
        categories: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
      },
      xaxis: {
        type: "datetime",
        min: new Date(date.setHours(0, 0, 0, 0)).getTime(), // start date
        max: new Date(date.setHours(24, 0, 0, 0)).getTime(), // end date
        tickAmount: 2, // interval you want
        labels: {
          datetimeUTC: false,
          datetimeFormatter: {
            year: 'yyyy',
            month: "MMM 'yy",
            day: 'dd MMM',
            hour: 'HH:mm',
        },
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [50, 0, 100, 100]
        }
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "left"
      }
    };

    return options;
  }

  /* Create Sum, Max, Min and Avg aggregation from webservice data
     to display in header items */
  public evaluateAggregationHeaders() {
    var sumHeader: Aggregation = new Aggregation();
    sumHeader.description = 'Sum';
    sumHeader.unit = this.selectedTab.unit;
    var maxHeader: Aggregation = new Aggregation();
    maxHeader.description = 'Max'
    maxHeader.unit = this.selectedTab.unit;
    var minHeader: Aggregation = new Aggregation();
    minHeader.description = 'Min';
    minHeader.unit = this.selectedTab.unit;
    var avgHeader: Aggregation = new Aggregation();
    avgHeader.description = 'Avg';
    avgHeader.unit = this.selectedTab.unit;

    var first = this.aggregatedData[0];

    var sum = 0;
    var max = +first['sum'];
    maxHeader.equipment = first['vehicle'];
    var min = +first['sum'];
    minHeader.equipment = first['vehicle'];
    var avg = 0;

    this.aggregatedData.forEach(o => {
      sum += +o['sum'];

      if (max < +o['sum']) {
        max = +o['sum'];
        maxHeader.equipment = o['vehicle'];
      }

      if (min > +o['sum']) {
        min = +o['sum'];
        minHeader.equipment = o['vehicle'];
      }
    });

    sum = this.round(sum, 2);
    max = this.round(max, 2);
    min = this.round(min, 2);
    avg = this.round((sum / this._selectedEquipments.length), 2);

    sumHeader.value = sum;
    maxHeader.value = max;
    minHeader.value = min;
    avgHeader.value = avg;

    sumHeader.borderColor = '#009EE0';
    maxHeader.borderColor = '#5ff838';
    minHeader.borderColor = '#f53939';
    avgHeader.borderColor = '#009EE0';

    // create sum header container
    this.aggregations = new Array();
    this.aggregations.push(sumHeader);
    this.aggregations.push(maxHeader);
    this.aggregations.push(minHeader);
    this.aggregations.push(avgHeader);
  }

  /* Receive data from webservice */
  private async loadData() {
    // remove old data from html page
    this.showAggregation = false;
    this.showIntervalData = false;
    this.showEventData = false;
    this.showTimeline = false;
    this.showSingleVehicleData = false;
    // remove existing charts
    if (this.charts && this.charts.length > 0) {
      this.charts.forEach(x => {
        if (x) x.destroy();
      });
    }

    var offsetHours = -(this.from.getTimezoneOffset() / 60);

    if (this.from && this.to && this.interval && this.selectedEquipments.length > 0 && this.selectedTab) {
      // show loading dialog
      const dialogRef = this._loadingSpinnerDialog.open(LoadingSpinnerComponent, {
        data: {
          diameter: 57,
          loading: this.dataLoaded
        }
      });

      dialogRef.afterClosed().subscribe(x => {
        this.dataLoaded.next(false);
      });

      // load interval data
      await this._tripDataService.tripDataIntervalData(this._selectedEquipments.map(x => x.id), this.from, this.to, offsetHours, this.selectedTab.descriptor, this.interval, 'de-DE')
            .toPromise().then((result: any[]) => {
              if (result && result.length > 0) {
                this.intervalData = result;

                this.processIntervalData();
              } else {
                // TODO show info window for no data found
                console.log('No interval data found!');
              }
      }).catch((err: HttpErrorResponse) => {
        this.dataLoaded.next(true);
        this._validationService.validateHttpErrorResponse(err);
      });

      // load aggregated data
      await this._tripDataService.tripDataAggregatedData(this._selectedEquipments.map(x => x.id), this.from, this.to, this.selectedTab.descriptor)
            .toPromise().then((result: any[]) => {
              if (result && result.length > 0) {
                this.aggregatedData = result;

                this.processAggregatedData();
              } else {
                // TODO show info window for no data found
                console.log('No aggregated data found!');
              }
      }).catch((err: HttpErrorResponse) => {
        this.dataLoaded.next(true);
        this._validationService.validateHttpErrorResponse(err);
      });

    // load event data
    await this._tripDataService.tripDataSingleData(this._selectedEquipments.map(x => x.id), this.from, this.to, this.selectedTab.descriptor)
          .toPromise().then((result: any[]) => {
            if (result && result.length > 0) {
              this.eventData = result;
              this.processEventData();
            } else {
              // TODO show info window for no data found
              console.log('No interval data found!');
            }
    }).catch((err: HttpErrorResponse) => {
      this.dataLoaded.next(true);
      this._validationService.validateHttpErrorResponse(err);
    });

     // load single vehicle data
      if (this.selectedEquipments.length == 1 && this.selectedTab.descriptor === 'operating_hours' && this.interval === 'day') {
        this.singleDataEvaluation();
      }

      this.dataLoaded.next(true);
    } else {
      // TODO show info with invalid filter message
      console.log('Invalid filter parameters!');
    }
  }

  public async singleDataEvaluation() {
    var offsetHours = -(this.from.getTimezoneOffset() / 60);
    await this._tripDataService.tripDataSingleVehicleWorkingEvaluation(this._selectedEquipments.map(x => x.id)[0], this.from, this.to, offsetHours, 'de-DE')
          .toPromise().then((result: any[]) => {
            if (result && result.length > 0) {
              this.singleVehicleData = result;
              this.singleVehicleDataHeader = new Array();
              this.singleVehicleDataColumns = new Array();

              // init header
              Object.keys(this.singleVehicleData[0]).forEach(key => this.singleVehicleDataHeader.push(key));
              var tempObjects = JSON.parse(JSON.stringify(this.singleVehicleData));

              this.singleVehicleDataTitle = this._selectedEquipments[0].licencePlate + ' - ' + this.from.toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }) + ' ' + this.from.toLocaleTimeString() + ' bis ' +  this.to.toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }) + ' ' + this.to.toLocaleTimeString();

              // convert to format
              this.singleVehicleDataColumns = tempObjects;
              this.showSingleVehicleData = true;
            } else {
              // TODO show info window for no data found
              console.log('No single vehicle data found!');
            }
          }).catch((err: HttpErrorResponse) => {
            this.dataLoaded.next(true);
            this._validationService.validateHttpErrorResponse(err);
          });
  }

  /* Open equipment selection dialog */
  public openEquipmentSelection() {
    const dialogRef = this._equipmentSelection.open(EquipmentSelectionComponent, {
      data: {
        equipments: this._equipments,
        selectedEquipments: this._selectedEquipments
      }
    })

    dialogRef.afterClosed().subscribe((response: DialogResponse) => {
      if (this._dialogService.evaluateResponse(response)) {
        this._selectedEquipments = response.data;
      }
    });
  }

  /* Create charts and header values for aggregated data */
  private processAggregatedData() {
    this.evaluateAggregationHeaders();

    // remove old charts
    if (this.aggregationCharts && this.aggregationCharts.length > 0) this.aggregationCharts.forEach(x => x.destroy());
    this.aggregationCharts = new Array();

    var labels: string[] = new Array();
    var data: number[] = new Array();

    // receive sum data for vehicle from aggregated data
    this.aggregatedData.forEach(x => {
      labels.push(x['vehicle']);
      data.push(x['sum']);
    });

    var height = 150 + (labels.length * 10);
    this.createChart('#sumPerVehicle', labels, data, this.globals.languageTable_res.get(2066) + ' - ' + this.globals.languageTable_res.get(2026), 'bar', height);

    // receive sum data for interval from interval data
    // create interval sample chart
    labels = new Array();
    data = new Array();

    if (this.intervalData && this.intervalData.length) {
      this.intervalData.forEach(x => {
        // ignore sum column
        if (x['interval']) {
          labels.push(x['interval']);
          if (this.selectedTab.unit === 'h') data.push(this.round((x['sum'] / 3600), 2));
          else data.push(this.round(x['sum'], 2));
        }
      });

      height = 150 + (labels.length * 10);
      this.createChart('#sumPerInterval', labels, data, this.globals.languageTable_res.get(2066) + ' - ' + this.globals.languageTable_res.get(2099), 'bar', height);
    }

    // display charts
    this.showAggregation = true;
  }

  /* Create data table for event data */
  private processEventData() {
    this.eventDataHeader = new Array();
    this.eventDataColumns = new Array();

    // init header
    Object.keys(this.eventData[0]).forEach(key => this.eventDataHeader.push(key));

    var tempObjects = JSON.parse(JSON.stringify(this.eventData));
    // convert to format
    this.convertFormat(tempObjects);

    this.eventDataColumns = tempObjects;
    this.showEventData = true;

    if (this.selectedTab.showTimeline) this.createTimelines();
  }

  /* Create data table for interval data */
  private processIntervalData() {
    this.intervalDataHeader = new Array();
    this.intervalDataColumns = new Array();

    // init header
    Object.keys(this.intervalData[0]).forEach(key => this.intervalDataHeader.push(key));
    var tempObjects = JSON.parse(JSON.stringify(this.intervalData));

    // convert to format
    this.convertFormat(tempObjects);
    this.intervalDataColumns = tempObjects;

    this.showIntervalData = true;
  }

  /* Update data for selected filter values */
  public refresh(from: Date, to: Date, interval: string) {
    this.expandFilter = false;

    // TODO create info to select min. one equipment
    if (this._selectedEquipments.length <= 0) {
      console.log('Select min. 1 equipment!'); return;
    }

    // get selected timespan and interval from time picker
    this.from = from;
    this.to = to;
    this.interval = interval;

    // calculate colors for selected equipments
    this.chartColors = this.calculateColors(this._selectedEquipments.length);
    // switch to selected tab
    this.loadData();
  }

  /* Round given number to given digits */
  public round(value: number, digits: number): number {
    var result = +parseFloat(value.toString()).toFixed(digits);
    return result;
  }

  /* Switch selected tab and refresh data */
  public selectTab(index: number) {
    if (this.selectedTab && this.selectedTab.index != index) {
      this.selectedTab = this.tabs[index];
      this.loadData();
    }
  }

  // getter
  public get selectedEquipments(): Equipment[] {
    return this._selectedEquipments;
  }
}
