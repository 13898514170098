import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/service/api';
import { GeozoneInsideView } from 'src/app/view/geozoneInsideView';

@Component({
  selector: 'app-inside-entry',
  templateUrl: './inside-entry.component.html',
  styleUrls: ['./inside-entry.component.css']
})
export class InsideEntryComponent implements OnInit {
  @Input() entry : GeozoneInsideView;
  expanded = false;
  empty = true;
  constructor(public languageService: LanguageService) { }

  ngOnInit() {
    if (this.entry && this.entry.positionInfos && this.entry.positionInfos.length > 0) {
      this.empty = false;
    }
  }

  public toggleEntry() {
    this.expanded = !this.expanded;
  }
}
