import { Component, isDevMode, OnInit } from '@angular/core';
import * as sha1 from 'sha1';
import { AuthService, LanguageService } from 'src/app/service/api';
import { ProjectCommon } from 'src/app/project-common.class';
import { SessionInfo, User, Customer } from 'src/app/model/mdtdb/models';
import { CustomerSelectionComponent } from '../dialogs/customer-selection/customer-selection.component';
import { MatDialog } from '@angular/material';
import { CustomerService, UserService } from 'src/app/generated-services/api';
import { InfoService } from 'src/app/service/info.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidationService } from 'src/app/service/validation.service';
import { Definitions } from 'src/app/definitions';
import { BehaviorSubject } from 'rxjs';
import { Globals } from 'src/app/globals';
import { Status } from 'src/app/enumeration/status';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css']
})
export class LogInComponent implements OnInit {
  isLoginError = false;
  testSystem = isDevMode();
  public languageInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _authService: AuthService,
              private _customerService: CustomerService,
              private _customerSelectionDialog: MatDialog,
              public globals: Globals,
              private _infoService: InfoService,
              private _languageService: LanguageService,
              private _userService: UserService,
              private _validationService: ValidationService) { }

  ngOnInit() {
    this._authService.logout();
  }

  ngOnDestroy() {
    // kill subscription for language initialization
    this.languageInitialized.unsubscribe();
  }

  public login(username, password) {

    const user: User = {
      username,
      password: this.encryptPassword(password)
    };

    this._userService.userSignIn(user).toPromise().then((data: any) => {
      var session = data as SessionInfo;

      if (session) {
        var selectedLang = LanguageService.SELECTED_LANG_DE;

        // set default language german
        this._languageService.setSelectedLang(selectedLang);
        // Save session data from logged in user in client session storage
        ProjectCommon.SetSessionId(session.sessionId);
        this._authService.setSession(session);
        this._languageService.initializeLanguageTables(selectedLang).subscribe(success => {
          if (success) {
            this.openCustomerSelection();
          }
        });
      } else {
        // TODO - infow window : user credentials wrong
        this._infoService.showInfoWindow(Globals.TYPE_ERROR, 'Login fehlgeschlagen');
      }
    }).catch((err: HttpErrorResponse) => {
      if (err.status === Definitions.HTTP_RESPONSE_401) {
        this._infoService.showInfoWindow(Globals.TYPE_ERROR, 'Benutzername / Passwort falsch!');
      } else {
        this._validationService.validateHttpErrorResponse(err);
      }
    });
  }
  private openCustomerSelection() {
    this._customerService.customerGetCustomersForUser(this._authService.session.userId, Status.ACTIVE).toPromise().then((data: any) => {
      var customers: Customer[] = data as Customer[];

      if (!customers || customers.length === 0) {
        // TODO return info text as application text from db
        this._infoService.showInfoWindow(Globals.TYPE_INFO, 'No customer available');
        this._authService.logout();
      } else if (customers.length === 1) {
        // only one customer - skip selection
        this._authService.customerLogin(customers[0], this._authService.session.sessionId);
      } else {
        // open customer selection dialog
        const dialogRef = this._customerSelectionDialog.open(CustomerSelectionComponent, {
          data: {
            customers,
          }
        });
      }
    }).catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
    });
  }

  public encryptPassword(password) {
    return '*' + sha1(sha1(password, {asBytes: true}));
  }

  public getVersion(): string {
    return ProjectCommon.PORTAL_VERSION;
  }
}
