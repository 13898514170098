<h2 mat-dialog-title>{{globals.languageTable_res.get(2008)}} - {{entry.licencePlate}}</h2>

<mat-dialog-content>
  <div class="content">
    <div class="left">
      <div class="date-picker-container">
        <div fxLayout="row" fxLayoutAlign="center" >
          <mat-select style="width: 150px;" [formControl]="datetimeCtrl" placeholder="{{languageService.getTranslationFromKeyword(datetimeFilter[0])}}"
              #datetimeSelect (selectionChange)="selectTimespan($event.value)">
            <mat-option *ngFor="let filter of datetimeFilter" [value]="filter">
              {{languageService.getTranslationFromKeyword(filter)}}
            </mat-option>
          </mat-select>
        </div>
        <br>
        <div fxLayout="row" fxLayoutAlign="center">
          <input type="date" style="width: 130px;" mat-label="test" id="fromDate" value="{{basicFromDate}}" onkeydown="return false">
          <span style="width: 15px"></span>
          <span style="width: 15px"></span>
          <input type="date" style="width: 130px;" id="toDate" value="{{basicToDate}}" onkeydown="return false">
        </div>
        <br>
        <div fxLayout="column" fxLayoutAlign="space-around center" >
          <button mat-icon-button>
            <mat-icon (click)="createRoute(true)">refresh</mat-icon>
          </button>
        </div>
      </div>
      <div class="data-container">
        <mat-tab-group id="tab">
          <mat-tab [label]="globals.languageTable_res.get(2166)">
            <div class="table-data-container">
              <table mat-table matSort [dataSource]="tripDataSource" class="mat-elevation-z8 table-view scrollable">

                <ng-container matColumnDef="begin">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{globals.languageTable_res.get(2039)}}</th>
                  <td mat-cell *matCellDef="let record">
                      {{record.begin}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="end">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{globals.languageTable_res.get(2040)}}</th>
                  <td mat-cell *matCellDef="let record">
                      {{record.end}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="duration">
                  <th mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2057)}}</th>
                  <td mat-cell *matCellDef="let record">
                      {{record.duration}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="distance">
                  <th mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2074)}} [km]</th>
                  <td mat-cell *matCellDef="let record">
                      {{record.distance}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="color">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let record">
                    <span class="standard-text">
                      <input [(colorPicker)]="record.polyColor" (colorPickerSelect)="changeColor(record)" [style.background]="record.polyColor"
                      class="color-input clickable" title="{{globals.languageTable_res.get(2061)}}"/>
                    </span>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedTripColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedTripColumns;"></tr>
              </table>
              <ng-container *ngIf="tripDataSource.data.length === 0">
                <h2>{{globals.languageTable_res.get(2042)}}</h2>
              </ng-container>
            </div>
          </mat-tab>
          <mat-tab [label]="'Events'">
            <div class="table-data-container">
              <table mat-table matSort  [dataSource]="eventDataSource" class="mat-elevation-z8 table-view scrollable">

                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{globals.languageTable_res.get(2115)}}</th>
                  <td mat-cell *matCellDef="let record">
                    {{record.date}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{globals.languageTable_res.get(2163)}}</th>
                  <td mat-cell *matCellDef="let record">
                      {{record.address}}
                  </td>
                </ng-container>

                <!-- <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{globals.languageTable_res.get(2103)}}</th>
                  <td mat-cell *matCellDef="let record">
                    <mat-icon class="secondary-icon unclickable" [style.color]="record.color" *ngIf="record.iconName">{{record.iconName}}</mat-icon>
                    {{record.name}}
                  </td>
                </ng-container> -->

                <tr mat-header-row *matHeaderRowDef="displayedEventColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedEventColumns;" (click)="selectEvent(row)" class="clickable"></tr>
              </table>
              <ng-container *ngIf="eventDataSource.data.length === 0">
                <h2>{{globals.languageTable_res.get(2042)}}</h2>
              </ng-container>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <div class="right">
      <div class="map-container">
        <div class="map-container" *ngIf="showMap">
          <app-google-map #routingMapComponent [trafficLayer]="false" [searchBox]="true" [drawingManager]="false" [mapType]="'satellite'" [mapReadyState]="mapReadyState"></app-google-map>
        </div>
      </div>
      <div class="map-footer-container">
        <div class="diagram-filter">
          <mat-select style="background-color: white; width: 150px;" [formControl]="diagramCtrl" placeholder="{{languageService.getTranslationFromKeyword(diagramFilter[0])}}" #diagramSelect (selectionChange)="createChart($event.value)">
            <mat-option *ngFor="let filter of diagramFilter" [value]="filter">
              {{languageService.getTranslationFromKeyword(filter)}}
            </mat-option>
          </mat-select>
        </div>
        <div class="diagram-container">
          <div id="chart" style="width: 100%; height: 100%;">
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button class="dialog-controll-button"(click)="cancel()">{{globals.languageTable_res.get(2035)}}</button>
</mat-dialog-actions>








