import { Injectable } from '@angular/core';
import { Chart } from 'chart.js';
import { Globals } from 'src/app/globals';

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  today: any[];
  thisWeek: any[];
  thisMonth: any[];
  thisYear: any[];
  longer: any[];

  constructor(public globals: Globals) { }

  createTrackingDevicesLifecounterOverviewChart(chart, viewEntryList) {
    this.today = new Array();
    this.thisWeek = new Array();
    this.thisMonth = new Array();
    this.thisYear = new Array();
    this.longer = new Array();

    for (const v of viewEntryList) {
      if (this.isToday(v.recordingDatetime)) {
        this.today.push(v);
      } else if (this.isThisWeek(v.recordingDatetime)) {
        this.thisWeek.push(v);
      } else {
        this.longer.push(v);
      }
    }

    const c = new Chart(chart, {
      type: 'doughnut',
      options: {
        title : {
          display: true,
          text: this.globals.languageTable_res.get(2002),
          position: 'top'
        },
        legend: {
          position: 'bottom',
        }
      },
      data: {
        labels: [
          // today
          this.globals.languageTable_res.get(2003),
          // this week
          this.globals.languageTable_res.get(2004),
          // older
          this.globals.languageTable_res.get(2005)
                ],
        datasets: [{
          data: [this.today.length, this.thisWeek.length, this.longer.length],
          backgroundColor: [
            '#2FF247',
            '#FFAA12',
            '#A23657',
          ]
        }]
      }
    });

  }

  public isToday(someDate) {
    const today = new Date();
    const date = new Date(someDate);

    return date.getDay() === today.getDay() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  }

  public isThisWeek(someDate: Date) {
    const today = new Date();
    const date = new Date(someDate);

    if (date.getDay() - today.getDay() <= 7) {
      return date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
    }

    return false;
  }

  public isThisMonth(someDate: Date) {
    const today = new Date();
    const date = new Date(someDate);

    return date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
  }

  public isThisYear(someDate: Date) {
    const today = new Date();
    const date = new Date(someDate);

    return date.getFullYear() === today.getFullYear();
  }
}
