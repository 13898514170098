<h2 mat-dialog-title>{{globals.languageTable_res.get(2052)}}</h2>

<mat-dialog-content [formGroup]="equipmentGroupFg" class="flex-container">
  <div>
    <input formControlName="description" matInput placeholder="{{globals.languageTable_res.get(2010)}}" required="true" minlength="2">
  </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button class="dialog-controll-button" (click)="cancel()">{{globals.languageTable_res.get(2018)}}</button>
  <button class="dialog-controll-button" [disabled]="equipmentGroupFg.get('description').invalid" (click)="save()">{{globals.languageTable_res.get(2019)}}</button>
</mat-dialog-actions>
