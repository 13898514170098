<ng-container *ngIf="!empty">
  <div class="entry-container" *ngFor="let entry of views">
    <app-inside-entry [entry]="entry"></app-inside-entry>
  </div>
</ng-container>
<ng-container *ngIf="empty">
  <div class="entry-container">
    <span class="standard-text">
      {{languageService.getTranslation(2095)}}
    </span>
  </div>
</ng-container>
