<!-- FLEET NAVIGATION -->
<div class="content">
  <div class="map-container">
    <div class="google-map">
      <app-google-map #mapComponent [trafficLayer]="true" [searchBox]="searchBox" [drawingManager]="drawingManager" [mapType]="mapType" [mapReadyState]="mapReadyState"></app-google-map>
    </div>
    <div class="timer">
      <app-timer [refreshTime]="refreshTime" [refreshDataBool]="refreshDataBool"></app-timer>
    </div>
  </div>

  <div class="data-container">
    <app-fleet-list [mapComponent]="mapComponent" [refreshData]="refreshData" [dataLoaded]="dataLoaded"></app-fleet-list>
  </div>
</div>
