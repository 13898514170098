/**
 * MdtDbWebService
 * This is a service for basic communication with database
 *
 * OpenAPI spec version: 1.0.0.0
 * Contact: info@moderndrive.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from './encoder';
import { Observable } from 'rxjs';
import { Equipment } from '../model/mdtdb/equipment';
import { Configuration } from './configuration';
import { AppConfigService } from '../app-config.service';
import { SessionHelper } from './sessionhelper';
import { EquipmentDeviceInfo } from '../view/equipmentDeviceInfo';


@Injectable()
export class EquipmentService {

    protected basePath = Configuration.getServiceBasePath();
    public defaultHeaders = new HttpHeaders();
    public configuration  = new Configuration();

    constructor(protected httpClient: HttpClient, private appConfig: AppConfigService) {
        this.basePath = appConfig.getConfig().webservice.url;
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    /**
     *
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public equipmentGetAllEquipments(status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Equipment>>;
    public equipmentGetAllEquipments(status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Equipment>>>;
    public equipmentGetAllEquipments(status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Equipment>>>;
    public equipmentGetAllEquipments(status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', status);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Equipment>>(`${this.basePath}/api/Equipment/GetAllEquipments`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     *
     * @param customerId customerId
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public equipmentGetEquipmentsForCustomer(customerId: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Equipment>>;
    public equipmentGetEquipmentsForCustomer(customerId: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Equipment>>>;
    public equipmentGetEquipmentsForCustomer(customerId: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Equipment>>>;
    public equipmentGetEquipmentsForCustomer(customerId: string, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling equipmentGetEquipmentsForCustomer.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', status);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Equipment>>(`${this.basePath}/api/Equipment/GetEquipmentsForCustomer/${encodeURIComponent(String(customerId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     *
     * @param id
     * @param licencePlate
     * @param modifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public equipmentChangeLicencePlate(id: string, licencePlate?: string, modifier?: string, observe?: 'body', reportProgress?: boolean): Observable<Equipment>;
    public equipmentChangeLicencePlate(id: string, licencePlate?: string, modifier?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Equipment>>;
    public equipmentChangeLicencePlate(id: string, licencePlate?: string, modifier?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Equipment>>;
    public equipmentChangeLicencePlate(id: string, licencePlate?: string, modifier?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling equipmentChangeLicencePlate.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (licencePlate !== undefined && licencePlate !== null) {
            queryParameters = queryParameters.set('licencePlate', <any>licencePlate);
        }
        if (modifier !== undefined && modifier !== null) {
            queryParameters = queryParameters.set('modifier', <any>modifier);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Equipment>(`${this.basePath}/api/Equipment/ChangeLicencePlate/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * @param userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public equipmentUserIsFleetManagerOrHigher(userId: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public equipmentUserIsFleetManagerOrHigher(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public equipmentUserIsFleetManagerOrHigher(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public equipmentUserIsFleetManagerOrHigher(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling equipmentUserIsFleetManagerOrHigher.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/api/Equipment/UserIsFleetManagerOrHigher/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public equipmentGetEquipmentDeviceInfo(body: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<EquipmentDeviceInfo>>;
    public equipmentGetEquipmentDeviceInfo(body: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EquipmentDeviceInfo>>>;
    public equipmentGetEquipmentDeviceInfo(body: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EquipmentDeviceInfo>>>;
    public equipmentGetEquipmentDeviceInfo(body: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling equipmentGetEquipmentDeviceInfo.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<EquipmentDeviceInfo>>(`${this.basePath}/api/Equipment/GetEquipmentDeviceInfo`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
