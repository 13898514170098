import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DriverService } from 'src/app/generated-services/driver.service';
import { TachographService } from 'src/app/generated-services/tachograph.service';
import { Globals } from 'src/app/globals';
import { Driver } from 'src/app/model/mdtdb/driver';
import { InfoService } from 'src/app/service/info.service';

@Component({
  selector: 'app-tachograph-data',
  templateUrl: './tachograph-data.component.html',
  styleUrls: ['./tachograph-data.component.css']
})
export class TachographDataComponent implements OnInit {

  id: any;
  data: any;
  dailyData: any[];
  driver: Driver;

  constructor(@Inject(MAT_DIALOG_DATA) private _data: any,
              private _dialogRef: MatDialogRef<TachographDataComponent>,
              private _driverService: DriverService,
              public globals: Globals,
              private _infoService: InfoService,
              private _tachographService: TachographService) {
                this.id = _data;
              }

  ngOnInit() {
    console.log(this.id);
    this.getLatestData();
    this.getDailyData();
  }

  private getLatestData() {
    this._tachographService.tachographGetTachographData(this.id).subscribe((result: any) => {
      console.log(result);
      if (result && result.driver1Info) {
        this.data = result;
        var startDate = new Date();
        var lastDate = new Date(result.timestamp);
        startDate.setHours(0, 0, 0);
        // check if last data is current daily data
        if (lastDate < startDate) this.showNoInfosFound();
        console.log(this.data);
        if (this.data && this.data.driver1Info) {
          this.loadDriverData(this.data.driver1Info.driverId);
        }
      } else {
        this.showNoInfosFound();
      }
    });
  }

  private showNoInfosFound() {
    this._infoService.showInfoWindow(Globals.TYPE_INFO, this.globals.languageTable_res.get(2123));
    this.cancel();
  }

  private loadDriverData(identifier: string) {
    if (identifier) {
      this._driverService.driverGet(identifier).subscribe((result: any) => {
        this.driver = result;
      });
    }
  }

  /* Service not working */
  private getDailyData() {
    var from = new Date();
    from.setHours(0, 0, 0);
    var to = new Date();
    to.setHours(23, 59, 59);

    this._tachographService.tachographGetTachographDatas(this.id, from, to).subscribe((result: any) => {
      this.dailyData = result;
      console.log(result);
    });
  }

  public cancel() {
    this._dialogRef.close();
  }

  public get dailyBreakTime() {
    if (this.data && this.data.driver1Info) return this.data.driver1Info.cumulativeBreakTime;
    return null;
  }

  public get dailyDriveTime() {
    if (this.data && this.data.driver1Info) return this.data.driver1Info.currentDailyDriveTime;
    return null;
  }

  public get driverId() {
    if (this.data && this.data.driver1Info && this.data.driver1Info.driverId) return this.data.driver1Info.driverId;
    return this.globals.languageTable_res.get(2122);
  }

  public get driverName() {
    if (this.driver) return this.driver.forename + ' ' + this.driver.lastname;
    return this.globals.languageTable_res.get(2121);
  }

  public get restDriveTime() {
    if (this.dailyDriveTime) return 540 - this.dailyDriveTime;
  }

  public get workStart() {
    if (this.dailyData && this.dailyData.length) return this.dailyData[0].timestamp;
    return null;
  }

  /* Cast amount of seconds to hh:mm:ss format - max. 24 hours, else overflow */
  public castToHours(minutes) {
    if (minutes) {
      var hours: any = Math.floor(minutes / 60)
      var min: any = Math.floor(minutes % 60);

      if (min <= 9) min = '0' + min;
      if (hours == 0) hours = '00';
      // if (hours <= 9 && hours > 0) hours = '0' + hours;
      return hours + 'h' + ' ' + min + 'min';
    }
    return minutes;
  }


}
