<a mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}" (click)="onItemSelected(item)"
   [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}"
   class="menu-list-item">
  <mat-icon class="route-icon">{{item.iconName}}</mat-icon>
  <span class="item-text">{{globals.languageTable_res.get(item.displayName)}}</span>
  <span fxFlex *ngIf="item.children && item.children.length">
    <span fxFlex></span>
    <span class="navigation-icon-span">
      <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'" class="navigation-icon">
        expand_more
      </mat-icon>
    </span>
  </span>
  <span fxFlex *ngIf="!item.children || !item.children.length">
    <span fxFlex></span>
    <mat-icon class="navigation-icon">
      chevron_right
    </mat-icon>
  </span>
</a>
<div *ngIf="expanded">
  <app-sidenav-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
  </app-sidenav-menu-list-item>
</div>
