import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { LoadingSpinnerComponent } from 'src/app/component/filter/loading-spinner/loading-spinner.component';
import { GoogleMapComponent } from 'src/app/component/tracking/google-map/google-map.component';
import { DeviceService } from 'src/app/generated-services/device.service';
import { Globals } from 'src/app/globals';
import { TdStatus } from 'src/app/model/mdtdata/tdStatus';
import { InfoService } from 'src/app/service/info.service';
import { LanguageService } from 'src/app/service/language.service';
import { SimConnectivityInfo } from 'src/app/view/wherever-sim/simConnectivityInfo';

@Component({
  selector: 'app-sim-tracking',
  templateUrl: './sim-tracking.component.html',
  styleUrls: ['./sim-tracking.component.css']
})
export class SimTrackingComponent implements OnInit, AfterViewInit {
  @ViewChild('mapComponent', {static: false}) mapComponent: GoogleMapComponent;

  deviceId: string;
  endpointId: string;
  serial: string;
  licencePlate: string;
  connectivityInfo: SimConnectivityInfo;
  mapReadyState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private cdr: ChangeDetectorRef,
              @Inject(MAT_DIALOG_DATA) private _data: any,
              private _deviceService: DeviceService,
              private _dialogRef: MatDialogRef<SimTrackingComponent>,
              public globals: Globals,
              private _infoService: InfoService,
              private _languageService: LanguageService,
              private _loadingSpinnerDialog: MatDialog) {
                this.serial = _data.serial;
                this.licencePlate = _data.licencePlate;
                this.deviceId = _data.deviceId;
                this.endpointId = _data.endpointId;
               }

  ngOnInit() {
    this._loadingSpinnerDialog.open(LoadingSpinnerComponent, {
      data: {
        diameter: 57,
        loading: this.dataLoaded
      }
    });

    this.loadData();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public createMarker() {
    if (this.connectivityInfo && this.connectivityInfo.radioCell && this.connectivityInfo.radioCell.radioCellLocation) {
      var latLng = new google.maps.LatLng(this.connectivityInfo.radioCell.radioCellLocation.lat, this.connectivityInfo.radioCell.radioCellLocation.lon);
      var cellId = this.connectivityInfo.radioCell.cid.toString();

      var marker = new google.maps.Marker({
        position: latLng,
        title: cellId,
      });

      // add info window to marker
      var infowindow = new google.maps.InfoWindow({
        content: this._languageService.getTranslation(2142) + " " + cellId
      });

      marker.addListener('click', function() {
        infowindow.open(this.getMap(), marker);
      });
      infowindow.open(this.mapComponent.map,marker);

      marker.setMap(this.mapComponent.map);

      this.mapComponent.map.setCenter(latLng);
      this.mapComponent.map.setZoom(13);
    }
  }

  public close() {
    this._dialogRef.close();
  }

  private loadData() {
    // check if wherever endpoint is set for wherever API tracking
    if (this.endpointId) {
      this.whereverSimTracking();
    } else {
      // td_status tracking
      this.tdStatusTracking();
    }
  }

  private whereverSimTracking() {
    this._deviceService.deviceSimConnectivityInfo(this.serial).toPromise().then((response: any) => {
      if (response) {
        this.connectivityInfo = response;
        this.createMarker();
        this.dataLoaded.next(true);
      }
    }).catch((err: HttpErrorResponse) => {
      this.dataLoaded.next(true);
      console.log(err.error);
      this._infoService.showInfoWindow(Globals.TYPE_INFO, this._languageService.getTranslationFromKeyword('no_cell_tracking_information_found'));
    });
  }

  private tdStatusTracking() {
    this._deviceService.deviceLastGsmInfo(this.deviceId).toPromise().then((td_response: TdStatus) => {
      if (td_response) {
        this._deviceService.deviceGetRadioCellLocation(td_response.gsmMcc, td_response.gsmMnc, td_response.gsmAreaCode, td_response.gsmCellId).toPromise().then((response: any) => {
          if (response) {
            this.connectivityInfo = {
              lastGprsUpdate: td_response.recordingTimestamp,
              lastLocationUpdate: td_response.recordingTimestamp,
              radioCell: {
                mcc: td_response.gsmMcc,
                mnc: td_response.gsmMnc,
                lac: td_response.gsmAreaCode,
                cid: td_response.gsmCellId,
                radioCellLocation: response
              }
            };

            this.createMarker();
            this.dataLoaded.next(true);
          } else {
            this.dataLoaded.next(true);
            this._infoService.showInfoWindow(Globals.TYPE_INFO, this._languageService.getTranslationFromKeyword('no_cell_tracking_information_found'));
          }
        }).catch((err: HttpErrorResponse) => {
          this.dataLoaded.next(true);
          console.log(err.error);
          this._infoService.showInfoWindow(Globals.TYPE_INFO, this._languageService.getTranslationFromKeyword('no_cell_tracking_information_found'));
        });
      } else {
        this.dataLoaded.next(true);
        this._infoService.showInfoWindow(Globals.TYPE_INFO, this._languageService.getTranslationFromKeyword('no_cell_tracking_information_found'));
      }
    }).catch((err: HttpErrorResponse) => {
      this.dataLoaded.next(true);
      console.log(err.error);
      this._infoService.showInfoWindow(Globals.TYPE_INFO, this._languageService.getTranslationFromKeyword('no_cell_tracking_information_found'));
    });
  }

  // getter
  public get languageService(): LanguageService {
    return this._languageService;
  }

  public getLastLocationUpdate() {
    if (this.connectivityInfo) return this._languageService.getLocaleDateTime(this.connectivityInfo.lastLocationUpdate);
  }

  public getLastGprsUpdate() {
    if (this.connectivityInfo) return this._languageService.getLocaleDateTime(this.connectivityInfo.lastGprsUpdate);
  }
}
