<h2 mat-dialog-title>{{description}}</h2>

<mat-dialog-content>
  {{globals.languageTable_res.get(2017)}}
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button class="dialog-controll-button"(click)="cancel()">{{globals.languageTable_res.get(2018)}}</button>
  <button class="dialog-controll-button"(click)="delete()">{{globals.languageTable_res.get(2012)}}</button>
</mat-dialog-actions>
