import { Injectable } from '@angular/core';
import { InfoService } from './info.service';
import { LanguageService } from './language.service';
import { DialogResponse } from '../view/dialogResponse';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  public static RESPONSE_CODE_CANCEL = "100";
  public static RESPONSE_CODE_SUCCESS = "200";
  public static RESPONSE_CODE_ERROR = "300";

  constructor(public globals: Globals,
              private _infoService: InfoService) { }

  public evaluateResponse(response: DialogResponse) {
    if (response) {
      if (response.responseCode === DialogService.RESPONSE_CODE_SUCCESS) {
        if (response.data) {
          // this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_SUCCESS);
          return true;
        } else {
          // show info with no data available
          this._infoService.showInfoWindow(Globals.TYPE_INFO, this.globals.languageTable_res.get(2042));
        }
        // return true to trigger workflow for result data
      } else if (response.responseCode === DialogService.RESPONSE_CODE_ERROR) {
        // show error message
        this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_ERROR);
        return false;
      } else {
        // do nothing if cancel is clicked
        return false;
      }
    }
  }
}
