<h2 mat-dialog-title>{{description}} - {{globals.languageTable_res.get(2054)}}</h2>

<mat-dialog-content class="dialog-content">
  <h3>{{globals.languageTable_res.get(2125)}}: {{from}} - {{to}}</h3>
  <div class="scrollable">
    <table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" class="mat-elevation-z8 table-view">
      <ng-container matColumnDef="licencePlate">
        <th mat-header-cell *matHeaderCellDef>
          {{globals.languageTable_res.get(2026)}}
        </th>
        <td mat-cell *matCellDef="let record">
          <span class="standard-text">
            {{record.licencePlate}}
          </span>
        </td>
        <td mat-footer-cell *matFooterCellDef>{{globals.languageTable_res.get(2066)}}</td>
      </ng-container>

      <ng-container matColumnDef="entrance">
        <th mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2055)}}</th>
        <td mat-cell *matCellDef="let record">
          <span class="standard-text">
            {{getDateTimeOfEntry(record.entrance)}}
          </span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="exit">
        <th mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2056)}}</th>
        <td mat-cell *matCellDef="let record">
          <span class="standard-text">
            {{getDateTimeOfEntry(record.exit)}}
          </span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2057)}}</th>
        <td mat-cell *matCellDef="let record">
          <span class="standard-text">
            {{record.duration}}
          </span>
        </td>
        <td mat-footer-cell *matFooterCellDef>{{sumDuration}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row  *matFooterRowDef="displayedColumns"></tr>
    </table>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button class="dialog-controll-button"(click)="cancel()">{{globals.languageTable_res.get(2035)}}</button>
  <button class="dialog-controll-button"(click)="exporter.exportTable('xlsx')">{{globals.languageTable_res.get(2065)}}</button>
</mat-dialog-actions>
