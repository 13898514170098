import { Definitions } from './definitions';
import {} from 'googlemaps';

export class ProjectCommon {
  public static PORTAL_VERSION = "A5.5";
  public static WEBSERVICE_VERSION = "2.0.0";

  /* Remove all items from session storage. */
  public static ClearSessionStorage() {
    sessionStorage.clear();
  }

  /* Method to receive the current map location from sessionStorage */
  public static GetMapLocation(): google.maps.LatLng {
    let res = null;

    const sess = sessionStorage.getItem(Definitions.TRACKING_CURRENT_LOCATION);
    if (sess) {
      // convert string lat,long to google LatLng
      const split = sess.split(',');

      if (split && split.length == 2) {
        res = new google.maps.LatLng(+split[0], +split[1]);
      }
    }

    return res;
  }

  /* Method to receive the current map zoom from sessionStorage */
  public static GetMapZoom(): number {
    return +sessionStorage.getItem(Definitions.TRACKING_MAP_ZOOM);
  }

  /* Method to receive the current sessionId from sessionStorage */
  public static GetSessionId() {
    return sessionStorage.getItem(Definitions.SESSION_ID);
  }

  /* Method to receive boolean which determines if user is logged in from sessionStorage*/
  public static GetUserLoggedIn(): boolean {
    const val = sessionStorage.getItem(Definitions.USER_LOGGED_IN);

    if (val) {
      return (val.toLocaleLowerCase() === 'true');
    }

    return false;
  }

  /* Remove credentials for map location */
  public static RemoveMapLocation() {
    sessionStorage.removeItem(Definitions.TRACKING_MAP_ZOOM);
    sessionStorage.removeItem(Definitions.TRACKING_CURRENT_LOCATION);
  }

  /* Method to remove sessionId from sessionStorage */
  public static RemoveSessionId() {
    sessionStorage.removeItem(Definitions.SESSION_ID);
  }

  /* Method to remove boolean which determines if user is logged in from sessionStorage */
  public static RemoveUserLoggedIn() {
    sessionStorage.removeItem(Definitions.USER_LOGGED_IN);
  }

  /* Method to save the current map location in sessonStorage */
  public static SetMapLocation(latLng: google.maps.LatLng) {
    if (latLng) {
      const res = latLng.lat().toString() + ',' + latLng.lng().toString();

      sessionStorage.setItem(Definitions.TRACKING_CURRENT_LOCATION, res);
    }
  }

  /* Method to save the current map zoom in sessionStorage */
  public static SetMapZoom(zoom: number) {
    if (zoom) {
      sessionStorage.setItem(Definitions.TRACKING_MAP_ZOOM, zoom.toString());
    }
  }

  /* Method to save the sessionId in sessionStorage */
  public static SetSessionId(id: string) {
    sessionStorage.setItem(Definitions.SESSION_ID, id);
  }
}
