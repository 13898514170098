<h2 mat-dialog-title>Fahrerdetails</h2>

<mat-dialog-content class="flex-container">
  <table id="entry-table">
    <tr>
      <td>
        <mat-icon class="secondary-icon unclickable">account_circle</mat-icon>
        <span class="standard-text">
          {{driverName}}
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <mat-icon class="secondary-icon unclickable">credit_card</mat-icon>
        <span class="standard-text">
          {{driverId}}
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <mat-icon class="secondary-icon unclickable">timer</mat-icon>
        <span class="standard-text">
          {{globals.languageTable_res.get(2120)}}: {{castToHours(dailyDriveTime)}}
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <mat-icon class="secondary-icon unclickable">timer</mat-icon>
        <span class="standard-text">
          {{globals.languageTable_res.get(2119)}}: {{castToHours(restDriveTime)}}
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <mat-icon class="secondary-icon unclickable">timer</mat-icon>
        <span class="standard-text">
          {{globals.languageTable_res.get(2118)}}: {{dailyBreakTime}} min
        </span>
      </td>
    </tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button class="dialog-controll-button" (click)="cancel()">{{globals.languageTable_res.get(2035)}}</button>
</mat-dialog-actions>




