<div id="fleet-data-container" class="data-container">
  <div id="fleet-data-menu" class="data-menu" class="data-menu">
    <mat-toolbar class="thirdy-toolbar small-toolbar">
      <mat-toolbar-row>
        <mat-select placeholder="{{globals.languageTable_res.get(2038)}}" class="group-selection"
        (selectionChange)="selectGroup($event.value)">
          <mat-option *ngFor="let group of equipmentGroups" [value]="group">
            <span class="select-item">{{group.groupName}}</span>
          </mat-option>
        </mat-select>
        <span style="width: 30px;"></span>
        <input matInput style="width: 250px;" (keyup)="searchVehicle($event.target.value)" placeholder="{{globals.languageTable_res.get(2164)}}  . . . ">
        <span style="width: 30px;"></span>
        <input matInput style="width: 250px;" (keyup)="searchDevice($event.target.value)" placeholder="{{globals.languageTable_res.get(2165)}}   . . . ">
        <span style="width: 30px;"></span>
        <div style="float: right">
          <button button id="traffic-button" class="dialog-controll-button-small" (click)="selectTraffic()" title="{{globals.languageTable_res.get(2169)}}">
            <mat-icon class="primary-icon clickable">remove_road</mat-icon>
          </button>
        </div>
        <span style="width: 30px;"></span>
        <div style="float: right">
          <button button id="cluster-button" class="dialog-controll-button-small" (click)="selectCluster()" title="Clustering">
            <mat-icon class="primary-icon clickable">hive</mat-icon>
          </button>
        </div>
        <span style="width: 30px;"></span>
        <div style="float: right">
          <button button id="geozone-button" class="dialog-controll-button-small" (click)="selectView()" title="{{globals.languageTable_res.get(2009)}}">
            <mat-icon class="primary-icon clickable">control_camera</mat-icon>
          </button>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>

  <div id="fleet-data-table-container" class="data-table-container">
    <table mat-table matSort matTableExporter #trackingExporter="matTableExporter" [hiddenColumns]="getExportExcludingColumns()" [dataSource]="trackingDataSource" class="mat-elevation-z8 table-view dark scrollable">

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="header-text"></th>
        <td mat-cell *matCellDef="let record">
          <mat-icon class="primary-icon small clickable" (click)="openLicencePlateEditingDialog(record.entry)" title="{{globals.languageTable_res.get(2087)}}" style="float: left; font-size: 15px">edit</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="licencePlate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-text">{{globals.languageTable_res.get(2162)}}</th>
        <td mat-cell *matCellDef="let record">
          <div class="clickable">
            <span id="{{record.entry.equipmentId}}" class="header-text" title="Device: {{record.entry.deviceSerial}}">
              {{record.entry.licencePlate}}
            </span>
            <ng-container *ngIf="record.entry.lastIgnitionOn">
              <mat-icon class="primary-icon small clickable" [style.color]="record.ignitionIdentifier.color"
                title="{{record.ignitionIdentifier.title}}"
                style="float: left; font-size: 15px">power_settings_new</mat-icon>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="deviceSerial">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-text">{{globals.languageTable_res.get(2170)}}</th>
        <td mat-cell *matCellDef="let record">
          <ng-container *ngIf="record.entry">
            <span class="standard-text clickable" title="{{globals.languageTable_res.get(2170)}}">
              {{record.entry.deviceSerial}}
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef class="header-text">{{globals.languageTable_res.get(2163)}}</th>
        <td mat-cell *matCellDef="let record">
          <ng-container *ngIf="addressesLoaded.value">
            <span class="standard-text">
              {{record.entry.address}}
            </span>
          </ng-container>
          <ng-container *ngIf="!addressesLoaded.value">
            <span class="standard-text">
              {{globals.languageTable_res.get(2131)}}
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-text">{{globals.languageTable_res.get(2148)}}</th>
        <td mat-cell *matCellDef="let record">
          <ng-container *ngIf="record.position">
            <span class="standard-text clickable" [style.color]="record.position.color" title="{{record.position.title}}">
              {{record.position.description}}
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastIncomingData">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-text">{{globals.languageTable_res.get(2167)}}</th>
        <td mat-cell *matCellDef="let record">
          <ng-container *ngIf="record.lastIncomingData">
            <span class="standard-text">
              {{record.lastIncomingData.description}}
            </span>
          </ng-container>
          <ng-container *ngIf="!deviceDataLoaded.value">
            <span class="standard-text">
              {{globals.languageTable_res.get(2131)}}
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastStart">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-text">{{globals.languageTable_res.get(2158)}}</th>
        <td mat-cell *matCellDef="let record">
          <ng-container *ngIf="record.entry.lastTrip">
            <span class="standard-text">
              {{record.lastStart.description}}
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastStop">
        <th mat-header-cell *matHeaderCellDef class="header-text">{{globals.languageTable_res.get(2159)}}</th>
        <td mat-cell *matCellDef="let record">
          <ng-container *ngIf="record.entry.lastTrip">
            <span class="standard-text">
              {{record.lastStop.value}}
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="mileage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-text">{{globals.languageTable_res.get(2116)}} [km]</th>
        <td mat-cell *matCellDef="let record">
          <ng-container *ngIf="record.entry.lastTrip">
            <span class="standard-text">
              {{record.mileage.value}}
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="batteryVoltage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-text">{{globals.languageTable_res.get(2073)}} [V]</th>
        <td mat-cell *matCellDef="let record">
          <ng-container *ngIf="record.entry.deviceStatus">
            <span class="standard-text" title="{{record.entry.deviceStatus.recordingTimestamp}}">
              {{record.entry.deviceStatus.batteryVoltage}} V
            </span>
          </ng-container>
          <ng-container *ngIf="!deviceDataLoaded.value">
            <span class="standard-text">
              {{globals.languageTable_res.get(2131)}}
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="header-text"></th>
        <td mat-cell *matCellDef="let record">
          <ng-container>
            <div class="left-flex-container">
              <div>
                <button button class="dialog-controll-button-small" (click)="openGeozoneAlarmDialog(record.entry)" title="Alarm">
                  <mat-icon class="primary-icon clickable">notifications_active</mat-icon>
                </button>
              </div>
              <ng-container *ngIf="positionsPermission">
                <div>
                  <button button class="dialog-controll-button-small" (click)="openRoutingDialog(record.entry)" title="Route">
                    <mat-icon class="primary-icon clickable">gesture</mat-icon>
                  </button>
                </div>
              </ng-container>
              <ng-container *ngIf="deviceHasSimTracking(record.entry)">
                <div>
                  <button button class="dialog-controll-button-small" (click)="openSimTracking(record.entry)" title="Sim-Tracking">
                    <mat-icon class="primary-icon clickable">settings_input_antenna</mat-icon>
                  </button>
                </div>
              </ng-container>
              <ng-container *ngIf="tachographPermission && record.entry.deviceType && record.entry.deviceType.toLowerCase() === 'fmc640'">
                <div>
                  <button button class="dialog-controll-button-small" (click)="openTachographData(record.entry)" title="Details">
                    <mat-icon class="primary-icon clickable">speed</mat-icon>
                  </button>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedTrackingColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedTrackingColumns;" (click)="selectEntry(row.entry)"></tr>
      </table>
    <div>
      <button mat-raised-button style="background-color: transparent; color: white;" (click)="trackingExporter.exportTable('xlsx')">Excel</button>
      <button mat-raised-button style="background-color: transparent; color: white;" (click)="trackingExporter.exportTable('csv')">Csv</button>
      <button mat-raised-button style="background-color: transparent; color: white;" (click)="trackingExporter.exportTable('txt')">Txt</button>
    </div>
  </div>
</div>

<div id="geozones-data-container" class="data-container" *ngIf="showGeozones">
  <div id="geozones-data-menu" class="data-menu" class="data-menu">
    <mat-toolbar class="thirdy-toolbar small-toolbar">
      <mat-toolbar-row>
        <mat-select placeholder="Alle Geozonen" class="group-selection unclickable">
        </mat-select>
        <span style="width: 30px;"></span>
        <div style="float: right">
          <button button id="geozone-button" class="dialog-controll-button-small" (click)="selectView()" title="{{globals.languageTable_res.get(2162)}}">
            <mat-icon class="primary-icon clickable">commute</mat-icon>
          </button>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>

  <div id="geozones-data-table-container" class="data-table-container">
    <app-geo-zones-inside></app-geo-zones-inside>
  </div>
</div>


