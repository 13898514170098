import { Component, OnInit, Input } from '@angular/core';
import {} from 'googlemaps';
import { Observable, BehaviorSubject } from 'rxjs';
import { Definitions } from 'src/app/definitions';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.css']
})
export class GoogleMapComponent implements OnInit {
  @Input() trafficLayer: boolean;
  @Input() searchBox: boolean;
  @Input() drawingManager: boolean;
  @Input() mapType: String;
  @Input() mapReadyState: BehaviorSubject<boolean>;

  constructor(public globals: Globals) { }

  public mapReady$ = new Observable<boolean>();
  public static DEFAULT_ZOOM = 5;

  // Google maps params
  map: any;
  openedWindow = "0";
  maxZoomClustering: number;
  shapes = new Array();
  zoneCoordinates = new Array();
  trafficLayerElement: any;

  ngOnInit() {
  }

  public initializeMap() {
    this.map.setCenter(new google.maps.LatLng(50.876519, 10.355150));
    this.map.setZoom(GoogleMapComponent.DEFAULT_ZOOM);

    if (!this.mapType) {
      // set default type to roadmap
      this.mapType = Definitions.MAP_TYPE_ROADMAP;
    }

    this.map.setMapTypeId(this.mapType);
  }

  public setTrafficLayer(active: boolean) {
    if (active) {
      this.trafficLayerElement = new google.maps.TrafficLayer();
      this.trafficLayerElement.setMap(this.map);
    } else {
      if (this.trafficLayerElement) {
        this.trafficLayerElement.setMap(undefined);
        this.trafficLayerElement = undefined;
      }
    }
  }

  /*
  Method to check if google api is fully loaded.
  */
  public mapReady(event: any) {
    this.map = event;

    // add traffic layer
    this.setTrafficLayer(this.trafficLayer);

    this.initializeMap();
    this.mapReadyState.next(true);

    if (this.searchBox) {
      this.initAutocomplete();
    }

    if (this.drawingManager) {
      this.initDrawingManager();
    }
  }

  /** Add a listener for the drawing mode change event, delete any existing polygons */
  private addDrawingModeChangedListener(drawingManager: google.maps.drawing.DrawingManager) {
    google.maps.event.addListener(drawingManager, "drawingmode_changed", () => {
      if (!this.shapes) {
        this.shapes = new Array();
      }
      if (drawingManager.getDrawingMode() != null) {
        for (var i = 0; i < this.shapes.length; i++) {
          this.shapes[i].setMap(null);
        }
        this.shapes = [];
        this.zoneCoordinates = new Array();
      }
    });
  }

  /** Add a listener for creating new shape event. */
  private addOverlayCompleteListener(drawingManager: google.maps.drawing.DrawingManager) {
    google.maps.event.addListener(drawingManager, "overlaycomplete", (event) => {
      if (!this.shapes) {
        this.shapes = new Array();
      }
      var newShape = event.overlay;
      newShape.type = event.type;
      this.shapes.push(newShape);
      if (drawingManager.getDrawingMode()) {
        drawingManager.setDrawingMode(null);
      }

      this.zoneCoordinates = event.overlay.getPath().getArray();
    });
  }

  /** Initialize drawing manager to create polygons on google map. Only allowed shape is currently polygon. */
  private initDrawingManager() {
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYGON
        ]
      },
      polygonOptions: {
        draggable: true,
        editable: true
      },
      drawingMode: null
    };

    const drawingManager = new google.maps.drawing.DrawingManager(options);
    drawingManager.setOptions(options);
    drawingManager.setMap(this.map);

    this.addOverlayCompleteListener(drawingManager);
    this.addDrawingModeChangedListener(drawingManager);
  }

  public initAutocomplete() {

    // Create the search box and link it to the UI element.
    const input = document.getElementById("pac-input") as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

    // Bias the SearchBox results towards current map's viewport.
    this.map.addListener("bounds_changed", () => {
      searchBox.setBounds(this.map.getBounds() as google.maps.LatLngBounds);
    });

    let markers: google.maps.Marker[] = [];
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }

      // Clear out the old markers.
      markers.forEach(marker => {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();
      places.forEach(place => {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        const icon = {
          url: place.icon as string,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25)
        };

        // Create a marker for each place.
        markers.push(
          new google.maps.Marker({
            map: this.map,
            icon,
            title: place.name,
            position: place.geometry.location
          })
        );

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.map.fitBounds(bounds);
    });
  }
}
