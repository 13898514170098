/**
 * MdtDbWebService
 * This is a service for basic communication with database
 *
 * OpenAPI spec version: 1.0.0.1
 * Contact: info@moderndrive.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from './encoder';
import { Observable }                                        from 'rxjs';
import { Geozone } from '../model/mdtdb/geozone';
import { Configuration }                                     from './configuration';
import { SessionHelper } from './sessionhelper';
import { AppConfigService } from '../app-config.service';
import { Equipment } from '../model/mdtdb//models';
import { GeozoneReport } from '../view/geozoneReport';
import { GeozoneHasMailRecipient } from '../model/mdtdb/geozoneHasMailRecipient';
import { GeozoneInsideView } from '../view/geozoneInsideView';

@Injectable()
export class GeozoneService {

  public static ALARM_IDENTIFIER = "_RadiusAlarm_";
  protected basePath = Configuration.getServiceBasePath();
  public defaultHeaders = new HttpHeaders();
  public configuration  = new Configuration();

  constructor(protected httpClient: HttpClient, private appConfig: AppConfigService) {
      this.basePath = appConfig.getConfig().webservice.url;
  }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    /**
     * Add an array of Equipment to the given Geozone.
     *
     * @param body
     * @param geozoneId
     * @param creator
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geozoneAddEquipmentsToGeozone(body: Array<string>, geozoneId: string, creator?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public geozoneAddEquipmentsToGeozone(body: Array<string>, geozoneId: string, creator?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public geozoneAddEquipmentsToGeozone(body: Array<string>, geozoneId: string, creator?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public geozoneAddEquipmentsToGeozone(body: Array<string>, geozoneId: string, creator?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling geozoneAddEquipmentsToGeozone.');
        }

        if (geozoneId === null || geozoneId === undefined) {
            throw new Error('Required parameter geozoneId was null or undefined when calling geozoneAddEquipmentsToGeozone.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (creator !== undefined && creator !== null) {
            queryParameters = queryParameters.set('creator', <any>creator);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Blob>(`${this.basePath}/api/Geozone/AddEquipmentsToGeozone/${encodeURIComponent(String(geozoneId))}`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a Geozone entity.
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geozoneCreate(body: Geozone, observe?: 'body', reportProgress?: boolean): Observable<Geozone>;
    public geozoneCreate(body: Geozone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Geozone>>;
    public geozoneCreate(body: Geozone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Geozone>>;
    public geozoneCreate(body: Geozone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling geozoneCreate.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Geozone>(`${this.basePath}/api/Geozone/Create`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a GeozoneHasMailRecipient object.
     *
     * @param body GeozoneHasMailRecipient object.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geozoneCreateGeozoneMailRecipient(body: GeozoneHasMailRecipient, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public geozoneCreateGeozoneMailRecipient(body: GeozoneHasMailRecipient, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public geozoneCreateGeozoneMailRecipient(body: GeozoneHasMailRecipient, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public geozoneCreateGeozoneMailRecipient(body: GeozoneHasMailRecipient, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling geozoneCreateGeozoneMailRecipient.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GeozoneHasMailRecipient>(`${this.basePath}/api/Geozone/CreateGeozoneMailRecipient`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a postgis POLYGON geometry string for given radius and current position defined by latitude and longitude.
     *
     * @param latitude Latitude of position center.
     * @param longitude Longitude of position center.
     * @param radius Radius of geometry in meters.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geozoneCreateZoneStringFromRadius(latitude?: number, longitude?: number, radius?: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public geozoneCreateZoneStringFromRadius(latitude?: number, longitude?: number, radius?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public geozoneCreateZoneStringFromRadius(latitude?: number, longitude?: number, radius?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public geozoneCreateZoneStringFromRadius(latitude?: number, longitude?: number, radius?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (latitude !== undefined && latitude !== null) {
            queryParameters = queryParameters.set('latitude', <any>latitude);
        }
        if (longitude !== undefined && longitude !== null) {
            queryParameters = queryParameters.set('longitude', <any>longitude);
        }
        if (radius !== undefined && radius !== null) {
            queryParameters = queryParameters.set('radius', <any>radius);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/Geozone/CreateZoneStringFromRadius`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an Geozone object for the given id (physical removal).
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geozoneDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public geozoneDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public geozoneDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public geozoneDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling geozoneDelete.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Blob>(`${this.basePath}/api/Geozone/Delete/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Physical remove an array of Equipment from the given Geozone.
     *
     * @param body
     * @param geozoneId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geozoneDeleteEquipmentsFromGeozone(body: Array<string>, geozoneId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public geozoneDeleteEquipmentsFromGeozone(body: Array<string>, geozoneId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public geozoneDeleteEquipmentsFromGeozone(body: Array<string>, geozoneId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public geozoneDeleteEquipmentsFromGeozone(body: Array<string>, geozoneId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling geozoneDeleteEquipmentsFromGeozone.');
        }

        if (geozoneId === null || geozoneId === undefined) {
            throw new Error('Required parameter geozoneId was null or undefined when calling geozoneDeleteEquipmentsFromGeozone.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Blob>(`${this.basePath}/api/Geozone/DeleteEquipmentsFromGeozone/${encodeURIComponent(String(geozoneId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

	/**
     * Returns a Geozone object for given Geozone id.
     *
     * @param geozoneId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geozoneGet(geozoneId: string, observe?: 'body', reportProgress?: boolean): Observable<Geozone>;
    public geozoneGet(geozoneId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Geozone>>;
    public geozoneGet(geozoneId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Geozone>>;
    public geozoneGet(geozoneId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geozoneId === null || geozoneId === undefined) {
            throw new Error('Required parameter geozoneId was null or undefined when calling geozoneGet.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Geozone>(`${this.basePath}/api/Geozone/Get/${encodeURIComponent(String(geozoneId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a list of Equipment objects for given Geozone id mapped on the record status. If status is null, only active stati are returned. Result is ordered by licence plate.
     *
     * @param geozoneId
     * @param status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geozoneGetEquipmentsOfGeozone(geozoneId: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Equipment>>;
    public geozoneGetEquipmentsOfGeozone(geozoneId: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Equipment>>>;
    public geozoneGetEquipmentsOfGeozone(geozoneId: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Equipment>>>;
    public geozoneGetEquipmentsOfGeozone(geozoneId: string, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geozoneId === null || geozoneId === undefined) {
            throw new Error('Required parameter geozoneId was null or undefined when calling geozoneGetEquipmentsOfGeozone.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Equipment>>(`${this.basePath}/api/Geozone/GetEquipmentsOfGeozone/${encodeURIComponent(String(geozoneId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a list of Geozone objects for given Customer id mapped on the given record status. If no status is given, all statuses are returned. Result is ordered by geozone description.
     *
     * @param customerId
     * @param status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geozoneGetForCustomer(customerId: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Geozone>>;
    public geozoneGetForCustomer(customerId: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Geozone>>>;
    public geozoneGetForCustomer(customerId: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Geozone>>>;
    public geozoneGetForCustomer(customerId: string, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling geozoneGetForCustomer.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Geozone>>(`${this.basePath}/api/Geozone/GetForCustomer/${encodeURIComponent(String(customerId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

	/**
     * Returns a list of Geozone objects for given Equipment id mapped on the given record status. If no status is given, all statuses are returned. Result is ordered by geozone description.
     *
     * @param equipmentId
     * @param status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geozoneGetForEquipment(equipmentId: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Geozone>>;
    public geozoneGetForEquipment(equipmentId: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Geozone>>>;
    public geozoneGetForEquipment(equipmentId: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Geozone>>>;
    public geozoneGetForEquipment(equipmentId: string, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (equipmentId === null || equipmentId === undefined) {
            throw new Error('Required parameter equipmentId was null or undefined when calling geozoneGetForEquipment.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Geozone>>(`${this.basePath}/api/Geozone/GetForEquipment/${encodeURIComponent(String(equipmentId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get GeozoneReport object for defined equipments and timespan. Arrival is defined by entering the geozone, Departure by leaving the zone. Duration is defined as the timespan staying in the zone.
     *
     * @param body
     * @param geozoneId
     * @param from
     * @param to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geozoneGetGeozoneReport(body: Array<string>, geozoneId: string, from?: Date, to?: Date, observe?: 'body', reportProgress?: boolean): Observable<GeozoneReport>;
    public geozoneGetGeozoneReport(body: Array<string>, geozoneId: string, from?: Date, to?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeozoneReport>>;
    public geozoneGetGeozoneReport(body: Array<string>, geozoneId: string, from?: Date, to?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeozoneReport>>;
    public geozoneGetGeozoneReport(body: Array<string>, geozoneId: string, from?: Date, to?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling geozoneGetGeozoneReport.');
        }

        if (geozoneId === null || geozoneId === undefined) {
            throw new Error('Required parameter geozoneId was null or undefined when calling geozoneGetGeozoneReport.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from.toISOString());
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to.toISOString());
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/Geozone/GetGeozoneReport/${encodeURIComponent(String(geozoneId))}`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Receive GeozoneHasMailRecipient mail recipients for given geozone id and given status. If status is null, all statuses are returned.
     *
     * @param geozoneId Id of geozone.
     * @param status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geozoneGetMailRecipientsForGeozone(geozoneId: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<GeozoneHasMailRecipient>>;
    public geozoneGetMailRecipientsForGeozone(geozoneId: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GeozoneHasMailRecipient>>>;
    public geozoneGetMailRecipientsForGeozone(geozoneId: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GeozoneHasMailRecipient>>>;
    public geozoneGetMailRecipientsForGeozone(geozoneId: string, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geozoneId === null || geozoneId === undefined) {
            throw new Error('Required parameter geozoneId was null or undefined when calling geozoneGetMailRecipientsForGeozone.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<GeozoneHasMailRecipient>>(`${this.basePath}/api/Geozone/GetMailRecipientsForGeozone/${encodeURIComponent(String(geozoneId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update existing Geozone object.
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geozoneUpdate(body: Geozone, observe?: 'body', reportProgress?: boolean): Observable<Geozone>;
    public geozoneUpdate(body: Geozone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Geozone>>;
    public geozoneUpdate(body: Geozone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Geozone>>;
    public geozoneUpdate(body: Geozone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling geozoneUpdate.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Geozone>(`${this.basePath}/api/Geozone/Update`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates equipment relations of existing Geozone object. Attribute modifiedBy is set from User modifier.
     *
     * @param body
     * @param geozoneId
     * @param modifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geozoneUpdateEquipmentRelationsOfGeozone(body: Array<string>, geozoneId: string, modifier?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public geozoneUpdateEquipmentRelationsOfGeozone(body: Array<string>, geozoneId: string, modifier?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public geozoneUpdateEquipmentRelationsOfGeozone(body: Array<string>, geozoneId: string, modifier?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public geozoneUpdateEquipmentRelationsOfGeozone(body: Array<string>, geozoneId: string, modifier?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling geozoneUpdateEquipmentRelationsOfGeozone.');
        }

        if (geozoneId === null || geozoneId === undefined) {
            throw new Error('Required parameter geozoneId was null or undefined when calling geozoneUpdateEquipmentRelationsOfGeozone.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (modifier !== undefined && modifier !== null) {
            queryParameters = queryParameters.set('modifier', <any>modifier);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<boolean>(`${this.basePath}/api/Geozone/UpdateEquipmentRelationsOfGeozone/${encodeURIComponent(String(geozoneId))}`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update existing GeozoneHasMailRecipient object.
     *
     * @param body To be updated GeozoneHasMailRecipient object.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geozoneUpdateGeozoneMailRecipient(body: GeozoneHasMailRecipient, observe?: 'body', reportProgress?: boolean): Observable<GeozoneHasMailRecipient>;
    public geozoneUpdateGeozoneMailRecipient(body: GeozoneHasMailRecipient, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeozoneHasMailRecipient>>;
    public geozoneUpdateGeozoneMailRecipient(body: GeozoneHasMailRecipient, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeozoneHasMailRecipient>>;
    public geozoneUpdateGeozoneMailRecipient(body: GeozoneHasMailRecipient, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling geozoneUpdateGeozoneMailRecipient.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GeozoneHasMailRecipient>(`${this.basePath}/api/Geozone/UpdateGeozoneMailRecipient`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * @param customerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geozoneGetGeozoneInsideViews(customerId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<GeozoneInsideView>>;
    public geozoneGetGeozoneInsideViews(customerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GeozoneInsideView>>>;
    public geozoneGetGeozoneInsideViews(customerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GeozoneInsideView>>>;
    public geozoneGetGeozoneInsideViews(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling geozoneGetGeozoneInsideViews.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<GeozoneInsideView>>(`${this.basePath}/api/Geozone/GetGeozoneInsideViews/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
