/**
 * MdtDbWebService
 * This is a service for basic communication with database
 *
 * OpenAPI spec version: 1.0.0.0
 * Contact: info@moderndrive.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from './encoder';
import { Observable } from 'rxjs';
import { TripDataPositionLast } from '../model/mdtdb/tripDataPositionLast';
import { Configuration } from './configuration';
import { AppConfigService } from '../app-config.service';
import { SessionHelper } from './sessionhelper';
import { TripDataPosition, TripData } from '../model/mdtdatabase/models';
import { TripReport } from '../view/tripReport';


@Injectable()
export class TripDataService {

  protected basePath = Configuration.getServiceBasePath();
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, private appConfig: AppConfigService) {
      this.basePath = appConfig.getConfig().webservice.url;
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
        if (form === consume) {
            return true;
        }
    }
    return false;
  }

  /**
   *
   * @param body body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public tripDataGetLastPositionOfEquipments(body: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<TripDataPositionLast>>;
  public tripDataGetLastPositionOfEquipments(body: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TripDataPositionLast>>>;
  public tripDataGetLastPositionOfEquipments(body: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TripDataPositionLast>>>;
  public tripDataGetLastPositionOfEquipments(body: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling tripDataGetLastPositionOfEquipments.');
    }

    let headers = SessionHelper.getStandardSessionHeader();

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
        'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
        'application/json'
    ];
    const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<TripDataPositionLast>>(`${this.basePath}/api/TripData/GetLastPositionOfEquipments`,
        body,
        {
            withCredentials: this.configuration.withCredentials,
            headers,
            observe,
            reportProgress
        }
    );
  }

  /**
   * Get the TripData of given Equipment id as an array since given DateTime until given DateTime.
   *
   * @param equipmentId Guid of equipment.
   * @param from A valid minimum datetime (UTC)
   * @param to A valid maximum datetime (UTC)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public tripDataGetTripDataOfEquipment(equipmentId: string, from?: Date, to?: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<TripData>>;
  public tripDataGetTripDataOfEquipment(equipmentId: string, from?: Date, to?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TripData>>>;
  public tripDataGetTripDataOfEquipment(equipmentId: string, from?: Date, to?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TripData>>>;
  public tripDataGetTripDataOfEquipment(equipmentId: string, from?: Date, to?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      if (equipmentId === null || equipmentId === undefined) {
          throw new Error('Required parameter equipmentId was null or undefined when calling tripDataGetTripDataOfEquipment.');
      }

      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
      if (from !== undefined && from !== null) {
          queryParameters = queryParameters.set('from', <any>from.toISOString());
      }
      if (to !== undefined && to !== null) {
          queryParameters = queryParameters.set('to', <any>to.toISOString());
      }

      let headers = SessionHelper.getStandardSessionHeader();

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
          'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.get<Array<TripData>>(`${this.basePath}/api/TripData/GetTripDataOfEquipment/${encodeURIComponent(String(equipmentId))}`,
          {
              params: queryParameters,
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress
          }
      );
  }

   /**
    * Get the TripDataPosition of given array of Equipment ids as an array since given DateTime until given DateTime.
    *
    * @param body Guid of equipment Id
    * @param from A valid minimum datetime (UTC)
    * @param to A valid maximum datetime (UTC)
    * @param gpsFix Identifier to consider gps fix of positions. 0 - all fixes, 1 - only 2D and 3D, 2 - only 3D fixes used. If no fix is given, all fixes are used
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
   public tripDataGetTripDataPositionsOfEquipment(equipmentId: string, from?: Date, to?: Date, gpsFix?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TripDataPosition>>;
   public tripDataGetTripDataPositionsOfEquipment(equipmentId: string, from?: Date, to?: Date, gpsFix?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TripDataPosition>>>;
   public tripDataGetTripDataPositionsOfEquipment(equipmentId: string, from?: Date, to?: Date, gpsFix?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TripDataPosition>>>;
   public tripDataGetTripDataPositionsOfEquipment(equipmentId: string, from?: Date, to?: Date, gpsFix?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (equipmentId === null || equipmentId === undefined) {
      throw new Error('Required parameter equipmentId was null or undefined when calling tripDataGetTripDataOfEquipment.');
  }

       let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
       if (from !== undefined && from !== null) {
           queryParameters = queryParameters.set('from', <any>from.toISOString());
       }
       if (to !== undefined && to !== null) {
           queryParameters = queryParameters.set('to', <any>to.toISOString());
       }
       if (gpsFix !== undefined && gpsFix !== null) {
           queryParameters = queryParameters.set('gpsFix', <any>gpsFix);
       }

       let headers = SessionHelper.getStandardSessionHeader();

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
          'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

       return this.httpClient.get<Array<TripDataPosition>>(`${this.basePath}/api/TripData/GetTripDataPositionsOfEquipment/${encodeURIComponent(String(equipmentId))}`,
          {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
          }
       );
   }

    /**
     *
     * @param body
     * @param from
     * @param to
     * @param aggregate
     * @param descriptor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public tripDataAggregatedData(body: Array<string>, from?: Date, to?: Date, descriptor?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<any>>;
     public tripDataAggregatedData(body: Array<string>, from?: Date, to?: Date, descriptor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<any>>>;
     public tripDataAggregatedData(body: Array<string>, from?: Date, to?: Date, descriptor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<any>>>;
     public tripDataAggregatedData(body: Array<string>, from?: Date, to?: Date, descriptor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (body === null || body === undefined) {
             throw new Error('Required parameter body was null or undefined when calling tripDataAggregatedData.');
         }

         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (from !== undefined && from !== null) {
             queryParameters = queryParameters.set('from', <any>from.toISOString());
         }
         if (to !== undefined && to !== null) {
             queryParameters = queryParameters.set('to', <any>to.toISOString());
         }
         if (descriptor !== undefined && descriptor !== null) {
             queryParameters = queryParameters.set('descriptor', <any>descriptor);
         }

         let headers = SessionHelper.getStandardSessionHeader();

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
         const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }

         return this.httpClient.post<Array<any>>(`${this.basePath}/api/TripData/AggregatedData`,
             body,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

          /**
     *
     * @param body
     * @param from
     * @param to
     * @param descriptor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tripDataSingleData(body: Array<string>, from?: Date, to?: Date, descriptor?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<any>>;
    public tripDataSingleData(body: Array<string>, from?: Date, to?: Date, descriptor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<any>>>;
    public tripDataSingleData(body: Array<string>, from?: Date, to?: Date, descriptor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<any>>>;
    public tripDataSingleData(body: Array<string>, from?: Date, to?: Date, descriptor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling tripDataCounterData.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from.toISOString());
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to.toISOString());
        }
        if (descriptor !== undefined && descriptor !== null) {
            queryParameters = queryParameters.set('descriptor', <any>descriptor);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<any>>(`${this.basePath}/api/TripData/SingleData`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

     /**
      *
      * @param body
      * @param from
      * @param to
      * @param interval
      * @param aggregate
      * @param descriptor
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public tripDataIntervalData(body: Array<string>, from?: Date, to?: Date, timeZoneOffset?: number, descriptor?: string, interval?: string, locale?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<any>>;
     public tripDataIntervalData(body: Array<string>, from?: Date, to?: Date, timeZoneOffset?: number, descriptor?: string, interval?: string, locale?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<any>>>;
     public tripDataIntervalData(body: Array<string>, from?: Date, to?: Date, timeZoneOffset?: number, descriptor?: string, interval?: string, locale?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<any>>>;
     public tripDataIntervalData(body: Array<string>, from?: Date, to?: Date, timeZoneOffset?: number, descriptor?: string, interval?: string, locale?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (body === null || body === undefined) {
             throw new Error('Required parameter body was null or undefined when calling tripDataIntervalData.');
         }

         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (from !== undefined && from !== null) {
             queryParameters = queryParameters.set('from', <any>from.toISOString());
         }
         if (to !== undefined && to !== null) {
             queryParameters = queryParameters.set('to', <any>to.toISOString());
         }
         if (timeZoneOffset !== undefined && timeZoneOffset !== null) {
            queryParameters = queryParameters.set('timeZoneOffset', <any>timeZoneOffset);
        }
         if (descriptor !== undefined && descriptor !== null) {
            queryParameters = queryParameters.set('descriptor', <any>descriptor);
         }
         if (interval !== undefined && interval !== null) {
             queryParameters = queryParameters.set('interval', <any>interval);
         }
         if (locale !== undefined && locale !== null) {
             queryParameters = queryParameters.set('locale', <any>locale);
         }

         let headers = SessionHelper.getStandardSessionHeader();

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
         const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }

         return this.httpClient.post<Array<any>>(`${this.basePath}/api/TripData/IntervalData`,
             body,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
     *
     * @param equipmentId
     * @param from
     * @param to
     * @param locale
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tripDataSingleVehicleWorkingEvaluation(equipmentId: string, from?: Date, to?: Date, timeZoneOffset?: number, locale?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<any>>;
    public tripDataSingleVehicleWorkingEvaluation(equipmentId: string, from?: Date, to?: Date, timeZoneOffset?: number, locale?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<any>>>;
    public tripDataSingleVehicleWorkingEvaluation(equipmentId: string, from?: Date, to?: Date, timeZoneOffset?: number, locale?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<any>>>;
    public tripDataSingleVehicleWorkingEvaluation(equipmentId: string, from?: Date, to?: Date, timeZoneOffset?: number, locale?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (equipmentId === null || equipmentId === undefined) {
            throw new Error('Required parameter equipmentId was null or undefined when calling tripDataSingleVehicleWorkingEvaluation.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from.toISOString());
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to.toISOString());
        }
        if (timeZoneOffset !== undefined && timeZoneOffset !== null) {
          queryParameters = queryParameters.set('timeZoneOffset', <any>timeZoneOffset);
        }
        if (locale !== undefined && locale !== null) {
            queryParameters = queryParameters.set('locale', <any>locale);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<any>>(`${this.basePath}/api/TripData/SingleVehicleWorkingEvaluation/${encodeURIComponent(String(equipmentId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
      }

    /**
     *
     * @param equipmentId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tripDataTestMethod(equipmentId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<any>>;
    public tripDataTestMethod(equipmentId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<any>>>;
    public tripDataTestMethod(equipmentId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<any>>>;
    public tripDataTestMethod(equipmentId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (equipmentId === null || equipmentId === undefined) {
            throw new Error('Required parameter equipmentId was null or undefined when calling tripDataTestMethod.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<any>>(`${this.basePath}/api/TripData/TestMethod/${encodeURIComponent(String(equipmentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * @param equipmentId
     * @param from
     * @param to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public tripDataTripReport(equipmentId: string, from?: Date, to?: Date, observe?: 'body', reportProgress?: boolean): Observable<TripReport>;
     public tripDataTripReport(equipmentId: string, from?: Date, to?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TripReport>>;
     public tripDataTripReport(equipmentId: string, from?: Date, to?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TripReport>>;
     public tripDataTripReport(equipmentId: string, from?: Date, to?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (equipmentId === null || equipmentId === undefined) {
             throw new Error('Required parameter equipmentId was null or undefined when calling tripDataTripReport.');
         }

         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (from !== undefined && from !== null) {
             queryParameters = queryParameters.set('from', <any>from.toISOString());
         }
         if (to !== undefined && to !== null) {
             queryParameters = queryParameters.set('to', <any>to.toISOString());
         }

         let headers = SessionHelper.getStandardSessionHeader();

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.get<TripReport>(`${this.basePath}/api/TripData/TripReport/${encodeURIComponent(String(equipmentId))}`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
}
