<h2 mat-dialog-title>{{geozone.description}} - {{globals.languageTable_res.get(2054)}}</h2>

<mat-dialog-content class="dialog-content">
  <div>
    <app-datetime-selection #dateTimeSelection>
    </app-datetime-selection>
  </div>
  <div>
    <div class="operation-buttons-container">
      <button button class="dialog-controll-button-small" (click)="openEquipmentSelection()" title="{{globals.languageTable_res.get(2064)}}">
        <mat-icon class="primary-icon clickable">commute</mat-icon>
      </button>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button class="dialog-controll-button"(click)="cancel()">{{globals.languageTable_res.get(2018)}}</button>
  <button class="dialog-controll-button"(click)="execute()">{{globals.languageTable_res.get(2041)}}</button>
</mat-dialog-actions>
