import { Injectable } from '@angular/core';
import { ApplicationTextService } from '../generated-services/api';
import { ApplicationText } from '../model/mdtdb/applicationText';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import { Globals } from '../globals';
import { Definitions } from '../definitions';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public static SELECTED_LANG_DE = 'de_DE';
  public static SELECTED_LANG_EN = 'en_GB';

  private _languageTable_id = new BehaviorSubject<Map<number, string>>(null);
  private _languageTable_key = new BehaviorSubject<Map<string, string>>(null);

  constructor(public globals: Globals, private _applicationTextService: ApplicationTextService) {}

  public initializeLanguageTables(language: string): Observable<boolean> {
    var res = new Subject<boolean>();
    res.next(false);
    // initialize language table
    this._applicationTextService.applicationTextGetAll().subscribe(text => {
      const arr = text as ApplicationText[];
      const map_id: Map<number, string> = new Map();
      const map_key: Map<string, string> = new Map();

      for (const a of arr) {
        // TODO : consider language identifier for user
        // default value for test purpose set to german
        language === LanguageService.SELECTED_LANG_DE ? map_id.set(a.id, a.textValueDe) : map_id.set(a.id, a.textValueEn);
        language === LanguageService.SELECTED_LANG_DE ? map_key.set(a.keyword, a.textValueDe) : map_key.set(a.keyword, a.textValueEn);
      }

      this._languageTable_id.next(map_id);
      this._languageTable_key.next(map_key);

      this.globals.languageTable_res = this._languageTable_id.getValue();
      this.globals.languageTableInitialized.next(true);

      res.next(true);
    });

    return res.asObservable();
  }

  public isMobile() {
    // TODO create behaviour subject on login and check if device is mobile
    return false;
  }

  // getters
  public getLocaleDate(utc: Date) {
    if (utc) {
      var date = moment.utc(utc).local().format('DD.MM.YYYY');

      return date;
    }
  }

  public getLocaleDateTime(utc: Date) {
    if (utc) {
      var date = moment.utc(utc).local().format('DD.MM.YYYY - HH:mm:ss');

      return date;
    }
  }

  public dateToString(date: Date) {
    if (date) {
      return moment(date).format('DD.MM.YYYY - HH:mm:ss');
    }
  }

  public compareDates(first: Date, second: Date) {
    var result;

    var x = moment.utc(first).local();
    var y = moment.utc(second).local();

    if (x.isBefore(y)) {
      result = 0;
    }
    if (x.isAfter(y)) {
      result = 1;
    }
    if (x.isSame(y)) {
      result = 2;
    }

    return result;
  }

  public getLocaleTime(utc: Date) {
    if (utc) {
      return new Date(utc).toLocaleTimeString();
    }
  }

  public getTranslation(id: number): string {
    let result = id.toString();

    if (this._languageTable_id && this._languageTable_id.getValue() && this._languageTable_id.getValue().get(id)) {
      if (this.getSelectedLang() === LanguageService.SELECTED_LANG_DE) return this._languageTable_id.getValue().get(id);
      if (this.getSelectedLang() === LanguageService.SELECTED_LANG_EN) return this._languageTable_id.getValue().get(id);
    }

    return result;
  }

  public getTranslationFromKeyword(keyword: string): string {
    let result = keyword;

    if (this._languageTable_key && this._languageTable_key.getValue() && this._languageTable_key.getValue().get(keyword)) {
      if (this.getSelectedLang() === LanguageService.SELECTED_LANG_DE) return this._languageTable_key.getValue().get(keyword);
      if (this.getSelectedLang() === LanguageService.SELECTED_LANG_EN) return this._languageTable_key.getValue().get(keyword);
    }

    return result;
  }

  public convertToTime(seconds: number): string {
    if (seconds) {
      var date = new Date(null);
       date.setSeconds(seconds);
       return date.toISOString().substr(11, 8);
    }
  }

  public round(value: number, digits: number): number {
    if (value || value === 0) {
      if (digits) return Math.round((value + Number.EPSILON) * Math.pow(10, digits)) / Math.pow(10, digits);
      else return Math.round(value);
    }
  }

  public numberWithCommas(x) {
    if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  public get basicMinDate(): Date {
    return new Date("2017-01-01");
  }

  public get basicMaxDate(): Date {
    return new Date();
  }

  getSelectedLang() {
    var lang = sessionStorage.getItem(Definitions.SELECTED_LANG);

    if (!lang) lang = LanguageService.SELECTED_LANG_DE;

    return lang;
  }

  setSelectedLang(value: string) {
    if (value) sessionStorage.setItem(Definitions.SELECTED_LANG, value);
  }
}
