<div class="background-container">
  <div class="container">
    <div class="row">
      <div class="col s6 offset-s4" style="margin-left: auto; margin-right: auto; min-width: 600px">
        <div class="card grey lighten-2">
          <div class="card-tabs">
            <ul class="tabs tabs-fixed-width tabs-transparent">
              <li class="tab">
                <a class="unclickable">Passwort vergessen?</a>
              </li>
            </ul>
          </div>
          <div class="card-content white">
            <div class="row">
              Sollten Sie Ihr Passwort vergessen haben, benötigen Sie einen Aktivierungscode, um dieses neu anzulegen.
              Hier können Sie den Code anfordern. Dieser wird an Ihre hinterlegte E-Mail Adresse gesendet.
              <br>
              <form #loginForm="ngForm" class="col s10" (ngSubmit)="sendCode(UserName.value)">
                <div class="row">
                  <div class="input-field col s10 offset-s1" style="text-align:center">
                    <i class="material-icons prefix">account_circle</i>
                    <input type="text" #UserName ngModel name="UserName" placeholder="Username" required>
                  </div>
                </div>
                <div class="row">
                  <div class="input-field col s10 offset-s2">
                    <button [disabled]="!loginForm.valid" class="btn-large btn-submit" type="submit">Aktivierungscode senden</button>
                  </div>
                </div>
                <div class="row">
                  <div class="input-field col s10 offset-s2">
                    <a routerLink='/password-recovery' routerLinkActive='active'>
                      <button class="btn-large btn-submit">Passwort zurücksetzen</button>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
