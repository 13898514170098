<h2 mat-dialog-title>Version {{currentVersion}}</h2>

<mat-dialog-content class="flex-container">
  <h2>Änderungen in der Flottenansicht:</h2>
  <div class="image"></div>
  <br>
  <h2>Überarbeitung der Routenverfolgung:</h2>
  <h3>- Auto-Load des heutigen Tages</h3>
  <h3>- Zusätzliche Event-Tabelle</h3>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button class="dialog-controll-button" (click)="cancel()">{{globals.languageTable_res.get(2035)}}</button>
</mat-dialog-actions>
