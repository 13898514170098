import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { MatDialog } from '@angular/material';
import { LanguageService, NavigationService } from 'src/app/service/api';
import {} from 'googlemaps';
import { BehaviorSubject } from 'rxjs';
import { Definitions } from 'src/app/definitions';
import { GoogleMapComponent } from '../google-map/google-map.component';
import { Globals } from 'src/app/globals';
import { LoadingSpinnerComponent } from '../../filter/loading-spinner/loading-spinner.component';


@Component({
  selector: 'app-tracking-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.css'],
})
export class FleetComponent implements OnInit, OnDestroy  {

  @ViewChild('mapComponent', {static: false}) mapComponent: GoogleMapComponent;
  // auto refresh data after 120 seconds
  // refreshTime = 120;
  refreshTime = 120;
  refreshData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  refreshDataBool: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  mapReadyState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  mapType = Definitions.MAP_TYPE_ROADMAP;
  markerCluster: any;
  searchBox = true;
  drawingManager = false;

  public polyLines: google.maps.Polyline[] = new Array();
  public mapMarkers: google.maps.Marker[] = new Array();

  constructor(private _authService: AuthService,
              public globals: Globals,
              private _languageService: LanguageService,
              private _loadingSpinnerDialog: MatDialog,
              private _navigationService: NavigationService) {
                // create refresh interval for auto data refreshing
                this.refreshDataBool.subscribe((refresh: boolean) => {
                  if (refresh) {
                    this.refresh();
                  }
                })
              }

  async ngOnInit() {
    this.refresh();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }

  /* Method call when component gets destroyed */
  ngOnDestroy() {
    // kill refresh action at route change
    this.refreshDataBool.unsubscribe();
  }

  /*
    Refresh session data and positions for selected equipments.
  */
  private async refresh() {
    this.dataLoaded.next(false);
    this.refreshData.next(false);
    if (await this._authService.sessionValidation()) {
      this.refreshData.next(true);
      this._loadingSpinnerDialog.open(LoadingSpinnerComponent, {
        data: {
          diameter: 57,
          loading: this.dataLoaded
        }
      });
    }
  }

  public get languageService(): LanguageService {
    return this._languageService;
  }

  public get navigationService(): NavigationService {
    return this._navigationService;
  }
}
