/**
 * MdtDbWebService
 * This is a service for basic communication with database
 *
 * OpenAPI spec version: 1.0.0.0
 * Contact: info@moderndrive.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from './encoder';
import { Observable } from 'rxjs';
import { User } from '../model/mdtdb/user';
import { BASE_PATH, COLLECTION_FORMATS } from './variables';
import { Configuration } from './configuration';
import { AppConfigService } from '../app-config.service';
import { SessionHelper } from './sessionhelper';
import { UserPasswordRequest } from '../model/mdtdb/userPasswordRequest';


@Injectable()
export class UserService {

    protected basePath = Configuration.getServiceBasePath();
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, private appConfig : AppConfigService) {
        this.basePath = appConfig.getConfig().webservice.url;
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    /**
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public userGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public userGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public userGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      if (id === null || id === undefined) {
          throw new Error('Required parameter id was null or undefined when calling userGet.');
      }

      let headers = SessionHelper.getStandardSessionHeader();

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
          'application/octet-stream'
      ];
      const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected !== undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.get<Blob>(`${this.basePath}/api/User/${encodeURIComponent(String(id))}`,
          {
              withCredentials: this.configuration.withCredentials,
              headers,
              observe,
              reportProgress
          }
      );
    }

    /**
     *
     * @param customerId customerId
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userGetUsersForCustomer(customerId: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public userGetUsersForCustomer(customerId: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public userGetUsersForCustomer(customerId: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public userGetUsersForCustomer(customerId: string, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling userGetUsersForCustomer.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', status);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<User>>(`${this.basePath}/api/User/GetUsersForCustomer/${encodeURIComponent(String(customerId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * For this permission selected Customer has to obtain module tracking and user has to obtain permission Administrator or (Worker and Fleetmanager)
     *
     * @param customerId
     * @param userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public userPermissionForWebportalV4(customerId: string, userId: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
     public userPermissionForWebportalV4(customerId: string, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
     public userPermissionForWebportalV4(customerId: string, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
     public userPermissionForWebportalV4(customerId: string, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (customerId === null || customerId === undefined) {
             throw new Error('Required parameter customerId was null or undefined when calling userPermissionForWebportalV4.');
         }

         if (userId === null || userId === undefined) {
             throw new Error('Required parameter userId was null or undefined when calling userPermissionForWebportalV4.');
         }

         let headers = SessionHelper.getStandardSessionHeader();

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.get<boolean>(`${this.basePath}/api/User/PermissionForWebportalV4/${encodeURIComponent(String(customerId))}/${encodeURIComponent(String(userId))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
    }

         /**
   *
   * @param body body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public userSignIn(body: User, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public userSignIn(body: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public userSignIn(body: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public userSignIn(body: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling userSignIn.');
    }

    const authorizationData = 'Basic ' + btoa(body.username + ':' + body.password);
    let headers = new HttpHeaders({ Authorization: authorizationData });

    // headers.append('Access-Control-Allow-Origin',  Configuration.GetStandardBasePath());
    // headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    // headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token, Authorization');

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/octet-stream'
    ];

    headers = headers.set('Accept', httpHeaderAccepts);

    const consumes: string[] = [
      'application/json'
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.post<any>(`${this.basePath}/api/User/SignIn`,
        body,
        {
            withCredentials: false,
            headers,
            observe,
            reportProgress
        }
    );
  }

      /**
     * Send passwort recovery activation code for username.
     *
     * @param username
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
       public userSendPasswordActivationCode(username?: string, observe?: 'body', reportProgress?: boolean): Observable<UserPasswordRequest>;
       public userSendPasswordActivationCode(username?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPasswordRequest>>;
       public userSendPasswordActivationCode(username?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPasswordRequest>>;
       public userSendPasswordActivationCode(username?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

           let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
           if (username !== undefined && username !== null) {
               queryParameters = queryParameters.set('username', <any>username);
           }

           let headers = new HttpHeaders();

           // to determine the Accept header
           let httpHeaderAccepts: string[] = [
               'application/json'
           ];
           const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
           if (httpHeaderAcceptSelected != undefined) {
               headers = headers.set('Accept', httpHeaderAcceptSelected);
           }

           // to determine the Content-Type header
           const consumes: string[] = [
           ];

           return this.httpClient.get<UserPasswordRequest>(`${this.basePath}/api/User/SendPasswordActivationCode`,
               {
                   params: queryParameters,
                   withCredentials: this.configuration.withCredentials,
                   headers: headers,
                   observe: observe,
                   reportProgress: reportProgress
               }
           );
       }

       /**
     * Send passwort recovery activation code for username.
     *
     * @param username
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public userResetPassword(activationCode?: string, password?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
        public userResetPassword(activationCode?: string, password?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
        public userResetPassword(activationCode?: string, password?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
        public userResetPassword(activationCode?: string, password?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
            if (activationCode !== undefined && activationCode !== null) {
                queryParameters = queryParameters.set('activationCode', <any>activationCode);
            }
            if (password !== undefined && password !== null) {
              queryParameters = queryParameters.set('password', <any>password);
            }

            let headers = new HttpHeaders();

            // to determine the Accept header
            let httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
            if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }

            // to determine the Content-Type header
            const consumes: string[] = [
            ];

            return this.httpClient.get<boolean>(`${this.basePath}/api/User/ResetPassword`,
                {
                    params: queryParameters,
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            );
        }
}
