<h2 mat-dialog-title>{{globals.languageTable_res.get(2049)}}</h2>

<mat-dialog-content [formGroup]="geozoneFg" class="dialog-content">
  <div>
    <input formControlName="description" matInput placeholder="{{globals.languageTable_res.get(2010)}}" required="true" minlength="2">
  </div>
  <div class="scrollable">
    <div class="map-container">
      <div class="google-map">
        <app-google-map #mapComponent [searchBox]="searchBox" [drawingManager]="drawingManager" [mapType]="mapType" [mapReadyState]="mapReadyState"></app-google-map>
      </div>
      <br>
      <ng-container *ngIf="positionsPermission">
        <div class="routing-selection">
          <button button class="dialog-controll-button width-fit-content dark" (click)="openRoutingDialog()" title="{{globals.languageTable_res.get((2008))}}">
            {{globals.languageTable_res.get((2008))}}
          </button>
          <mat-select placeholder="{{globals.languageTable_res.get(2059)}}" class="standard-selection"
              (selectionChange)="selectRouteEquipment($event.value)">
            <mat-option *ngFor="let equipment of equipmentsOfCustomer" [value]="equipment">
              <span class="select-item">{{equipment.licencePlate}}</span>
            </mat-option>
          </mat-select>
        </div>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button class="dialog-controll-button" (click)="cancel()">{{globals.languageTable_res.get(2018)}}</button>
  <button class="dialog-controll-button" [disabled]="geozoneFg.get('description').invalid" (click)="save()">{{globals.languageTable_res.get(2019)}}</button>
</mat-dialog-actions>
