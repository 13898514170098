import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatPaginator, MatSelect, MatTableDataSource } from '@angular/material';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { Status } from 'src/app/enumeration/status';
import { EquipmentService } from 'src/app/generated-services/equipment.service';
import { TripDataService } from 'src/app/generated-services/tripData.service';
import { Equipment } from 'src/app/model/mdtdb/equipment';
import { AuthService } from 'src/app/service/auth.service';
import { ValidationService } from 'src/app/service/validation.service';
import * as $ from 'jquery';
import { Globals } from 'src/app/globals';
import { LanguageService } from 'src/app/service/language.service';
import { LoadingSpinnerComponent } from '../../filter/loading-spinner/loading-spinner.component';
import { InfoService } from 'src/app/service/info.service';
import { TripReport } from 'src/app/view/tripReport';

@Component({
  selector: 'app-equipment-report',
  templateUrl: './equipment-report.component.html',
  styleUrls: ['./equipment-report.component.css']
})
export class EquipmentReportComponent implements OnInit {
  // table
  private _dataSource = new MatTableDataSource<any>();
  private _displayedColumns = ['begin', 'end', 'duration', 'distance', 'startAddress', 'endAddress', 'standStill', 'standing'];
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  toDate: Date = new Date();
  fromDate: Date = new Date();

  public report: TripReport;

  public reports: number[] = new Array();

  /** list of equipments */
  protected _equipments: Equipment[] = new Array();

  /** control for the selected report */
  public reportCtrl: FormControl = new FormControl();
  /** control for the selected equipment */
  public equipCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public equipFilterCtrl: FormControl = new FormControl();

  /** list of banks filtered by search keyword */
  public filteredEquips: ReplaySubject<Equipment[]> = new ReplaySubject<Equipment[]>(1);

  @ViewChild('reportSelect', {static: false}) reportSelect: MatSelect;
  @ViewChild('equipSelect', {static: false}) equipSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(private _authService: AuthService,
              private cdr: ChangeDetectorRef,
              private _equipmentService: EquipmentService,
              public globals: Globals,
              private _infoService: InfoService,
              private _languageService: LanguageService,
              private _loadingSpinnerDialog: MatDialog,
              private _tripDataService: TripDataService,
              private _validationService: ValidationService) { }

  ngOnInit() {
    this.reports.push(2138);
    this.fromDate.setDate(this.toDate.getDate() -1);
    this.loadEquips();
  }

  ngAfterViewInit() {
    this.setInitialValue();
    this.dataSource.paginator = this.paginator;
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public createReport() {
    this._dataSource.data = new Array();
    this.report = null;

    if (this.equipCtrl.value) {
      // show loading dialog
      const dialogRef = this._loadingSpinnerDialog.open(LoadingSpinnerComponent, {
        data: {
          diameter: 57,
          loading: this.dataLoaded
        }
      });

      dialogRef.afterClosed().subscribe(x => {
        this.dataLoaded.next(false);
      });

      var selectedEquipment = this._equipments.find(x => x.licencePlate === this.equipCtrl.value.licencePlate);

      this._tripDataService.tripDataTripReport(selectedEquipment.id, this.getFromDate(), this.getToDate()).toPromise().then((result: TripReport) => {
        this.dataLoaded.next(true);

        if (result) {
          this.report = result;
          this._dataSource.data = result.trips;
        } else {
          this._infoService.showInfoWindow(Globals.TYPE_INFO, this._languageService.getTranslation(2042));
        }
      }).catch((err: HttpErrorResponse) => {
        this.dataLoaded.next(true);
        this._validationService.validateHttpErrorResponse(err);
      });
    } else {
      this._infoService.showInfoWindow(Globals.TYPE_INFO, this._languageService.getTranslation(2136));
    }
  }

  protected loadEquips() {
    this._equipmentService.equipmentGetEquipmentsForCustomer(this._authService.selectedCustomer.id, Status.ACTIVE).toPromise().then((result: Equipment[]) => {
      this._equipments = result;

      if (result && result.length) {
        // set initial selection
        this.equipCtrl.setValue(null);

        // load the initial equipment list
        this.filteredEquips.next(this._equipments.slice());

        // listen for search field value changes
        this.equipFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterEquipments();
          });
      }
    }).catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
    });
  }

  public getFromDate(): Date {
    return new Date($('#fromDate').val());
  }

  public getToDate(): Date {
    var toDate = new Date($('#toDate').val());
    toDate.setHours(23);
    toDate.setMinutes(59);
    toDate.setSeconds(59);

    return toDate;
  }

  public get basicFromDate(): string {
    return this.dateToString(this.fromDate);
  }

  public get basicToDate(): string {
    return this.dateToString(this.toDate);
  }

  public dateToString(date: Date): string {
    return date.toISOString().slice(0,10);
  }

  /**
   * Sets the initial value after the filteredEquips are loaded initially
   */
  protected setInitialValue() {
    this.filteredEquips
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredEquips are loaded initially
        // and after the mat-option elements are available
        if (this.equipSelect) this.equipSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }

  protected filterEquipments() {
    if (!this._equipments) {
      return;
    }
    // get the search keyword
    let search = this.equipFilterCtrl.value;
    if (!search) {
      this.filteredEquips.next(this._equipments.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the equipments
    this.filteredEquips.next(
      this._equipments.filter(e => e.licencePlate.toLowerCase().indexOf(search) > -1)
    );
  }

  // getters
  public get reportDescription() {
    if (this.equipCtrl && this.equipCtrl.value) {
      return this.equipCtrl.value.licencePlate + ", " + this.getFromDate().toLocaleDateString() + " - " + this.getToDate().toLocaleDateString();
    }
  }

  public get selectedEquipment() {
    if (this.equipCtrl) return this.equipCtrl.value;
  }

  public get selectedReport() {
    if (this.reportCtrl) return this.reportCtrl.value;
  }

  public get sumDistance() {
    var result = 0;

    if (this.report) {
      result = this.report.sumDistance;
    }

    return result;
  }

  public get sumDuration() {
    var result = '00:00:00';

    if (this.report) {
      result = this.report.sumDuration;
    }

    return result;
  }

  public get sumStandStill() {
    var result = '00:00:00';

    if (this.report) {
      result = this.report.sumStandStill;
    }

    return result;
  }

  public get dataSource(): MatTableDataSource<any> {
    return this._dataSource;
  }

  public get displayedColumns(): string[] {
    return this._displayedColumns;
  }

  public get languageService(): LanguageService {
    return this._languageService;
  }
}
