import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app-config.service';
import { TachographData } from '../model/mdtdata/tachographData';
import { Configuration } from './configuration';
import { CustomHttpUrlEncodingCodec } from './encoder';
import { SessionHelper } from './sessionhelper';

@Injectable({
  providedIn: 'root'
})
export class TachographService {

  protected basePath = Configuration.getServiceBasePath();
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, private appConfig: AppConfigService) {
      this.basePath = appConfig.getConfig().webservice.url;
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
      const form = 'multipart/form-data';
      for (const consume of consumes) {
          if (form === consume) {
              return true;
          }
      }
      return false;
  }

  /**
     * Get the most latest TachographData for an equipment ids.
     *
     * @param equipmentId A valid equipment id
     * @param dateTime If not empty/null, the date/time of the most latest data, before this date/time
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
   public tachographGetTachographData(equipmentId: string, dateTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<TachographData>;
   public tachographGetTachographData(equipmentId: string, dateTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TachographData>>;
   public tachographGetTachographData(equipmentId: string, dateTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TachographData>>;
   public tachographGetTachographData(equipmentId: string, dateTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

       if (equipmentId === null || equipmentId === undefined) {
           throw new Error('Required parameter equipmentId was null or undefined when calling tachographGetTachographData.');
       }


       let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
       if (dateTime !== undefined && dateTime !== null) {
           queryParameters = queryParameters.set('dateTime', <any>dateTime.toISOString());
       }

       let headers = SessionHelper.getStandardSessionHeader();

       // to determine the Accept header
       let httpHeaderAccepts: string[] = [
           'application/json'
       ];
       const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
       if (httpHeaderAcceptSelected != undefined) {
           headers = headers.set('Accept', httpHeaderAcceptSelected);
       }

       // to determine the Content-Type header
       const consumes: string[] = [
       ];

       return this.httpClient.get<TachographData>(`${this.basePath}/api/Tachograph/GetTachographData/${encodeURIComponent(String(equipmentId))}`,
           {
               params: queryParameters,
               withCredentials: this.configuration.withCredentials,
               headers: headers,
               observe: observe,
               reportProgress: reportProgress
           }
       );
   }

   /**
     * Get a list of TachographData for a single equipment between a date/time range.
     *
     * @param equipmentId A valid equipment id
     * @param dateTime The date/time of the most latest data, before this date/time
     * @param untilDateTime The date/time of the oldest data, after this date/time
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tachographGetTachographDatas(equipmentId: string, dateTime?: Date, untilDateTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<TachographData>>;
    public tachographGetTachographDatas(equipmentId: string, dateTime?: Date, untilDateTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TachographData>>>;
    public tachographGetTachographDatas(equipmentId: string, dateTime?: Date, untilDateTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TachographData>>>;
    public tachographGetTachographDatas(equipmentId: string, dateTime?: Date, untilDateTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (equipmentId === null || equipmentId === undefined) {
            throw new Error('Required parameter equipmentId was null or undefined when calling tachographGetTachographDatas.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dateTime !== undefined && dateTime !== null) {
            queryParameters = queryParameters.set('dateTime', <any>dateTime.toISOString());
        }
        if (untilDateTime !== undefined && untilDateTime !== null) {
            queryParameters = queryParameters.set('untilDateTime', <any>untilDateTime.toISOString());
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TachographData>>(`${this.basePath}/api/Tachograph/GetTachographDatas/${encodeURIComponent(String(equipmentId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
