import { Injectable } from '@angular/core';
import { InfoService } from './info.service';
import { AuthService } from './api';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Definitions } from '../definitions';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  public static VALIDATION_PATTERN_EMAIL = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  public static VALIDATION_PATTERN_PASSWORD = "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{8,}";

  constructor(private _authService: AuthService,
              public globals: Globals,
              private _infoService: InfoService) { }

  /* Returns true if drawing is valid, else false.
   * Drawing needs to contain more than 2 points,
   * last point has to be equal to first point to get a closed shape.
   */
  public geozoneDrawingValidation(coordinates: any[]): boolean {
    // check if zone contains more than 2 points
    if (!coordinates || coordinates.length < 3 || coordinates[0] === coordinates[coordinates.length - 1]) {
      this._infoService.showInfoWindow(Globals.TYPE_ERROR, this.globals.languageTable_res.get(2050));
      return false;
    }

    return true;
  }

  /* Returns true if input is valid, return false if input already exists */
  public inputAlreadyExistsValidation(input: string, values: string[], old: string): boolean {
    if (values.filter(x => x === input).length && input != old) {
      this._infoService.showInfoWindow(Globals.TYPE_ERROR, this.globals.languageTable_res.get(2062));
      return false;
    }

    return true;
  }

  /* Returns true if input is valid, return false if input is empty or contains more characters than allowed */
  public inputLengthValidation(input: string, length: number): boolean {
    if (!input || input.length > 128) {
      this._infoService.showInfoWindow(Globals.TYPE_ERROR, this.globals.languageTable_res.get(2051));
      return false;;
    }

    return true;
  }

  public validateHttpErrorResponse(response: HttpErrorResponse) {
    if (response) {
      if (response.status === Definitions.HTTP_RESPONSE_401) {
          this._infoService.showSessionExpired();
          this._authService.logout();
      } else if (response.status) {
        console.log(response.status);
        console.log(response.statusText);
        this._infoService.showInfoWindow(Globals.TYPE_ERROR, this.globals.languageTable_res.get(Globals.ID_ERROR) + ' - ' + response.statusText);
      }
    }
  }

  public evaluateResponseStatus(response: HttpResponse<any>): boolean {
    let result = false;
    if (response) {
      if (response.status === Definitions.HTTP_RESPONSE_200) {
        result = true;
      } else {
        console.log(response.status);
        this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_ERROR);
      }
    }

    return result;
  }
}
