import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { InfoDialogComponent } from '../component/dialogs/general/info-dialog/info-dialog.component';
import { HttpResponse } from '@angular/common/http';
import { Definitions } from '../definitions';
import { Globals } from '../globals';


@Injectable({
  providedIn: 'root'
})
export class InfoService {
  // define time span before info pops up
  protected WAIT_BEFORE = 800;

  constructor(public globals: Globals,
              private infoDialog: MatDialog) { }

  /** Print error message to screen if response code is not 200OK */
  public evaluateErrorResponse(response: HttpResponse<any>) {
    if (response && (response.status != Definitions.HTTP_RESPONSE_200)) {
      this.showInfoWindowWithStandardMessage(Globals.TYPE_ERROR);
    }
  }

  private waitUntil(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  public showInfoWindowWithStandardMessage(type: string) {
    let message: number;

    if (type === Globals.TYPE_SUCCESS) {
      message = Globals.ID_SUCCESS;
    } else if (type === Globals.TYPE_ERROR) {
      message = Globals.ID_ERROR;
    } else {
      message = Globals.ID_INFO;
    }

    this.showInfoWindow(type, this.globals.languageTable_res.get(message));
  }

  public showInfoWindowFromHttpResponse(response: HttpResponse<any>) {
    if (response) {
      let message: number;
      let type: string;

      if (response.status === Definitions.HTTP_RESPONSE_200) {
        message = Globals.ID_SUCCESS;
        type = Globals.TYPE_SUCCESS;
      } else {
        message = Globals.ID_ERROR;
        type = Globals.TYPE_ERROR;
      }

      this.showInfoWindow(type, this.globals.languageTable_res.get(message));
    }
  }

  async showInfoWindow(type: string, message: string) {
    await this.waitUntil(this.WAIT_BEFORE);

    const dialogRef = this.infoDialog.open(InfoDialogComponent, {
      data: {
        message,
        type
      },
      hasBackdrop: false,
      // set style class from global style.css
      panelClass: 'info-dialog-' + type
    });
  }

  public showSessionExpired() {
    // print message no data available
    this.showInfoWindow(Globals.TYPE_INFO, this.globals.languageTable_res.get(2030));
  }
}
