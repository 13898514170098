import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SessionInfo, Customer } from 'src/app/model/mdtdb/models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/service/auth.service';
import { LanguageService } from 'src/app/service/language.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-customer-selection',
  templateUrl: './customer-selection.component.html',
  styleUrls: ['./customer-selection.component.css']
})
export class CustomerSelectionComponent implements OnInit {
  customerSelectionFg: FormGroup;
  private _customers: Customer[];
  selectedLang: string = LanguageService.SELECTED_LANG_DE;

  constructor(private _authService: AuthService,
              private _dialogRef: MatDialogRef<CustomerSelectionComponent>,
              private _languageService: LanguageService,
              public globals: Globals,
              @Inject(MAT_DIALOG_DATA) private _data: any) {
                this._customers = _data.customers;
              }

  ngOnInit() {
    this.customerSelectionFg = new FormGroup({
      groupName: new FormControl('')
    });

    // this.languageService.initializeLanguageTable();
  }

  public chooseLanguage(value: string) {
    this._languageService.setSelectedLang(value);
  }

  public close() {
    this.customerSelectionFg.reset();
    this._dialogRef.close();
  }

  public doCustomerLogin(customer: Customer) {
    if (customer) {
      const c: Customer = {
        id: customer.id
      };

      this._languageService.initializeLanguageTables(this._languageService.getSelectedLang());
      this.close();
      this._authService.customerLogin(customer, this._authService.session.sessionId);
    }
  }

  // getters
  public get customers(): Customer[] {
    return this._customers;
  }
}
