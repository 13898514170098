<div class="background-container">
  <div class="container">
    <div class="row">
      <div class="col s5 offset-s2" style="margin-left: auto; margin-right: auto; min-width: 560px">
        <div class="card white lighten-2">
          <div style="width: 100%; height: 20px;">

          </div>
          <div class="card-content white">
            <div class="row">
              <div class="input-field col s4 offset-s1">
                <div class="logo">

                </div>
              </div>
            </div>
            <div class="row">
              <div *ngIf="isLoginError" class="red-text center error-message">
                <i class="material-icons">error</i> Incorrect username or password
              </div>
              <form #loginForm="ngForm" class="col s10" (ngSubmit)="login(UserName.value, Password.value)">
                <div class="row">
                  <div class="input-field col s10 offset-s1" style="text-align:center">
                    <i class="material-icons prefix">account_circle</i>
                    <input type="text" #UserName ngModel name="UserName" placeholder="Username" required>
                  </div>
                  <div class="input-field col s10 offset-s1">
                    <i class="material-icons prefix">vpn_key</i>
                    <input type="password" #Password ngModel name="Password" placeholder="Password" required>
                  </div>
                </div>
                <!-- <div style="color: red">
                !! Update: Version A 3.0
                <br>
                Update-Beschreibung via Klick auf die Versionsnummer in der Top-Navigation
                </div> -->
                <div class="row">
                  <div class="input-field col s10 offset-s2">
                    <button [disabled]="!loginForm.valid" class="btn-small btn-submit" type="submit">Login</button>
                  </div>
                  <div class="input-field col s10 offset-s2">
                    <a routerLink='/password-forgotten'>
                      <button class="btn-small btn-submit">Passwort vergessen?</button>
                    </a>
                  </div>
                </div>
                <!-- check if started in dev mode to display info for testportal -->
                <div class ="row" *ngIf="testSystem">
                  <div class="input-field col s10 offset-s5">
                    <button class="dialog-controll-button unclickable">{{getVersion()}}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
