/**
 * MdtDbWebService
 * This is a service for basic communication with database
 *
 * OpenAPI spec version: 1.6.0.0
 * Contact: info@moderndrive.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 import { CustomHttpUrlEncodingCodec }                        from './encoder';
 import { Observable }                                        from 'rxjs';
 import { Geozone } from '../model/mdtdb/geozone';
 import { Configuration }                                     from './configuration';
 import { SessionHelper } from './sessionhelper';
 import { AppConfigService } from '../app-config.service';


@Injectable()
export class SecurityService {

  protected basePath = Configuration.getServiceBasePath();
  public defaultHeaders = new HttpHeaders();
  public configuration  = new Configuration();

  constructor(protected httpClient: HttpClient, private appConfig: AppConfigService) {
      this.basePath = appConfig.getConfig().webservice.url;
  }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Evaluate permissions for Webportal V4 containing account permissions and selected user permissions.
     *
     * @param customerId
     * @param userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public securityWebportalPermissions(customerId?: string, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public securityWebportalPermissions(customerId?: string, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public securityWebportalPermissions(customerId?: string, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public securityWebportalPermissions(customerId?: string, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Blob>(`${this.basePath}/api/Security/WebportalPermissions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
