<div class="background-container">
  <div class="content">
    <div class="main-content-container">
      <mat-icon class="primary-icon clickable" (click)="openNewEquipmentGroupDialog()" title="{{globals.languageTable_res.get(2015)}}">playlist_add</mat-icon>

      <!--
      <div class="spinner-container" *ngIf="dataSource.loading$ | async">
        <mat-spinner></mat-spinner>
      </div>
        -->

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- group name column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>
            {{globals.languageTable_res.get(2010)}}
          </th>
          <td mat-cell *matCellDef="let group">
            <span class="dark-text">
              {{group.groupName}}
            </span>
          </td>
        </ng-container>

        <!-- group name column -->
        <!-- display only if device is not mobile because of space issues-->
        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2020)}}</th>
          <td mat-cell *matCellDef="let group">
            <span class="dark-text">
              {{group.createdBy}}
            </span>
          </td>
        </ng-container>

        <!-- group name column -->
        <!-- display only if device is not mobile because of space issues-->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2021)}}</th>
          <td mat-cell *matCellDef="let group">
            <span class="dark-text">
              {{languageService.getLocaleDate(group.createdAt)}}
            </span>
          </td>
        </ng-container>

        <!-- details column -->
        <ng-container matColumnDef="equipments">
          <th class="row-detail" mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2028)}}</th>
          <td class="row-detail" mat-cell *matCellDef="let group">
            <mat-icon (click)="openEquipmentSelection(group)" class="dark-icon clickable">commute</mat-icon>
          </td>
        </ng-container>

        <!-- details column -->
        <ng-container matColumnDef="details">
          <th class="row-detail" mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2011)}}</th>
          <td class="row-detail" mat-cell *matCellDef="let group">
            <mat-icon (click)="openEquipmentGroupDialog(group)" class="dark-icon clickable">more_vert</mat-icon>
          </td>
        </ng-container>

        <!-- delete column -->
        <ng-container matColumnDef="delete">
          <th class="row-detail" mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2012)}}</th>
          <td class="row-detail" mat-cell *matCellDef="let group">
            <mat-icon (click)="openDeleteObjectDialog(group)" class="dark-icon clickable">delete</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
