import { Component, Input, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { GeozoneService } from 'src/app/generated-services/api';
import { GeozoneInsideView } from 'src/app/view/geozoneInsideView';
import { AuthService, LanguageService } from 'src/app/service/api';
import { MatTableDataSource } from '@angular/material';
import { EquipmentPositionInfo } from 'src/app/view/equipmentPositionInfo';
import { Geozone } from 'src/app/model/mdtdb/models';
import { Status } from 'src/app/enumeration/status';
import { GoogleMapComponent } from '../../google-map/google-map.component';
import { Geometry } from 'src/app/model/Geometry';
import { Definitions } from 'src/app/definitions';

@Component({
  selector: 'app-geo-zones-inside',
  templateUrl: './geo-zones-inside.component.html',
  styleUrls: ['./geo-zones-inside.component.css']
})
export class GeoZonesInsideComponent implements OnInit {
  private _geozones: Geozone[] = new Array();
  private _views: GeozoneInsideView[] = new Array();
  private _dataSource = new MatTableDataSource<EquipmentPositionInfo>();
  private _displayedColumns = ['description', 'date'];
  empty = true;

  constructor(private _authService: AuthService,
              private _geozoneService: GeozoneService,
              private _languageService: LanguageService) { }

  async ngOnInit() {
    if(await this._authService.sessionValidation()) {
      await this.initializeGeozoneInsideViews();
    }
  }

  private initializeGeozoneInsideViews() {
    this._geozoneService.geozoneGetGeozoneInsideViews(this._authService.session.customerId).subscribe((result: GeozoneInsideView[]) => {
      if (result && result.length > 0) {
        this._views = result;
        this.empty = false;
      }
    });
  }

  public getDataSource(entry: EquipmentPositionInfo[]) {
    this._dataSource = new MatTableDataSource<EquipmentPositionInfo>();

    if (entry) {
      this._dataSource.data = entry;
    }

    return this._dataSource;
  }

  // getters
  public get displayedColumns(): string[] {
    return this._displayedColumns;
  }

  public get languageService(): LanguageService {
    return this._languageService;
  }

  public get views(): GeozoneInsideView[] {
    return this._views;
  }
}
