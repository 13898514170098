import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { Equipment, SessionInfo, Geozone } from 'src/app/model/mdtdb/models';
import { SessionService, EquipmentService } from 'src/app/generated-services/api';
import { AuthService, InfoService, LanguageService } from 'src/app/service/api';
import { ProjectCommon } from 'src/app/project-common.class';
import { Status } from 'src/app/enumeration/status';
import {} from 'googlemaps';
import { GeozoneService } from 'src/app/generated-services/geozone.service';
import { DeleteObjectComponent } from 'src/app/component/dialogs/delete-object/delete-object.component';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { GeozoneFormComponent } from 'src/app/component/dialogs/tracking/geozone-form/geozone-form.component';
import { GeoZoneEvaluationComponent } from 'src/app/component/dialogs/tracking/geo-zone-evaluation/geo-zone-evaluation.component';
import { DialogResponse } from 'src/app/view/dialogResponse';
import { DialogService } from 'src/app/service/dialog.service';
import { EquipmentSelectionComponent } from 'src/app/component/dialogs/equipment-selection/equipment-selection.component';
import { ValidationService } from 'src/app/service/validation.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-geo-zones-overview',
  templateUrl: './geo-zones-overview.component.html',
  styleUrls: ['./geo-zones-overview.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeoZonesOverviewComponent implements OnInit {

  private _dataSource = new MatTableDataSource<Geozone>();
  private _displayedColumns = ['description', 'createdBy', 'createdAt', 'evaluation', 'equipments', 'details', 'delete'];
  private _equipmentsOfCustomer: Equipment[];
  private _geozones: Geozone[];

  constructor(private _authService: AuthService,
              private _deleteObjectDialog: MatDialog,
              private _dialogService: DialogService,
              private _equipmentSelection: MatDialog,
              private _evaluationDialog: MatDialog,
              private _equipmentService: EquipmentService,
              private _geozoneForm: MatDialog,
              private _geozoneService: GeozoneService,
              public globals: Globals,
              private _infoService: InfoService,
              private _languageService: LanguageService,
              private _validationService: ValidationService) { }

  async ngOnInit() {
    if(await this._authService.sessionValidation()) {
      this.initializeGeozones();
      this.initializeEquipmentsOfCustomer();
    }
  }

  /** Load all equipments of customer from equipment service */
  private initializeEquipmentsOfCustomer() {
    this._equipmentService.equipmentGetEquipmentsForCustomer(this._authService.session.customerId, Status.ACTIVE).toPromise().then((equipments: Equipment[]) => {
      this._equipmentsOfCustomer = equipments;
    }).catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
    });
  }

  /** Load all existing zones of customer */
  private initializeGeozones() {
    this._geozoneService.geozoneGetForCustomer(this._authService.session.customerId, Status.ACTIVE).toPromise().then((zones: Geozone[]) => {
      this._geozones = zones;

      // reload datasource for table
      this.reloadDataSource();
    }).catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
    });
  }

  /** Delete selected geozone */
  public openDeleteObjectDialog(zone: Geozone) {
    const dialogRef = this._deleteObjectDialog.open(DeleteObjectComponent, {
      data: {
        description: zone.description,
      }
    });

    // wait for dialog is closed and check if deletion was submitted
    dialogRef.afterClosed().subscribe((response: DialogResponse) => {
      if (this._dialogService.evaluateResponse(response)) {
        if (response.data) {
          // deletion was submitted
          this._geozoneService.geozoneDelete(zone.id, 'response').subscribe((response: HttpResponse<any>) => {
            let result = this._validationService.evaluateResponseStatus(response);

            if (result) {
              this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_SUCCESS);
              this._geozones = this._geozones.filter(item => item !== zone);
              // reload datasource for table
              this.reloadDataSource();
            }
          });
        }
      }
    });
  }

  /* Open equipment selection dialog */
  public async openEquipmentSelection(geozone: Geozone) {
    // TODO : load equipments of group
    await this._geozoneService.geozoneGetEquipmentsOfGeozone(geozone.id, Status.ACTIVE).toPromise().then((equipmentsOfGeozone: Equipment[]) => {
      const dialogRef = this._equipmentSelection.open(EquipmentSelectionComponent, {
        data: {
          equipments: this._equipmentsOfCustomer,
          selectedEquipments: equipmentsOfGeozone
        }
      });

      dialogRef.afterClosed().subscribe((response: DialogResponse) => {
        if (this._dialogService.evaluateResponse(response)) {
          var equipmentIds = EquipmentSelectionComponent.GetSelectedEquipmentIds(response.data);

          this._geozoneService.geozoneUpdateEquipmentRelationsOfGeozone(equipmentIds, geozone.id, this._authService.loggedInUser.username).toPromise().then((result: boolean) => {
            if (result) {
              this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_SUCCESS);
            } else {
              this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_ERROR);
            }
          }).catch(err => {
            console.log(JSON.stringify(err));
            this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_ERROR);
          });
        }
      });
    }).catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
    });
  }

  /** Delete selected geozone */
  public openEvaluationDialog(geozone: Geozone) {
    this._geozoneService.geozoneGetEquipmentsOfGeozone(geozone.id, Status.ACTIVE).toPromise().then((equipments: Equipment[]) => {

      if (equipments && equipments.length) {
        const dialogRef = this._evaluationDialog.open(GeoZoneEvaluationComponent, {
          data: {
            geozone: geozone,
            equipmentsOfGeozone: equipments,
          }
        });
      } else {
        this._infoService.showInfoWindow(Globals.TYPE_INFO, this.globals.languageTable_res.get(2063));
      }
    }).catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
    });
  }

  /** Open existing geozone form */
  public openGeozoneDialog(geozone: Geozone) {
    const dialogRef = this._geozoneForm.open(GeozoneFormComponent, {
      data: {
        geozone: geozone,
        descriptions: this._geozones.map(x => x.description),
        equipmentsOfCustomer: this._equipmentsOfCustomer
      }
    });

    // wait for dialog is closed and action is done
    dialogRef.afterClosed().subscribe((response: DialogResponse) => {
      if (this._dialogService.evaluateResponse(response)) {
        this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_SUCCESS);
        // reload datasource for table
        this.reloadDataSource();
      }
    });
  }

  /** Open empty geozone form */
  public openNewGeozoneDialog() {
    const dialogRef = this._geozoneForm.open(GeozoneFormComponent, {
      data: {
        geozone: null,
        descriptions: this._geozones.map(x => x.description),
        equipmentsOfCustomer: this._equipmentsOfCustomer
      }
    });

    // wait for dialog is closed and action is done
    dialogRef.afterClosed().subscribe((response: DialogResponse) => {
      if (this._dialogService.evaluateResponse(response)) {
        this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_SUCCESS);
        // add zone to zones
        this._geozones.push(response.data);
        // order zones again by description
        this._geozones = this._geozones.sort((a, b) => a.description.localeCompare(b.description));
        // reload datasource for table
        this.reloadDataSource();
      }
    });
  }

  /** Refresh the data for data table */
  private reloadDataSource() {
    this._dataSource.data = this._geozones;
  }

  // getters
  public get dataSource(): MatTableDataSource<Geozone> {
    return this._dataSource;
  }

  public get displayedColumns(): string[] {
    return this._displayedColumns;
  }

  public get languageService(): LanguageService {
    return this._languageService;
  }
}
