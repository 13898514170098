<h2 mat-dialog-title>{{globals.languageTable_res.get(2064)}}</h2>

<mat-dialog-content>
  <div class="container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select" class="row-select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="analyzeCheckboxClick($event, row)"
                        [checked]="selection.isSelected(row)"
                        [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>


      <!-- licence plate -->
      <ng-container matColumnDef="licencePlate">
        <th mat-header-cell *matHeaderCellDef>{{globals.languageTable_res.get(2026)}}</th>
        <td mat-cell *matCellDef="let equipment">{{equipment.licencePlate}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
    </table>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button class="dialog-controll-button"(click)="cancel()">{{globals.languageTable_res.get(2018)}}</button>
  <button class="dialog-controll-button"(click)="save()">{{globals.languageTable_res.get(2019)}}</button>
</mat-dialog-actions>


