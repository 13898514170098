export * from './auth.service';
import { AuthService } from './auth.service';
export * from './chart.service';
import { ChartService } from './chart.service';
export * from './info.service';
import { InfoService } from './info.service';
export * from './language.service';
import { LanguageService } from './language.service';
export * from './navigation.service';
import { NavigationService } from './navigation.service';
export * from './routing.service';
import { RoutingService } from './routing.service';
export * from './user.service';
import { UserServiceStandard } from './user.service';
export const APIS = [AuthService, ChartService, InfoService, LanguageService, NavigationService, RoutingService, UserServiceStandard];
