import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService, NavigationService, RoutingService } from 'src/app/service/api';
import { MatDrawer } from '@angular/material';
import { Globals } from 'src/app/globals';

export interface NavItem {
  displayName: number;
  disabled?: boolean;
  iconName: string;
  route?: string;
  children?: NavItem[];
}

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.css']
})
export class SiteLayoutComponent implements OnInit {
  @ViewChild('appDrawer', {static: true}) appDrawer: MatDrawer;
  navItems: NavItem[] = new Array();

  constructor(private _authService: AuthService,
              private _navigationService: NavigationService,
              private _routingService: RoutingService) {
                this._authService.customerModernDrive.subscribe((result: boolean) => {
                  if (result) this.addCustomerMDProperties();
                });
               }

  ngOnInit() {
    this.initializeNavigation();
  }

  private initializeNavigation() {
    this.navItems = new Array();

    const trackingItems = {
      displayName: 2013,
      iconName: 'location_on',
      route: RoutingService.ROUTE_TRACKING_FLEET,
      children: []
    };

    const evaluationItems = {
      displayName: 2054,
      iconName: 'bar_chart',
      route: RoutingService.ROUTE_REPORTING_EQUIPMENT,
      children: [
        {
          displayName: 2130,
          iconName: 'directions_car',
          route: RoutingService.ROUTE_REPORTING_EQUIPMENT,
          children: [],
        },
        {
          displayName: 2132,
          iconName: 'commute',
          route: RoutingService.ROUTE_REPORTING_FLEET_REPORT,
          children: [],
        }
      ]
    };

    const administrationItems = {
      displayName: 2129,
      iconName: 'engineering',
      route: RoutingService.ROUTE_TRACKING_GROUPS_OVERVIEW,
      children: [
        {
          displayName: 2006,
          iconName: 'commute',
          route: RoutingService.ROUTE_TRACKING_GROUPS_OVERVIEW,
          children: [],
        },
        {
          displayName: 2009,
          iconName: 'fullscreen',
          route: RoutingService.ROUTE_TRACKING_GEO_ZONES_OVERVIEW,
          children: []
        }
      ]
    };

    // TODO : dburger
    // add permission service to decide which modules should be displayed for customer
    this._authService._customerModules.subscribe((result: any) => {
      if (result) {
        this.navItems = new Array();

        this.navItems.push(trackingItems);
        if (this._authService.hasPermission(Globals.ID_ACL_EVALUATION)) this.navItems.push(evaluationItems);
        this.navItems.push(administrationItems);

        if (this._authService.isCustomerModernDrive()) {
          this.addCustomerMDProperties();
        }

        this.navigationService.setAppDrawer(this.appDrawer);
      }
    })

  }

  // method to add only properties for customer MD
  public addCustomerMDProperties() {
  }

  public get navigationService(): NavigationService {
    return this._navigationService;
  }

  public get routingService(): RoutingService {
    return this._routingService;
  }
}
