import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EquipmentService } from 'src/app/generated-services/api';
import { Globals } from 'src/app/globals';
import { Equipment } from 'src/app/model/mdtdb/models';
import { AuthService, InfoService } from 'src/app/service/api';
import { DialogService } from 'src/app/service/dialog.service';
import { ValidationService } from 'src/app/service/validation.service';
import { DialogResponse } from 'src/app/view/dialogResponse';
import { TrackingEquipmentEntry } from 'src/app/view/trackingEquipmentEntry';

@Component({
  selector: 'app-edit-licenceplate',
  templateUrl: './edit-licenceplate.component.html',
  styleUrls: ['./edit-licenceplate.component.css']
})
export class EditLicenceplateComponent implements OnInit {

  private _entry: TrackingEquipmentEntry;
  licencePlate: string;
  response: DialogResponse = {};

  licencePlateFg: FormGroup;

  constructor(private _authService: AuthService,
              @Inject(MAT_DIALOG_DATA) private _data: any,
              private _dialogRef: MatDialogRef<EditLicenceplateComponent>,
              public globals: Globals,
              private _validationService: ValidationService,
              private _infoService: InfoService,
              private _equipmentService: EquipmentService) {
                this._entry = _data.entry
    }

  ngOnInit() {
    this.licencePlateFg = new FormGroup({
      licencePlate: new FormControl('')
    });

    if (this._entry)
    {
      this.licencePlate = this._entry.licencePlate;
      this.licencePlateFg.get('licencePlate').setValue(this.licencePlate);
    }
  }

  /** Cancel and kill dialog life cycle */
  public cancel() {
    this.response.responseCode = DialogService.RESPONSE_CODE_CANCEL;
    // close dialog an return that deletion was aborted
    this._dialogRef.close(this.response);
  }

  public save() {
    this.licencePlate = this.licencePlateFg.get('licencePlate').value;

    this._equipmentService.equipmentChangeLicencePlate(this._entry.equipmentId, this.licencePlate, this._authService.loggedInUser.username).toPromise().then((equipment: Equipment) => {
      if (equipment) {
        this.response.responseCode = DialogService.RESPONSE_CODE_SUCCESS;
        this.response.data = this.licencePlate;

        this._dialogRef.close(this.response);
      }
      else {
        this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_ERROR);
      }
    }).catch(x => {
      this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_ERROR);
    });
  }

}
