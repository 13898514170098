/**
 * MdtDbWebService
 * This is a service for basic communication with database
 *
 * OpenAPI spec version: 1.2.3.0
 * Contact: info@moderndrive.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */
 import { Injectable } from '@angular/core';
 import { HttpClient, HttpHeaders,
          HttpResponse, HttpEvent, HttpParams } from '@angular/common/http';
 import { Observable } from 'rxjs';
 import { Configuration } from './configuration';
 import { SessionHelper } from './sessionhelper';
 import { AppConfigService } from '../app-config.service';
import { TrackingEquipmentEntry } from '../view/trackingEquipmentEntry';
import { CustomHttpUrlEncodingCodec } from './encoder';
import { EventView } from '../view/eventView';
import { TdStatus } from '../model/mdtdata/tdStatus';


 @Injectable()
 export class DeviceService {

  protected basePath = Configuration.getServiceBasePath();
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, private appConfig: AppConfigService) {
        this.basePath = appConfig.getConfig().webservice.url;
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    /**
     *
     *
     * @param deviceId
     * @param from
     * @param to
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public deviceEvents(deviceId: string, from?: Date, to?: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<EventView>>;
     public deviceEvents(deviceId: string, from?: Date, to?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EventView>>>;
     public deviceEvents(deviceId: string, from?: Date, to?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EventView>>>;
     public deviceEvents(deviceId: string, from?: Date, to?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (deviceId === null || deviceId === undefined) {
             throw new Error('Required parameter deviceId was null or undefined when calling deviceEvents.');
         }

         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (from !== undefined && from !== null) {
             queryParameters = queryParameters.set('from', <any>from.toISOString());
         }
         if (to !== undefined && to !== null) {
             queryParameters = queryParameters.set('to', <any>to.toISOString());
         }

         let headers = SessionHelper.getStandardSessionHeader();

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.get<Array<EventView>>(`${this.basePath}/api/Device/Events/${encodeURIComponent(String(deviceId))}`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

  /**
  * Get the last status object for each device.
  *
  * @param body
  * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
  * @param reportProgress flag to report request and response progress.
  */
  public deviceLastTdStatus(body: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<any>>;
  public deviceLastTdStatus(body: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<any>>>;
  public deviceLastTdStatus(body: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<any>>>;
  public deviceLastTdStatus(body: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling deviceLastTdStatus.');
    }

    let headers = SessionHelper.getStandardSessionHeader();

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
        'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
        'application/json'
    ];
    const httpContentTypeSelected: string | undefined = SessionHelper.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<any>>(`${this.basePath}/api/Device/LastTdStatus`,
        body,
        {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        }
    );
  }

  /**
   *
   * @param customerId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
   public deviceGetTrackingAddresses(customerId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TrackingEquipmentEntry>>;
   public deviceGetTrackingAddresses(customerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TrackingEquipmentEntry>>>;
   public deviceGetTrackingAddresses(customerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TrackingEquipmentEntry>>>;
   public deviceGetTrackingAddresses(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

     if (customerId === null || customerId === undefined) {
         throw new Error('Required parameter customerId was null or undefined when calling deviceGetTrackingAddresses.');
     }

     let headers = SessionHelper.getStandardSessionHeader();

     // to determine the Accept header
     let httpHeaderAccepts: string[] = [
         'application/json'
     ];
     const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
     if (httpHeaderAcceptSelected != undefined) {
         headers = headers.set('Accept', httpHeaderAcceptSelected);
     }

     // to determine the Content-Type header
     const consumes: string[] = [
     ];

     return this.httpClient.get<Array<TrackingEquipmentEntry>>(`${this.basePath}/api/Device/GetTrackingAddresses/${encodeURIComponent(String(customerId))}`,
         {
             withCredentials: this.configuration.withCredentials,
             headers: headers,
             observe: observe,
             reportProgress: reportProgress
         }
     );
   }

  /**
   *
   * @param customerId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
   public deviceGetTrackingData(customerId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TrackingEquipmentEntry>>;
   public deviceGetTrackingData(customerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TrackingEquipmentEntry>>>;
   public deviceGetTrackingData(customerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TrackingEquipmentEntry>>>;
   public deviceGetTrackingData(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

     if (customerId === null || customerId === undefined) {
         throw new Error('Required parameter customerId was null or undefined when calling deviceGetTrackingData.');
     }

     let headers = SessionHelper.getStandardSessionHeader();

     // to determine the Accept header
     let httpHeaderAccepts: string[] = [
         'application/json'
     ];
     const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
     if (httpHeaderAcceptSelected != undefined) {
         headers = headers.set('Accept', httpHeaderAcceptSelected);
     }

     // to determine the Content-Type header
     const consumes: string[] = [
     ];

     return this.httpClient.get<Array<TrackingEquipmentEntry>>(`${this.basePath}/api/Device/GetTrackingData/${encodeURIComponent(String(customerId))}`,
         {
             withCredentials: this.configuration.withCredentials,
             headers: headers,
             observe: observe,
             reportProgress: reportProgress
         }
     );
   }

   /**
   *
   * @param customerId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
    public deviceGetTrackingDeviceData(customerId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TrackingEquipmentEntry>>;
    public deviceGetTrackingDeviceData(customerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TrackingEquipmentEntry>>>;
    public deviceGetTrackingDeviceData(customerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TrackingEquipmentEntry>>>;
    public deviceGetTrackingDeviceData(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      if (customerId === null || customerId === undefined) {
          throw new Error('Required parameter customerId was null or undefined when calling deviceGetTrackingDeviceData.');
      }

      let headers = SessionHelper.getStandardSessionHeader();

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
          'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.get<Array<TrackingEquipmentEntry>>(`${this.basePath}/api/Device/GetTrackingDeviceData/${encodeURIComponent(String(customerId))}`,
          {
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress
          }
      );
    }

   /**
     *
     * @param serial
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceSimConnectivityInfo(serial: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deviceSimConnectivityInfo(serial: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deviceSimConnectivityInfo(serial: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deviceSimConnectivityInfo(serial: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (serial === null || serial === undefined) {
            throw new Error('Required parameter serial was null or undefined when calling deviceSimConnectivityInfo.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/Device/SimConnectivityInfo/${encodeURIComponent(String(serial))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
   *
   * @param latitude
   * @param longitude
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
     public deviceReverseGeocoding(latitude: number, longitude: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
     public deviceReverseGeocoding(latitude: number, longitude: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
     public deviceReverseGeocoding(latitude: number, longitude: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
     public deviceReverseGeocoding(latitude: number, longitude: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
      if (latitude !== undefined && latitude !== null) {
          queryParameters = queryParameters.set('latitude', <any>latitude);
      }
      if (longitude !== undefined && longitude !== null) {
          queryParameters = queryParameters.set('longitude', <any>longitude);
      }

       let headers = SessionHelper.getStandardSessionHeader();

       // to determine the Accept header
       let httpHeaderAccepts: string[] = [
           'application/json'
       ];
       const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
       if (httpHeaderAcceptSelected != undefined) {
           headers = headers.set('Accept', httpHeaderAcceptSelected);
       }

       // to determine the Content-Type header
       const consumes: string[] = [
       ];

       return this.httpClient.get<string>(`${this.basePath}/api/Device/ReverseGeocoding`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
     *
     * @param deviceId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceLastGsmInfo(deviceId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deviceLastGsmInfo(deviceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deviceLastGsmInfo(deviceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deviceLastGsmInfo(deviceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling deviceLastGsmInfo.');
        }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TdStatus>(`${this.basePath}/api/Device/LastGsmInfo/${encodeURIComponent(String(deviceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
   *
   * @param mcc
   * @param mnc
   * @param lac
   * @param cid
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
      public deviceGetRadioCellLocation(mcc: number, mnc: number, lac: number, cid: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
      public deviceGetRadioCellLocation(mcc: number, mnc: number, lac: number, cid: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
      public deviceGetRadioCellLocation(mcc: number, mnc: number, lac: number, cid: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
      public deviceGetRadioCellLocation(mcc: number, mnc: number, lac: number, cid: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

       let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
       if (mcc !== undefined && mcc !== null) {
           queryParameters = queryParameters.set('mcc', <any>mcc);
       }
       if (mnc !== undefined && mnc !== null) {
           queryParameters = queryParameters.set('mnc', <any>mnc);
       }
       if (lac !== undefined && lac !== null) {
          queryParameters = queryParameters.set('lac', <any>lac);
      }
      if (cid !== undefined && cid !== null) {
          queryParameters = queryParameters.set('cid', <any>cid);
      }

        let headers = SessionHelper.getStandardSessionHeader();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = SessionHelper.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/Device/GetRadioCellLocation`,
              {
                  params: queryParameters,
                  withCredentials: this.configuration.withCredentials,
                  headers: headers,
                  observe: observe,
                  reportProgress: reportProgress
              }
          );
      }
}
