import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AuthService, InfoService, LanguageService } from 'src/app/service/api';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource, MatSort, MatSelect, MatDialog } from '@angular/material';
import { DeviceService, TripDataService } from 'src/app/generated-services/api';
import { TripDataPosition, TripData } from 'src/app/model/mdtdatabase/models';
import { ValidationService } from 'src/app/service/validation.service';
import { Globals } from 'src/app/globals';
import * as $ from 'jquery';
import { TrackingEquipmentEntry } from 'src/app/view/trackingEquipmentEntry';
import { EventView } from 'src/app/view/eventView';
import { BehaviorSubject } from 'rxjs';
import { GoogleMapComponent } from 'src/app/component/tracking/google-map/google-map.component';
import { FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingSpinnerComponent } from 'src/app/component/filter/loading-spinner/loading-spinner.component';

interface TripTableEntry {
  tripId: string,
  beginDatetime: Date,
  begin: string,
  end: string,
  sumDuration: number,
  duration: string,
  sumDistance: number,
  distance: number,
  coordinates: google.maps.LatLng[],
  polyLine: google.maps.Polyline,
  polyColor: string
}

interface EventTableEntry {
  identifier?: string,
  event?: string,
  name?: string,
  iconName?: string,
  color?: string,
  recordingDatetime?: Date,
  position?: TripDataPosition,
  date?: string,
  address?: string
}

interface EventIcon {
  identifier: string;
  iconName: string;
}

@Component({
  selector: 'app-routing',
  templateUrl: './routing.component.html',
  styleUrls: ['./routing.component.css']
})
export class RoutingComponent implements OnInit, AfterViewInit {
  dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  entry: TrackingEquipmentEntry;
  @ViewChild('routingMapComponent', {static: false}) mapComponent: GoogleMapComponent;
  @ViewChild('diagramSelect', {static: false}) diagramSelect: MatSelect;

  /** control for the selected diagram type */
  public diagramCtrl: FormControl = new FormControl();
  public diagramFilter: string[] = [ 'speed', 'satellites', 'height' ];
  public chart: ApexCharts;

  /** control for the selected timespan */
  public datetimeCtrl: FormControl = new FormControl();
  public datetimeFilter: string[] = [ 'today', 'yesterday', 'current_week', 'last_week' ];

  toDate: Date = new Date();
  fromDate: Date = new Date();

  showMap = true;

  private _positions: TripDataPosition[] = new Array();
  private _trips: TripData[] = new Array();
  private _events: EventView[] = new Array();

  // trips table
  public tripDataSource = new MatTableDataSource<TripTableEntry>();
  public displayedTripColumns = ['begin', 'end', 'duration', 'distance', 'color'];
  public eventDataSource = new MatTableDataSource<EventTableEntry>();
  public displayedEventColumns = ['date'];
  private sort: MatSort;
  @ViewChild(MatSort, {static: false}) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  eventIcons: EventIcon[] = new Array();
  eventEntries: EventTableEntry[] = new Array();
  tripEntries: TripTableEntry[] = new Array();

  mapReadyState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  openedInfoWindow: any;
  public defaultPolyColor: string = '#A60017';
  public polyLines: google.maps.Polyline[] = new Array();
  public markers: google.maps.Marker[] = new Array();
  public static SIZE_SMALL = 100;
  public static SIZE_MEDIUM = 1000;

  addressPermission: boolean = false;

  constructor(private _authService: AuthService,
              private cdr: ChangeDetectorRef,
              @Inject(MAT_DIALOG_DATA) private _data: any,
              private _deviceService: DeviceService,
              private _dialogRef: MatDialogRef<RoutingComponent>,
              public globals: Globals,
              private _infoService: InfoService,
              private _languageService: LanguageService,
              private _loadingSpinnerDialog: MatDialog,
              private _tripDataService: TripDataService,
              private _validationService: ValidationService) {
                this.entry = _data;
              }

  ngOnInit() {
    this.initIcons();
    this.addressPermission = this._authService.hasPermission(Globals.ID_ACL_REVERSE_GEOCODING);
    if (this.addressPermission) this.displayedEventColumns = ['date', 'address'];
  }

  ngAfterViewInit() {
    this.createRoute(true);
    this.cdr.detectChanges();
  }

  public changeColor(record: TripTableEntry) {
    if (record) {
      record.polyLine.setOptions({strokeColor: record.polyColor});
    }
  }

  public createChart(filter: string) {
    // destryo chart
    if (this.chart) this.chart.destroy();

    var beg = this.getFromDate().toDateString();
    var to = this.getToDate().toDateString();
    var withDate: boolean = (beg === to) ? false : true;
    // TODO choose selector

    // test purpose - only speed
    var data = new Array();

    if (filter === 'speed') {
      this._positions.forEach(p => {
        var x = {
          y: this._languageService.round(p.speed, 0),
          x: (withDate === true) ? this._languageService.getLocaleDateTime(p.recordingDatetime) : this._languageService.getLocaleTime(p.recordingDatetime)
        };
        data.push(x);
      });
    } else if (filter === 'satellites') {
      this._positions.forEach(p => {
        var x = {
          y: p.satellites,
          x: (withDate === true) ? this._languageService.getLocaleDateTime(p.recordingDatetime) : this._languageService.getLocaleTime(p.recordingDatetime)
        };
        data.push(x);
      });
    } else if (filter === 'height') {
      this._positions.forEach(p => {
        var x = {
          y: p.height,
          x: (withDate === true) ? this._languageService.getLocaleDateTime(p.recordingDatetime) : this._languageService.getLocaleTime(p.recordingDatetime)
        };
        data.push(x);
      });
    }

    var xLength = (withDate) ? 3 : 6;
    if (data.length < 3) xLength = data.length;

    var options = {
      series: [{
      name: this._languageService.getTranslationFromKeyword(filter),
      data: data
      }],
      stroke: {
        width: 1
    },
      chart: {
        type: 'area',
        stacked: false,
        height: 100,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: false
        },
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        labels: {
          show: false
        }
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      markers: {
        size: 0,
      }
    };

    this.chart = new ApexCharts(document.querySelector("#chart"), options);
    this.chart.render();
  }

  public cancel() {
    this._dialogRef.close();
  }

  public initIcons() {
    var x: EventIcon = {
      identifier: 'ignition',
      iconName: 'power_settings_new'
    };
    this.eventIcons.push(x);

    var y: EventIcon = {
      identifier: 'motion',
      iconName: 'swap_horiz'
    };
    this.eventIcons.push(y);

    var z: EventIcon = {
      identifier: 'trip',
      iconName: 'gesture'
    };
    this.eventIcons.push(z);
  }

  public getIcon(identifier: string) {
    if (identifier) {
      var x = this.eventIcons.find(x => x.identifier === identifier);
      if (x) return x.iconName;
    }
  }

  private initEventEntries() {
    this._events.forEach(e => {
      var iconName = '';
      var ei = this.eventIcons.find(x => x.identifier === e.event);
      if (ei) iconName = ei.iconName;
      var x: EventTableEntry = {
        identifier: e.event,
        event: e.event,
        name: this._languageService.getTranslationFromKeyword(e.event),
        iconName: iconName,
        color: e.eventValue === '1' ? 'green' : 'red',
        recordingDatetime: e.recordingTimestamp,
        date: this._languageService.getLocaleDateTime(e.recordingTimestamp)
      };

      this.eventEntries.push(x);
    });

    this.eventDataSource.data = this.eventEntries;
    this.eventEntries.forEach(e => {
      var p = this._positions.find(p => this._languageService.getLocaleDateTime(p.recordingDatetime) === e.date);
      if (p) {
        this._deviceService.deviceReverseGeocoding(p.latitude, p.longitude).toPromise().then((result: any) => { e.address = result; });
      }
    });
  }

  private initTripEntries() {
    this._trips.forEach(t => {
      var endDatetime = new Date(t.beginDatetime);
      endDatetime.setSeconds(endDatetime.getSeconds() + t.sumDuration);
      endDatetime.setMinutes(endDatetime.getMinutes() - endDatetime.getTimezoneOffset());
      var x: TripTableEntry = {
        tripId: t.id,
        beginDatetime: t.beginDatetime,
        begin: this._languageService.getLocaleDateTime(t.beginDatetime),
        end: this._languageService.dateToString(endDatetime),
        sumDuration: t.sumDuration,
        duration: this._languageService.convertToTime(t.sumDuration),
        sumDistance: t.sumDistance,
        distance: this._languageService.round(t.sumDistance, 2),
        coordinates: new Array(),
        polyColor: this.defaultPolyColor,
        polyLine: undefined
      };

      this.tripEntries.push(x);
    });

    this.tripDataSource.data = this.tripEntries;
  }

  public selectTripEntry(trip: TripData) {
  }

  setDataSourceAttributes() {
    this.eventDataSource.sortingDataAccessor = (item, property) => {
      switch(property) {
        case 'name': if (item.event) return this._languageService.getTranslationFromKeyword(item.name);
        default: return item[property];
      }
    };
    this.eventDataSource.sort = this.sort;

    this.tripDataSource.sortingDataAccessor = (item, property) => {
      switch(property) {
        // case 'position': if (item.position) return item.position.recordingDatetime;
        default: return item[property];
      }
    };
    this.tripDataSource.sort = this.sort;
  }

  public selectTimespan(identifier: string) {
    this.fromDate = new Date();
    this.toDate = new Date();

    if (identifier === 'yesterday') {
      this.fromDate.setDate(this.fromDate.getDate() - 1);
      this.toDate.setDate(this.toDate.getDate() - 1);
    }
    else if(identifier === 'current_week') {
      var curr = new Date; // get current date
      var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
      var last = first + 6; // last day is the first day + 6

      this.fromDate = new Date(curr.setDate(first));
      this.toDate = new Date(curr.setDate(last));
    }
    else if (identifier === 'last_week') {
      var curr = new Date; // get current date
      var first = curr.getDate() - curr.getDay() + 1 - 7; // First day is the day of the month - the day of the week
      // var last = first + 6; // last day is the first day + 6

      this.fromDate = new Date(curr.setDate(first));
      // this.toDate = new Date(curr.setDate(last));
      this.toDate = new Date(this.fromDate);
      this.toDate.setDate(this.toDate.getDate() + 6);
    }

    this.createRoute(false);
  }

  private clearMapOverlay() {
    if (this.markers && this.markers.length > 0) {
      this.markers.forEach(x => {
        if (x) x.setMap(null);
      });
    }
    if (this.polyLines && this.polyLines.length > 0) {
      this.polyLines.forEach(x => {
        if (x) x.setMap(null);
      });
    }

    this.markers = new Array();
    this.polyLines = new Array();
  }

  /* Evaluate start and end date of route and request trip data */
  public createRoute(dateFromPicker: boolean) {
    this.clearMapOverlay();
    this.tripDataSource = new MatTableDataSource<TripTableEntry>();
    this.eventDataSource = new  MatTableDataSource<EventTableEntry>();
    this.tripEntries = new Array();
    this._positions = new Array();
    this._trips = new Array();
    this.eventEntries = new Array();
    this._events = new Array();

    this.dataLoaded.next(false);
    this._loadingSpinnerDialog.open(LoadingSpinnerComponent, {
      data: {
        diameter: 57,
        loading: this.dataLoaded
      }
    });

    var from: Date = new Date();
    var to: Date = new Date();

    if (dateFromPicker) {
      from.setDate(this.getFromDate().getDate());
      from.setMonth(this.getFromDate().getMonth());
      from.setFullYear(this.getFromDate().getFullYear());
      to.setDate(this.getToDate().getDate());
      to.setMonth(this.getToDate().getMonth());
      to.setFullYear(this.getToDate().getFullYear());
    } else {
      from.setDate(this.fromDate.getDate());
      from.setMonth(this.fromDate.getMonth());
      from.setFullYear(this.fromDate.getFullYear());

      to.setDate(this.toDate.getDate());
      to.setMonth(this.toDate.getMonth());
      to.setFullYear(this.toDate.getFullYear());
    }

    // set time for full days
    from.setHours(0);
    from.setMinutes(0);
    from.setSeconds(0)
    from.setMilliseconds(0);
    to.setHours(23);
    to.setMinutes(59);
    to.setSeconds(59);

    this.showMap = false;
    this.showMap = true;

    const equipIds: string[] = new Array();
    equipIds.push(this.entry.equipmentId);


    console.log(from);
    console.log(to);
    this.loadData(from, to);
  }

  private async loadData(from: Date, to: Date) {
    await this._tripDataService.tripDataGetTripDataPositionsOfEquipment(this.entry.equipmentId, from,
      to, 0).toPromise().then((positions: TripDataPosition[]) => {
        if (positions) this._positions = positions;
    }).catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
    });

    // await this._deviceService.deviceEvents(this.entry.deviceId, from,
    // to).toPromise().then((events: EventView[]) => {
    //   if (events) this._events = events;
    // }).catch((err: HttpErrorResponse) => {
    //   this._validationService.validateHttpErrorResponse(err);
    // });

    await this._tripDataService.tripDataGetTripDataOfEquipment(this.entry.equipmentId, from,
      to).toPromise().then((trips: TripData[]) => {
      if (trips) this._trips = trips;
    }).catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
    });

    this.evaluateData();
  }

  private evaluateData() {
    // positions
    if (this._positions && this._positions.length) {
      this.createChart(this.diagramFilter[0]);
    } else {
      this._infoService.showInfoWindow(Globals.TYPE_INFO, this._languageService.getTranslationFromKeyword("no_position_data_available"));
      console.log('no positions found');
    }

    // trips
    if (this._trips && this._trips.length) {
      this.initTripEntries();
    } else {
      console.log('no trips found');
    }

    // events
    // if (this._events && this._events.length) {
    //   this.initEventEntries();
    // } else {
    //   console.log('no events found');
    // }

    this.dataLoaded.next(true);

    this.createDirectionMarkers();
  }

  /* Create markers and add to map for loaded positions restricted by modSize parameter (performance issue) */
  private createDirectionMarkers() {
    let modSize: number = 1;

    modSize = RoutingComponent.EvaluateRoutePositionsModSize(this._positions.length);

    // create markers
    for (var i = 0; i < this._positions.length; i = i + modSize) {
      let p = this._positions[i];

        var x: EventTableEntry = {
          recordingDatetime: p.recordingDatetime,
          date: this._languageService.getLocaleDateTime(p.recordingDatetime),
          position: p
        };
        this.eventEntries.push(x);

        var heading;
        // calculate heading direction for markers
        if (i < this._positions.length - 1) {
          heading = google.maps.geometry.spherical.computeHeading(new google.maps.LatLng(p.latitude, p.longitude),
          new google.maps.LatLng(this._positions[i+1].latitude, this._positions[i+1].longitude));
        }
        this.createMarker(p, heading, false);
    }

    this.eventDataSource.data = this.eventEntries;
    this.eventEntries.forEach(e => {

      if (e.position) {
        this._deviceService.deviceReverseGeocoding(e.position.latitude, e.position.longitude).toPromise().then((result: any) => { e.address = result; });
      }
    });

    this.createTripPolys();
  }

  private createMarker(p: TripDataPosition, heading: any, openInfoWindow: boolean) {
    if (p) {
          // create marker
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(p.latitude, p.longitude),
        icon: {
          path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
          scale: 2,
          rotation: heading ? heading : p.course,
          fillColor: p.speed > 0 ? '#00ff00' : '#ff0000',
          strokeColor: p.speed > 0 ? '#00ff00' : '#ff0000',
          fillOpacity: 1
        },
        draggable: false,
        map: this.mapComponent.map
      });

      // add infow window to marker
      // TODO use language service for words
      const infoWindow = new google.maps.InfoWindow({
        content: '<div id="content">' + '<h3><u><strong>' + this.entry.licencePlate + '</strong></u></h3>'
                  + '<br>' + this.globals.languageTable_res.get(2047) + ': '+ Math.round(p.speed? p.speed : 0) + ' km/h'
                  + '<br>' + this.globals.languageTable_res.get(2046) + ': ' + p.height + ' m'
                  + '<br>' + this.globals.languageTable_res.get(2033) + ': ' + p.satellites
                  + '<br>' + this.globals.languageTable_res.get(2034) + ': ' + p.accurancy + '</div>'
                  + '<br><strong>' + this._languageService.getLocaleDateTime(p.recordingDatetime) + '</strong></div>'
      });

      marker.addListener('mouseover', function() {
        infoWindow.open(this.getMap(), marker);
      });
      marker.addListener('mouseout', function() {
        infoWindow.close();
      });

      if (openInfoWindow) {
        if (this.openedInfoWindow) this.openedInfoWindow.close();
        infoWindow.open(this.mapComponent.map, marker);
        this.openedInfoWindow = infoWindow;
      }

      this.markers.push(marker);
    }
  }

  public selectEvent(event: any) {
    var p = this._positions.find(p => this._languageService.getLocaleDateTime(p.recordingDatetime) === event.date);
    if (p) {
      this.createMarker(p, undefined, true);
      this.zoomOnMap(p.latitude, p.longitude);
    }
  }

  private zoomOnMap(lat: number, long: number) {
    // create bound for single marker
    const marker = new google.maps.LatLng(lat, long);
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(marker);
    // Zoom to marker
    this.mapComponent.map.fitBounds(bounds);
    // set zoom static
    this.mapComponent.map.setZoom(21);
    // open info window
    // this.openWindow(entry.id);
  }

  /** Create a polyline for given coordinates with given color an add to tripPoly object. */
  private createPolyline(coordinates: google.maps.LatLng[], tripEntry: TripTableEntry) {
    // create polyline object
    var polyline = new google.maps.Polyline({
      path: coordinates,
      geodesic: true,
      strokeColor: this.defaultPolyColor,
      strokeOpacity: 1.5,
      strokeWeight: 2
    });

    // add polyline to trip if trip exists
    if (tripEntry) {
      tripEntry.polyLine = polyline;
    }

    // add polyline to map
    polyline.setMap(this.mapComponent.map);
    this.polyLines.push(polyline);
  }

  public getFromDate(): Date {
    var result = new Date($('#fromDate').val());
    // convert to UTC
    // result.setHours(result.getHours() + (result.getTimezoneOffset() / 60));

    // result = new Date("2021-10-14");

    return result;
  }

  public getToDate(): Date {
    var result = new Date($('#toDate').val());
    // result.setHours(23);
    // result.setMinutes(59);
    // result.setSeconds(59);

    // result = new Date("2021-10-15");
    return result;
  }

  public get basicFromDate(): string {
    return this.dateToString(this.fromDate);
  }

  public get basicToDate(): string {
    return this.dateToString(this.toDate);
  }

  public dateToString(date: Date): string {
    return date.toISOString().slice(0,10);
  }

  private createTripPolys() {
    var bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    var coordinates: google.maps.LatLng[] = new Array();

    if (this.tripEntries && this.tripEntries.length) {
      // add position to corresponding tripPoly object
      for (let p of this._positions) {
        // create bounds for map
        if (p) {
          let ltLng = new google.maps.LatLng(p.latitude, p.longitude);
          coordinates.push(ltLng);
          bounds.extend(ltLng);

          // assign positions to trips
          if (p.tripDataId) {
            var tripPoly = this.tripEntries.find(x => x.tripId == p.tripDataId);

            if (tripPoly) {
              tripPoly.coordinates.push(ltLng);
            }
          }
        }
      }

      /* Bugfix to display polyline for all coordinates if trips are not stored correct */
      var color = this.defaultPolyColor;
      this.defaultPolyColor = 'red';
      this.createPolyline(coordinates, undefined);
      this.defaultPolyColor = color;
      /* Bugfix end - TODO refactor code */

      for (let tp of this.tripEntries) {
        if (coordinates && coordinates.length > 0) {
          this.createPolyline(tp.coordinates, tp);
        }
      }
    } else {
      // create polyline
      for (var i = 0; i < this._positions.length; i++) {
        let p = this._positions[i];

        if (p) {
          let ltLng = new google.maps.LatLng(p.latitude, p.longitude);
          coordinates.push(ltLng);
          bounds.extend(ltLng);
        }
      }

      this.createPolyline(coordinates, undefined);
    }

    if (this._positions && this._positions.length > 0) {
      this.mapComponent.map.fitBounds(bounds);
    }
  }

  /* Simple help method to calculate a parameter modSize which restricts the drawing of positions
  to improve load balance */
  private static EvaluateRoutePositionsModSize(length: number): number {
    let modSize: number = 1;
    // create mod size depending on positions.length to decrease loading balance
    if (length <= RoutingComponent.SIZE_SMALL) {
      modSize = 1;
    } else if (length <= RoutingComponent.SIZE_MEDIUM) {
      modSize = 20;
    } else {
      modSize= 100;
    }

    return modSize;
  }

  // getters
  public get languageService(): LanguageService {
    return this._languageService;
  }
}
