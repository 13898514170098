<table id="{{id}}" mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" class="mat-elevation-z8 table-view">
  <ng-container *ngFor="let head of header; let i = index">
    <ng-container matColumnDef="{{head}}">
      <th mat-header-cell *matHeaderCellDef>{{languageService.getTranslationFromKeyword(head)}}</th>

      <td mat-cell *matCellDef="let value">{{value[head]}}</td>
      </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="header"></tr>
  <tr mat-row *matRowDef="let row; columns: header;"></tr>
</table>

<mat-paginator #paginator *ngIf="paginatorBool"
                 [pageSize]="5"
                 [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>


<div>
  <button mat-raised-button (click)="exporter.exportTable('xlsx')">Excel</button>
  <button mat-raised-button (click)="exporter.exportTable('csv')">Csv</button>
  <button mat-raised-button (click)="exporter.exportTable('txt')">Txt</button>
  <button mat-raised-button (click)="downloadPdf()" *ngIf="id === 'single'">Pdf</button>
</div>

