<h2 mat-dialog-title>{{globals.languageTable_res.get(2087)}}</h2>

<mat-dialog-content [formGroup]="licencePlateFg" class="flex-container">
  <div style="color: gray">
    <mat-icon class="small-icon dark-icon clickable">info</mat-icon>
    {{globals.languageTable_res.get(2094)}}
  </div>
  <br>
  <div>
    <input formControlName="licencePlate" matInput placeholder="{{licencePlate}}" required="true" minlength="2" maxlength="32">
  </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button class="dialog-controll-button" (click)="cancel()">{{globals.languageTable_res.get(2018)}}</button>
  <button class="dialog-controll-button" [disabled]="licencePlateFg.get('licencePlate').invalid" (click)="save()">{{globals.languageTable_res.get(2019)}}</button>
</mat-dialog-actions>
