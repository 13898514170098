import { Component, OnInit, Inject } from '@angular/core';
import { DialogResponse } from 'src/app/view/dialogResponse';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Geozone } from 'src/app/model/mdtdb/models';
import { DeleteObjectComponent } from '../../delete-object/delete-object.component';
import { DialogService } from 'src/app/service/dialog.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LanguageService, AuthService, InfoService } from 'src/app/service/api';
import { ValidationService } from 'src/app/service/validation.service';
import { GeozoneService } from 'src/app/generated-services/api';
import { GeozoneHasMailRecipient } from 'src/app/model/mdtdb/geozoneHasMailRecipient';
import { Status } from 'src/app/enumeration/status';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Globals } from 'src/app/globals';
import { TrackingEquipmentEntry } from 'src/app/view/trackingEquipmentEntry';

@Component({
  selector: 'app-radius-alarm',
  templateUrl: './radius-alarm.component.html',
  styleUrls: ['./radius-alarm.component.css']
})
export class RadiusAlarmComponent implements OnInit {
  private _geozone: Geozone;
  geozoneRadius: any;
  private _entry: TrackingEquipmentEntry;
  // set default mail recipient empty with mail address from logged in user
  private _mailRecipient: GeozoneHasMailRecipient = {};

  // true -> marks delete button as active if geozone exists - false -> delete button is disabled
  new: boolean = true;
  formChanged = false;

  // create input form with validators
  radiusAlarmFg: FormGroup = new FormGroup({
    radius: new FormControl('', [
      Validators.required, Validators.min(200) ]),
    firstMail: new FormControl(this._authService.loggedInUser.email,[
      Validators.required,
      Validators.pattern(ValidationService.VALIDATION_PATTERN_EMAIL)]),
    secondMail: new FormControl('',[
      Validators.pattern(ValidationService.VALIDATION_PATTERN_EMAIL)]),
    thirdMail: new FormControl('',[
      Validators.pattern(ValidationService.VALIDATION_PATTERN_EMAIL)])
  });

  constructor(private _authService: AuthService,
              @Inject(MAT_DIALOG_DATA) private _data: any,
              private _deleteObjectDialog: MatDialog,
              private _dialogRef: MatDialogRef<RadiusAlarmComponent>,
              private _dialogService: DialogService,
              private _geozoneService: GeozoneService,
              public globals: Globals,
              private _languageService: LanguageService,
              private _validationService: ValidationService) {
                this._geozone = _data.geozone;
                this._entry = _data.entry;
               }

  ngOnInit() {
    if (!this._geozone) {
      this._geozone = {};
      this._geozone.createdBy = this._authService.loggedInUser.username;
      this._mailRecipient.createdBy = this._authService.loggedInUser.username;
    } else {
      this.new = false;

      // load mail recipient for geozone
      this._geozoneService.geozoneGetMailRecipientsForGeozone(this._geozone.id, Status.ACTIVE).toPromise().then((recipients: GeozoneHasMailRecipient[]) => {
        if (recipients && recipients.length) {
          this._mailRecipient = recipients[0];

          this.initializeFormFields();
        }
      }).catch((err: HttpErrorResponse) => {
        this._validationService.validateHttpErrorResponse(err);
      });
    }
  }

  /** Cancel and kill dialog life cycle */
  public close() {
    this._dialogRef.close();
  }

  /* Create new radius alarm - geozone, equipment relation and recipient */
  private createRadiusAlarm() {
    var radius = this.radiusAlarmFg.controls['radius'].value;
    this._geozone.description = radius + GeozoneService.ALARM_IDENTIFIER + this._entry.licencePlate;
    this._geozone.createdBy = this._authService.loggedInUser.username;
    this._geozone.customerId = this._authService.selectedCustomer.id;

    this._geozoneService.geozoneCreateZoneStringFromRadius(this._entry.position.latitude, this._entry.position.longitude, radius).toPromise().then(
      zoneString => {
        if (zoneString) {
          this._geozone.zone = zoneString;

          this._geozoneService.geozoneCreate(this._geozone).toPromise().then(
            geozone => {
              if (geozone) {
                var idArr: string[] = new Array();
                idArr.push(this._entry.equipmentId);

                this._geozoneService.geozoneAddEquipmentsToGeozone(idArr, geozone.id, this._authService.loggedInUser.username, 'response').toPromise().then(
                  response => {
                    if (this._validationService.evaluateResponseStatus(response)) {
                      this._mailRecipient.mailAddressFirst = this.radiusAlarmFg.controls['firstMail'].value;
                      this._mailRecipient.mailAddressSecond = this.radiusAlarmFg.controls['secondMail'].value;
                      this._mailRecipient.mailAddressThird = this.radiusAlarmFg.controls['thirdMail'].value;
                      this._mailRecipient.geozoneId = geozone.id;

                      this._geozoneService.geozoneCreateGeozoneMailRecipient(this._mailRecipient, 'response').toPromise().then(
                        response => {
                          this._validationService.evaluateResponseStatus(response);
                        }
                      )
                    }
                  }
                )
              }
            }
          ).catch((err: HttpErrorResponse) => {
            this._validationService.validateHttpErrorResponse(err);
          });
        }
      }
    ).catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
    });

    this.close();
  }

  /* Validate input field to check if save button can be accessed */
  public disableSaveButton(): boolean {
    return this.radiusAlarmFg.get('radius').invalid || this.radiusAlarmFg.get('firstMail').invalid
          || this.radiusAlarmFg.get('secondMail').invalid || this.radiusAlarmFg.get('thirdMail').invalid
  }

  private initializeFormFields() {
    // fill form fields with attributes from mail recipient
    this.radiusAlarmFg.controls['firstMail'].setValue(this._mailRecipient.mailAddressFirst);
    this.radiusAlarmFg.controls['secondMail'].setValue(this._mailRecipient.mailAddressSecond);
    this.radiusAlarmFg.controls['thirdMail'].setValue(this._mailRecipient.mailAddressThird);

    // extract radius from geozone description
    var index = this._geozone.description.indexOf(GeozoneService.ALARM_IDENTIFIER);
    if (index && index > 0) {
      var radius = this._geozone.description.slice(0, index);
      this.radiusAlarmFg.controls['radius'].setValue(radius);
      this.geozoneRadius = radius;
    }

    // subscribe to check if form values has changed for update
    this.radiusAlarmFg.valueChanges.subscribe(selectedValued => {
      this.formChanged = true;
    });
  }

  /** Delete selected alarm notification */
  public openDeleteObjectDialog() {
    if (this._geozone.id) {
      const dialogRef = this._deleteObjectDialog.open(DeleteObjectComponent, {
        data: {
          description: this._geozone.description
        }
      });

      // wait for dialog is closed and check if deletion was submitted
      dialogRef.afterClosed().subscribe((response: DialogResponse) => {
        if (this._dialogService.evaluateResponse(response)) {
          if (response.data) {
            this._geozoneService.geozoneDelete(this._geozone.id, 'response').toPromise().then((response: HttpResponse<any>) => {
              this._validationService.evaluateResponseStatus(response);
            }).catch((err: HttpErrorResponse) => {
              this._validationService.validateHttpErrorResponse(err);
            });
          }
        }
      });
    }

    this._dialogRef.close();
  }

  public save() {
    if (this.new) {
      // TODO : persis geozone, ehg and msr
      this.createRadiusAlarm();
    } else {
      if (!this.formChanged) {
        // nothing happened - close dialog
        this.close();
      } else {
        this.updateRadiusAlarm();
      }
    }
  }

  /* Update existing geozone object if radius has changed */
  private updateGeozone() {
    this._geozone.modifiedBy = this._authService.loggedInUser.username;
    this._geozone.description = this.geozoneRadius + GeozoneService.ALARM_IDENTIFIER + this._entry.licencePlate;
    this._geozoneService.geozoneCreateZoneStringFromRadius(this._entry.position.latitude, this._entry.position.longitude, this.geozoneRadius).toPromise().then(
      zoneString => {
        if (zoneString) {
          this._geozone.zone = zoneString;

          this._geozoneService.geozoneUpdate(this._geozone).toPromise().then().catch((err: HttpErrorResponse) => {
              this.close();
              this._validationService.validateHttpErrorResponse(err);
          });
        }
    }).catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
    });
  }

  /* Update existing mail recipient object if email has changed */
  private updateMailRecipient() {
    this._mailRecipient.mailAddressFirst = this.radiusAlarmFg.controls['firstMail'].value;
    this._mailRecipient.mailAddressSecond = this.radiusAlarmFg.controls['secondMail'].value;
    this._mailRecipient.mailAddressThird = this.radiusAlarmFg.controls['thirdMail'].value;

    this._mailRecipient.modifiedBy = this._authService.loggedInUser.username;

    this._geozoneService.geozoneUpdateGeozoneMailRecipient(this._mailRecipient).toPromise().then().catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
    });
  }

  private updateRadiusAlarm() {
    // if radius changed update geozone
    if (!(this.radiusAlarmFg.controls['radius'].value === this.geozoneRadius)) {
      this.geozoneRadius = this.radiusAlarmFg.controls['radius'].value;
      this.updateGeozone();
    }

    // if mail recipients changed update ms
    if (this.radiusAlarmFg.controls['firstMail'].value !== this._mailRecipient.mailAddressFirst
        || this.radiusAlarmFg.controls['secondMail'].value !== this._mailRecipient.mailAddressSecond
        || this.radiusAlarmFg.controls['thirdMail'].value !== this._mailRecipient.mailAddressThird) {
      this.updateMailRecipient();
    }

    this.close();
  }

  // getters
  public getActivationDescription(): string {
      return this.globals.languageTable_res.get(2085) + ' ' + this._languageService.getLocaleDateTime(this._geozone.createdAt);
  }
}
