<div class="background-container">
  <div class="container">
    <div class="row">
      <div class="col s6 offset-s4" style="margin-left: auto; margin-right: auto; min-width: 600px">
        <div class="card grey lighten-2">
          <div class="card-tabs">
            <ul class="tabs tabs-fixed-width tabs-transparent">
              <li class="tab">
                <a class="unclickable">Passwort zurücksetzen</a>
              </li>
            </ul>
          </div>
          <div class="card-content white">
            <div class="row">
              Zum Anlegen oder Ändern des Passworts benötigen Sie einen Aktivierungscode.
              Haben Sie diesen bereits erhalten, dann können Sie hier das Passwort mit Hilfe des Codes pflegen.
              <br>
              <br>
              Ihr Passwort muss aus <b>mind. 8 Zeichen</b> bestehen und <b>jeweils ein Zeichen</b> aus den folgenden Kategorien enthalten:
              <br>
              <p style="font-weight: bold;">- Großbuchstaben der europäischen Sprachen</p>
              <p style="font-weight: bold;">- Kleinbuchstaben der europäischen Sprachen</p>
              <p style="font-weight: bold;">- Basis 10 Ziffern (0 bis 9)</p>
              <br>
              <form [formGroup]="ngForm" class="col s10" (ngSubmit)="resetPassword()">
                <div class="row">
                  <div class="input-field col s10 offset-s1" style="text-align:center">
                    <i class="material-icons prefix">fiber_pin</i>
                    <input formControlName="activationCode" type="text" #ActivationCode ngModel placeholder="Aktivierungscode" required>
                  </div>
                </div>
                <div class="row">
                  <div class="input-field col s10 offset-s1">
                    <i class="material-icons prefix clickable" (click)="hide = !hide" >{{hide ? 'visibility_off' : 'visibility'}}</i>
                    <input formControlName="password" [type]="hide ? 'password' : 'text'" placeholder="Passwort" required>
                  </div>
                </div>
                <div class="row">
                  <div class="input-field col s10 offset-s1">
                    <i class="material-icons prefix clickable" (click)="hide = !hide" >{{hide ? 'visibility_off' : 'visibility'}}</i>
                    <input formControlName="passwordRepetition" [type]="hide ? 'password' : 'text'" placeholder="Passwort wiederholen" required>
                    <ng-container *ngIf="!passwordsEqual()"><span style="color: red;">Passwörter müssen identisch sein</span></ng-container>
                  </div>
                </div>
                <div class="row">
                  <div class="input-field col s10 offset-s2">
                    <button [disabled]="!ngForm.valid || !passwordsEqual()" class="btn-large btn-submit" type="submit">Passwort zurücksetzen</button>
                  </div>
                </div>
                <div class="row">
                  <div class="input-field col s10 offset-s2">
                    <a routerLink='/password-forgotten' routerLinkActive='active'>
                      <button class="btn-large btn-submit">Aktivierungscode anfordern</button>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
