import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ProjectCommon } from '../project-common.class';
import { RoutingService } from './routing.service';
import { SessionService } from '../generated-services/session.service';
import { SessionInfo, User, Customer, AclObject } from '../model/mdtdb//models';
import { InfoService } from './info.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../generated-services/user.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomerService } from '../generated-services/customer.service';
import { Globals } from '../globals';
import { WebportalPermissionView } from '../view/webportalPermissionView';
import { SecurityService } from '../generated-services/security.service';
import { UserDenyAclObject } from '../model/mdtdb/userDenyAclObject';
import { Status } from '../enumeration/status';
import { LanguageService } from './language.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _loggedInUser: User;
  private _selectedCustomer: Customer;
  private _session: SessionInfo;
  public _customerModules: BehaviorSubject<AclObject[]> = new BehaviorSubject<any[]>(new Array());
  public _accountDenyPermissions: BehaviorSubject<UserDenyAclObject[]> = new BehaviorSubject<any[]>(new Array());
  public customerModernDrive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private _afAuth: AngularFireAuth,
              private _customerService: CustomerService,
              public globals: Globals,
              private _infoService: InfoService,
              private _languageService: LanguageService,
              private _routingService: RoutingService,
              private _securityService: SecurityService,
              private _sessionService: SessionService,
              private _userService: UserService) {

    this._afAuth.authState.subscribe(user => {
      if (user) {
        this._loggedInUser = user; // Setting up user data in loggedInUser var
      }
    });
  }

  // Unsubscribe from observables here and do other resource cleanup tasks.
  ngOnDestroy() {
  }

  public setSession(session: SessionInfo) {
    this._session = session;
  }

  public async sessionValidation(): Promise<boolean> {
    if (ProjectCommon.GetSessionId()) {
      const response = await this._sessionService.sessionAuthenticateWithCredentials(ProjectCommon.GetSessionId()).toPromise().catch( err => {
        this._routingService.navigateToLogin();
      });

      if (response && response.session) {
        this._session = response.session;

        this._selectedCustomer = response.customer;
        if (this.isCustomerModernDrive()) this.customerModernDrive.next(true);

        this._loggedInUser = response.user;

        await this.loadPermissions();
        // await this.loadCustomerModules();

        return true;
      } else {
        this._routingService.navigateToLogin();
        return false;
      }

    } else {
      this._routingService.navigateToLogin();
      return false;
    }
  }

  private async loadPermissions() {
    await this._securityService.securityWebportalPermissions(this._session.customerId, this._session.userId).toPromise().then((result: WebportalPermissionView) => {
      if (result && result.webportalV4Permission) {
          this._customerModules.next(result.aclObjects);
          this._accountDenyPermissions.next(result.userDenyAclObjects);
      } else {
        this._infoService.showInfoWindow(Globals.TYPE_ERROR, 'Keine Berechtigung für Portal V4');
        this.logout();
      }
    }).catch((err: HttpErrorResponse) => {
      this._infoService.showInfoWindow(Globals.TYPE_ERROR, 'Internal Server Error!');
      this.logout();
    });
  }

  public async customerLogin(customer, sessionId) {
    this._sessionService.sessionUpdateCustomer(customer, sessionId).toPromise().then((session: SessionInfo) => {
      if (session) {
        this._selectedCustomer = customer;

        // workaround to check if users language is set to english
        // user get method not working in service
        this._userService.userGetUsersForCustomer(session.customerId, Status.ACTIVE).toPromise().then((users: User[]) => {
          if (users && users.length) {
            var selectedUser = users.find(x => x.id === session.userId);
            if (selectedUser && (selectedUser.preferredLanguage === 'en-GB')) {
              this._languageService.setSelectedLang(LanguageService.SELECTED_LANG_EN);
              this._languageService.initializeLanguageTables(LanguageService.SELECTED_LANG_EN).subscribe(success => {
                if (success) {
                  this._routingService.redirectTo(RoutingService.ROUTE_TRACKING_FLEET);
                }
              });
            }
          }

          // TODO - implement welcome page identifier
          this._routingService.redirectTo(RoutingService.ROUTE_TRACKING_FLEET);
        });
      } else {
        // TODO return info text as application text from db
        this._infoService.showInfoWindow(Globals.TYPE_ERROR, 'No customer selected');
        this.logout();
      }
    }).catch((err: HttpErrorResponse) => {
      this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_ERROR);
      this.logout();
    });
  }

  public logout() {
    this.removeSessionItems();

    this._routingService.navigateToLogin();
  }

  public removeSessionItems() {
    this._loggedInUser = null;
    this._selectedCustomer = null;

    // check if session already exists
    if (ProjectCommon.GetSessionId()) {
      // remove session object in db
      this._sessionService.sessionDelete(ProjectCommon.GetSessionId(), 'response').subscribe();
      // remove session key in browser session storage
      ProjectCommon.RemoveSessionId();
    }
  }

  public isCustomerModernDrive() {
    if (this._selectedCustomer && this._selectedCustomer.id.toString() === 'c9bda01b-81c4-baf6-cfd3-2e50efaf0cb6') return true;
    return false;
  }

  public hasPermission(aclObjectId: string) {
    var result: boolean = false;
    if (aclObjectId && this._customerModules && this._customerModules.getValue() && this._customerModules.getValue().length) {
      if (this._customerModules.getValue().find(x => x.id === aclObjectId)) result = true;
    }

    return result;
  }

  public permissionDenied(aclObjectId: string) {
    var result: boolean = false;
    if (this._accountDenyPermissions && this._accountDenyPermissions.getValue() && this._accountDenyPermissions.getValue().length) {
      var o = this._accountDenyPermissions.getValue().find(y => y.aclObjectId === aclObjectId);

      if (o && o.denyReading) result = true;
    }

    return result;
  }

  // getters and setters
  public get loggedInUser(): User {
    return this._loggedInUser;
  }

  public set loggedInUser(user: User) {
    this._loggedInUser = user;
  }

  public get selectedCustomer(): Customer {
    return this._selectedCustomer;
  }

  public get session(): SessionInfo {
    return this._session;
  }
}
