<table id="entry-table">
  <tr>
    <th class="clickable">
      <span class="header-text">
        {{entry.geozone.description}}
        <ng-container *ngIf="!empty">
          <mat-icon class="primary-icon small clickable" (click)="toggleEntry()" style="float: right; font-size: 20px">keyboard_arrow_down</mat-icon>
        </ng-container>
    </span>
    </th>
  </tr>
  <ng-container *ngIf="expanded">
    <tr *ngFor="let info of entry.positionInfos">
      <td>
        <span class="standard-text">
          {{info.licencePlate}}
        </span>
        <span class="standard-text" style="float: right">
          {{languageService.getLocaleDateTime(info.position.recordingDatetime)}}
        </span>
      </td>
    </tr>
  </ng-container>
</table>
