import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/generated-services/user.service';
import { RoutingService } from 'src/app/service/routing.service';
import * as sha1 from 'sha1';
import { InfoService } from 'src/app/service/info.service';
import { Globals } from 'src/app/globals';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/service/validation.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingSpinnerComponent } from '../../filter/loading-spinner/loading-spinner.component';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent implements OnInit {

  dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hide: boolean = true;

  public ngForm: FormGroup = new FormGroup({
    activationCode: new FormControl('', [
      Validators.required,
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(ValidationService.VALIDATION_PATTERN_PASSWORD)]),
    passwordRepetition: new FormControl('', [
      Validators.required,
      Validators.pattern(ValidationService.VALIDATION_PATTERN_PASSWORD)])
  });

  constructor(private _loadingSpinnerDialog: MatDialog,
              private _infoService: InfoService,
              private _routingService: RoutingService,
              private _userService: UserService) { }

  ngOnInit() {
  }

  public passwordsEqual() {
    if (this.ngForm && this.ngForm.controls) {
      if (this.ngForm.controls['password'] && this.ngForm.controls['passwordRepetition'] && (this.ngForm.controls['password'].value === this.ngForm.controls['passwordRepetition'].value)) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  public resetPassword() {
    if (this.ngForm && this.ngForm.controls) {
      var key = this.ngForm.controls['activationCode'].value;
      var password = this.ngForm.controls['password'].value;
      var passwordRepetition = this.ngForm.controls['passwordRepetition'].value;

        console.log(key);
        console.log(password);
        console.log(passwordRepetition);

      if (!key || !password || !passwordRepetition) {
        console.log('all forms have to be filled');

        this._infoService.showInfoWindow(Globals.TYPE_ERROR, 'Alle Felder müssen gefüllt sein!');
      } else if (password !== passwordRepetition) {
        console.log('passwords not equal');

        this._infoService.showInfoWindow(Globals.TYPE_INFO, 'Passwörter müssen übereinstimmen!');
      } else {
        console.log('reset password');

        const dialogRef = this._loadingSpinnerDialog.open(LoadingSpinnerComponent, {
          data: {
            diameter: 57,
            loading: this.dataLoaded
          }
        });

        dialogRef.afterClosed().subscribe(x => {
          this.dataLoaded.next(false);
        });

        var cryptedP = this.encryptPassword(password);

        this._userService.userResetPassword(key, cryptedP).toPromise().then((result: boolean) => {
          this.dataLoaded.next(true);
          console.log(result);
          if (result) {
            // navigate to log in
            this._routingService.navigateToLogin();
          }
        }).catch((err: HttpErrorResponse) => {
          console.log(err);
          this._infoService.showInfoWindow(Globals.TYPE_INFO, err.error);
          this.dataLoaded.next(true);
        });
      }
    }
  }

  public encryptPassword(password) {
    return '*' + sha1(sha1(password, {asBytes: true}));
  }

}
