import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RoutingService, InfoService, AuthService, LanguageService } from 'src/app/service/api';
import { EquipmentGroup, EquipmentGroupContext, Equipment } from 'src/app/model/mdtdb/models';
import { GroupService, EquipmentService } from 'src/app/generated-services/api';
import { Status } from 'src/app/enumeration/status';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { DeleteObjectComponent } from 'src/app/component/dialogs/delete-object/delete-object.component';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { EquipmentGroupFormComponent } from 'src/app/component/dialogs/tracking/equipment-group-form/equipment-group-form.component';
import { EquipmentSelectionComponent } from 'src/app/component/dialogs/equipment-selection/equipment-selection.component';
import { DialogService } from 'src/app/service/dialog.service';
import { DialogResponse } from 'src/app/view/dialogResponse';
import { ValidationService } from 'src/app/service/validation.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-equipment-groups-overview',
  templateUrl: './equipment-groups-overview.component.html',
  styleUrls: ['./equipment-groups-overview.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EquipmentGroupsOverviewComponent implements OnInit {
  private _equipmentGroups: EquipmentGroup[];
  private _equipmentGroupContext: EquipmentGroupContext;
  private _equipmentsOfCustomer: Equipment[];
  private _dataSource = new MatTableDataSource<EquipmentGroup>();
  private _displayedColumns = ['description', 'createdBy', 'createdAt', 'equipments', 'details', 'delete'];

  constructor(private _authService: AuthService,
              private _deleteObjectDialog: MatDialog,
              private _dialogService: DialogService,
              private _equipmentGroupDialog: MatDialog,
              private _equipmentGroupForm: MatDialog,
              private _equipmentSelection: MatDialog,
              private _equipmentService: EquipmentService,
              public globals: Globals,
              private _groupService: GroupService,
              private _infoService: InfoService,
              private _languageService: LanguageService,
              private _validationService: ValidationService) { }

  async ngOnInit() {
    if(await this._authService.sessionValidation()) {
      this.initializeEquipmentGroups();
      this.initializeEquipmentsOfCustomer();
    }
  }

  public getNumberOfGroups() {
    if (this._equipmentGroups) {
      return this._equipmentGroups.length;
    }
  }

  /** Load all existing equipment groups of customer */
  private initializeEquipmentGroups() {
    this._groupService.groupGetEquipmentGroupContext(RoutingService.GROUP_CONTEXT_TRACKING).subscribe((context: EquipmentGroupContext) => {
      this._equipmentGroupContext = context;

      if (this._equipmentGroupContext) {
        this._groupService.groupGetEquipmentGroupsForCustomer(this._authService.session.customerId, this._equipmentGroupContext.id, Status.ACTIVE).toPromise().then((groups: EquipmentGroup[]) => {
          this._equipmentGroups = groups;

          // reload datasource for table
          this.reloadDataSource();
        }).catch((err: HttpErrorResponse) => {
          this._validationService.validateHttpErrorResponse(err);
        });
      }
    });
  }

  /** Load all equipments of customer from equipment service */
  private initializeEquipmentsOfCustomer() {
    this._equipmentService.equipmentGetEquipmentsForCustomer(this._authService.session.customerId, Status.ACTIVE).toPromise().then((equipments: Equipment[]) => {
      this._equipmentsOfCustomer = equipments;
    }).catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
    });
  }

  /** Delete selected equipment group */
  public openDeleteObjectDialog(group: EquipmentGroup) {
    const dialogRef = this._deleteObjectDialog.open(DeleteObjectComponent, {
      data: {
        description: group.groupName
      }
    });

    // wait for dialog is closed and check if deletion was submitted
    dialogRef.afterClosed().subscribe((response: DialogResponse) => {
      if (this._dialogService.evaluateResponse(response)) {
        if (response.data) {
          // deletion was submitted
          this._groupService.groupDeleteEquipmentGroup(group.id, 'response').toPromise().then((response: HttpResponse<any>) => {
            let result = this._validationService.evaluateResponseStatus(response);

            if (result) {
              this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_SUCCESS);
              this._equipmentGroups = this._equipmentGroups.filter(item => item !== group);
              // reload datasource for table
              this.reloadDataSource();
            }
          }).catch((err: HttpErrorResponse) => {
            this._validationService.validateHttpErrorResponse(err);
          });
        }
      }
    });
  }

  /** Open existing equipment group form */
  public openEquipmentGroupDialog(group: EquipmentGroup) {
    // open customer selection dialog
    const dialogRef = this._equipmentGroupForm.open(EquipmentGroupFormComponent, {
      data: {
        equipmentGroup: group,
        context: this._equipmentGroupContext,
        descriptions: this._equipmentGroups.map(x => x.groupName)
      }
    });

    // wait for dialog is closed and action is done
    dialogRef.afterClosed().subscribe((response: DialogResponse) => {
      if (this._dialogService.evaluateResponse(response)) {
        this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_SUCCESS);
        // reload datasource for table
        this.reloadDataSource();
      }
    });
  }

  /* Open equipment selection dialog */
  public async openEquipmentSelection(group: EquipmentGroup) {
    // TODO : load equipments of group
    await this._groupService.groupGetEquipmentsOfEquipmentGroup(group.id, Status.ACTIVE).toPromise().then((equipmentsOfGroup: Equipment[]) => {
      const dialogRef = this._equipmentSelection.open(EquipmentSelectionComponent, {
        data: {
          equipments: this._equipmentsOfCustomer,
          selectedEquipments: equipmentsOfGroup
        }
      })

      dialogRef.afterClosed().subscribe((response: DialogResponse) => {
        if (this._dialogService.evaluateResponse(response)) {
          var equipmentIds = EquipmentSelectionComponent.GetSelectedEquipmentIds(response.data);

          this._groupService.groupUpdateEquipmentRelationsOfEquipmentGroup(equipmentIds, group.id, this._authService.loggedInUser.username).toPromise().then((result: boolean) => {
            if (result) {
              this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_SUCCESS);
            } else {
              this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_ERROR);
            }
          }).catch(err => {
            console.log(JSON.stringify(err));
            this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_ERROR);
          });
        }
      });
    }).catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
    });
  }

  /** Open empty equipment group form */
  public openNewEquipmentGroupDialog() {
    const dialogRef = this._equipmentGroupForm.open(EquipmentGroupFormComponent, {
      data: {
        equipmentGroup: null,
        context: this._equipmentGroupContext,
        descriptions: this._equipmentGroups.map(x => x.groupName)
      }
    });

    // wait for dialog is closed and action is done
    dialogRef.afterClosed().subscribe((response: DialogResponse) => {
      if (this._dialogService.evaluateResponse(response)) {
        this._infoService.showInfoWindowWithStandardMessage(Globals.TYPE_SUCCESS);
        // add group to groups
        this._equipmentGroups.push(response.data);
        // order groups again by groupname
        this._equipmentGroups = this._equipmentGroups.sort((a, b) => a.groupName.localeCompare(b.groupName));
        // reload datasource for table
        this.reloadDataSource();
      }
    });
  }

  /** Refresh the data for data table */
  private reloadDataSource() {
    this._dataSource.data = this._equipmentGroups;
  }

  // getters
  public get dataSource(): MatTableDataSource<EquipmentGroup> {
    return this._dataSource;
  }

  public get displayedColumns(): string[] {
    return this._displayedColumns;
  }

  public get languageService(): LanguageService {
    return this._languageService;
  }
}
