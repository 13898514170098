import { GeometryType } from '../enumeration/geometry-type';
import { Geozone } from './mdtdb/models';
import {} from 'googlemaps';
declare const google: any;

export class Geometry {
  type?: GeometryType;
  geometryString?: string;
  coordinates?: google.maps.LatLng[];

  constructor() {
    this.coordinates = new Array();
  }

  public setGeozone(geozone: Geozone) {
    if (geozone && geozone.zone) {
      if (geozone.zone.includes(GeometryType.POLYGON)) {
        this.type = GeometryType.POLYGON;
      }

      this.geometryString = geozone.zone;
      this.setCoordinatesFromGeometryString();
    }
  }

  private setCoordinatesFromGeometryString() {
    var coords = null;
    var result = new Array();
    var indexOfHead = this.geometryString.indexOf('(') + 2;

    // remove header (type and opening brackets) from string
    this.geometryString = this.geometryString.substring(indexOfHead, this.geometryString.length);

    // remove tail (closing brackets) from string
    this.geometryString = this.geometryString.replace('))', '');

    coords = this.geometryString.split(',');

    // put each coordinate pair into array
    coords.forEach(element => {
      var child = element.split(' ');

      var latLng = new google.maps.LatLng(child[0], child[1]);
      result.push(latLng);
    });

    this.coordinates = result;
  }

  public static  GetGeometryZoneAsString(coordinates: google.maps.LatLng[], type: GeometryType): string {
    var head = type.toString() + '((';
    var tail = '))';
    var result = '';

    if (coordinates && coordinates.length) {
      // convert coordinates array to string model
      coordinates.forEach(element => {
        var str = element.lat().toString() + ' ' + element.lng().toString() + ','

        result = result + str;
      });
    }
    // add first coordinate at the end to close the ring
    result = result + coordinates[0].lat().toString() + ' ' + coordinates[0].lng().toString();

    // append header (geometry type + opening brackets) and tail (closing brackets)
    result = head + result + tail;

    return result;
  }

  public set setType(type: GeometryType) {
    this.type = type;
  }

  public set setCoordinates(coordinates: any) {
    this.coordinates = coordinates;
  }
}
