import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpEvent, HttpHeaders } from '@angular/common/http';
import { User } from '../model/mdtdb//models';
import { Observable } from 'rxjs';
import { Configuration } from '../generated-services/configuration';
import { AppConfigService } from '../app-config.service';

@Injectable()
export class UserServiceStandard {

  protected basePath = Configuration.getServiceBasePath();

  constructor(protected httpClient: HttpClient, private appConfig: AppConfigService) {
    this.basePath = appConfig.getConfig().webservice.url;
  }


}
