/**
 * @see https://juristr.com/blog/2018/01/ng-app-runtime-config/#how-does-the-app-initializer-work
 */
import { Injectable } from '@angular/core';
import { Configuration } from './generated-services/configuration';

@Injectable()
export class AppConfigService {
  private appConfig;

  constructor() { }

  loadAppConfig() {
    this.appConfig = { "webservice": { "url": Configuration.getServiceBasePath() } };
  }

  getConfig() {
    return this.appConfig;
  }
}
