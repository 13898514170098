import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatTableDataSource, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { GeoZoneEvaluationComponent } from '../geo-zone-evaluation/geo-zone-evaluation.component';
import { LanguageService } from 'src/app/service/api';
import { GeozoneTdEventRecord } from 'src/app/view/geozoneTdEventRecord';
import { GeozoneReport } from 'src/app/view/geozoneReport';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-geo-zone-report',
  templateUrl: './geo-zone-report.component.html',
  styleUrls: ['./geo-zone-report.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeoZoneReportComponent implements OnInit {

  private _dataSource = new MatTableDataSource<GeozoneTdEventRecord>();
  public displayedColumns = ['licencePlate', 'entrance', 'exit', 'duration'];
  private _description: string;
  private _report: GeozoneReport;
  private _from: Date;
  private _to: Date;

  constructor(@Inject(MAT_DIALOG_DATA) private _data: any,
              private _dialogRef: MatDialogRef<GeoZoneEvaluationComponent>,
              public globals: Globals,
              private _languageService: LanguageService) {
                this._report = _data.report;
                this._dataSource.data = _data.report.records;
                this._description = _data.report.description;
                this._from = _data.from;
                this._to = _data.to;
               }

  ngOnInit() {
  }

  /** Cancel and kill dialog life cycle */
  public cancel() {
    this._dialogRef.close();
  }

  // getters
  public get dataSource(): MatTableDataSource<GeozoneTdEventRecord> {
    return this._dataSource;
  }

  public get description(): string {
    return this._description;
  }

  public getDateTimeOfEntry(utc: any) {
    let res = this.globals.languageTable_res.get(2042);

    if (utc) {
      res = this._languageService.getLocaleDateTime(utc).toString();
    }

    return res;
  }

  public get from() {
    return this._from.toLocaleDateString() + " " + this._from.toLocaleTimeString();
  }

  public get to() {
    return this._to.toLocaleDateString() + " " + this._to.toLocaleTimeString();
  }

  public get languageService(): LanguageService {
    return this._languageService;
  }

  public get sumDuration(): string {
    return this._report.sumDuration;
  }
}
