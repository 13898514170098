import { AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.css']
})
export class LoadingSpinnerComponent implements OnInit, AfterViewInit {

  public diameter: number;
  public dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(@Inject(MAT_DIALOG_DATA) private _data: any,
              private _dialogRef: MatDialogRef<LoadingSpinnerComponent>,
              public globals: Globals,
              private ref: ChangeDetectorRef) {
                this.diameter = _data.diameter;
                this.dataLoaded = _data.loading;
                _dialogRef.disableClose = true;
              }

  ngOnInit() {
    this.dataLoaded.subscribe((result: boolean) => {
      if (result) {
        this._dialogRef.close();
      }
    })
  }

  ngAfterViewInit() : void {
    this.ref.detectChanges();
  }


}
