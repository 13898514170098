import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd, Event } from '@angular/router';
import { MatDrawer } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public appDrawer: MatDrawer;
  public currentUrl = new BehaviorSubject<string>(undefined);

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
  }

  public closeNavigation() {
    this.appDrawer.close();
  }

  public openNavigation(appDrawer: MatDrawer) {
    appDrawer.open();
  }

  public setAppDrawer(appDrawer: MatDrawer) {
    this.appDrawer = appDrawer;
  }
}
