import { Component, OnInit, HostBinding, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NavigationService } from 'src/app/service/navigation.service';
import { Router } from '@angular/router';
import { NavItem } from '../site-layout.component';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-sidenav-menu-list-item',
  templateUrl: './sidenav-menu-list-item.component.html',
  styleUrls: ['./sidenav-menu-list-item.component.css'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class SidenavMenuListItemComponent implements OnInit {
  expanded: boolean = true;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() depth: number;

  constructor(public globals: Globals,
              private _navigationService: NavigationService,
              private _router: Router) {
    if (this.depth === undefined) {
    this.depth = 0;
    }
  }

  ngOnInit() {
    this._navigationService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        // console.log(`Checking '/${this.item.route}' against '${url}'`);
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
        // console.log(`${this.item.route} is expanded: ${this.expanded}`);
      }
    });
  }

  onItemSelected(item: NavItem) {
    if (!item.children || !item.children.length) {
      this._router.navigate([item.route]);
      this._navigationService.closeNavigation();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }

  // getter
  public get router(): Router {
    return this._router;
  }
}
