import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { UserService } from 'src/app/generated-services/user.service';
import { Globals } from 'src/app/globals';
import { User } from 'src/app/model/mdtdb/user';
import { UserPasswordRequest } from 'src/app/model/mdtdb/userPasswordRequest';
import { InfoService } from 'src/app/service/info.service';
import { RoutingService } from 'src/app/service/routing.service';
import { LoadingSpinnerComponent } from '../../filter/loading-spinner/loading-spinner.component';

@Component({
  selector: 'app-password-forgotten',
  templateUrl: './password-forgotten.component.html',
  styleUrls: ['./password-forgotten.component.css']
})
export class PasswordForgottenComponent implements OnInit {

  dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _loadingSpinnerDialog: MatDialog,
              private _infoService: InfoService,
              private _routingService: RoutingService,
              private _userService: UserService) { }

  ngOnInit() {
  }


  public sendCode(username: string) {
    console.log('send code');
    console.log(username);

    const dialogRef = this._loadingSpinnerDialog.open(LoadingSpinnerComponent, {
      data: {
        diameter: 57,
        loading: this.dataLoaded
      }
    });

    dialogRef.afterClosed().subscribe(x => {
      this.dataLoaded.next(false);
    });

    this._userService.userSendPasswordActivationCode(username).toPromise().then((result: UserPasswordRequest) => {
      this.dataLoaded.next(true);
      if (result) {
        this._infoService.showInfoWindow(Globals.TYPE_SUCCESS, "Email wurde versendet!");
        // navigate to password reset page
        this._routingService.navigateToPath(RoutingService.PASSWORD_RECOVERY);
      }
    }).catch((err: HttpErrorResponse) => {
      this.dataLoaded.next(true);
      console.log(err);
      this._infoService.showInfoWindow(Globals.TYPE_INFO, err.error);
    });
  }
}
