<h3 mat-dialog-title>{{globals.languageTable_res.get(2131)}}</h3>

<mat-dialog-content style="width: 130px; height: 70px;">
  <div class="container">
    <mat-spinner
      [diameter]="diameter"
      mode="indeterminate">
    </mat-spinner>
  </div>
</mat-dialog-content>

