import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Geozone, Equipment } from 'src/app/model/mdtdb/models';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { DatetimeSelectionComponent } from 'src/app/component/filter/datetime-selection/datetime-selection.component';
import { LanguageService, InfoService } from 'src/app/service/api';
import { GeozoneService } from 'src/app/generated-services/api';
import { EquipmentSelectionComponent } from '../../equipment-selection/equipment-selection.component';
import { GeoZoneReportComponent } from '../geo-zone-report/geo-zone-report.component';
import { GeozoneReport } from 'src/app/view/geozoneReport';
import { DialogResponse } from 'src/app/view/dialogResponse';
import { DialogService } from 'src/app/service/dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidationService } from 'src/app/service/validation.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-geo-zone-evaluation',
  templateUrl: './geo-zone-evaluation.component.html',
  styleUrls: ['./geo-zone-evaluation.component.css']
})
export class GeoZoneEvaluationComponent implements OnInit {
  @ViewChild('dateTimeSelection', {static: false}) dateTimeSelection: DatetimeSelectionComponent;

  public showTable = false;

  private _equipmentsOfGeozone: Equipment[] = new Array();
  private _selectedEquipments: Equipment[] = new Array();
  private _geozone: Geozone;

  constructor(@Inject(MAT_DIALOG_DATA) private _data: any,
              private _dialogRef: MatDialogRef<GeoZoneEvaluationComponent>,
              private _dialogService: DialogService,
              private _equipmentSelection: MatDialog,
              private _geozoneService: GeozoneService,
              private _infoService: InfoService,
              public globals: Globals,
              private _validationService: ValidationService) {
    this._geozone = _data.geozone;
    this._equipmentsOfGeozone = _data.equipmentsOfGeozone;
    this._selectedEquipments = _data.equipmentsOfGeozone;
   }

  ngOnInit() {
  }

  /** Cancel and kill dialog life cycle */
  public cancel() {
    this._dialogRef.close();
  }

  /** Execute. */
  public execute() {
    this._geozoneService.geozoneGetGeozoneReport(this._selectedEquipments.map(e => e.id), this._geozone.id, this.dateTimeSelection.getFromDate(),
      this.dateTimeSelection.getToDate()).toPromise().then((report: any) => {
        this.openReportDialog(report);
    }).catch((err: HttpErrorResponse) => {
      this._validationService.validateHttpErrorResponse(err);
    });
  }

  /* Open equipment selection dialog */
  public openEquipmentSelection() {
    const dialogRef = this._equipmentSelection.open(EquipmentSelectionComponent, {
      data: {
        equipments: this._equipmentsOfGeozone,
        selectedEquipments: this._selectedEquipments
      }
    });

    dialogRef.afterClosed().subscribe((response: DialogResponse) => {
      if (this._dialogService.evaluateResponse(response)) {
        this._selectedEquipments = response.data;
      }
    });
  }

  /** Open dialog with geozone report. */
  public openReportDialog(report: GeozoneReport) {
    if (!report || !report.records || !report.records.length) {
      this._infoService.showInfoWindow(Globals.TYPE_INFO, this.globals.languageTable_res.get(2042));
      this.showTable = false;
    } else {
      const dialogRef = this._equipmentSelection.open(GeoZoneReportComponent, {
        data: {
          report: report,
          geozone: this._geozone,
          from: this.dateTimeSelection.getFromDate(),
          to: this.dateTimeSelection.getToDate()
        }
      });
    }
  }

  // getters
  public get equipmentsOfGeozone(): Equipment[] {
    return this._equipmentsOfGeozone;
  }

  public get geozone(): Geozone {
    return this._geozone;
  }

  public get selectedEquipments(): Equipment[] {
    return this._selectedEquipments;
  }
}
