import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Equipment, Geozone } from 'src/app/model/mdtdb/models';
import { LanguageService, InfoService, AuthService } from 'src/app/service/api';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import {} from 'googlemaps';
import { Status } from 'src/app/enumeration/status';
import { GeozoneService } from 'src/app/generated-services/geozone.service';
import { Geometry } from 'src/app/model/Geometry';
import { GeometryType } from 'src/app/enumeration/geometry-type';
import { RoutingComponent } from '../routing/routing.component';
import { ValidationService } from 'src/app/service/validation.service';
import { DialogResponse } from 'src/app/view/dialogResponse';
import { DialogService } from 'src/app/service/dialog.service';
import { GoogleMapComponent } from 'src/app/component/tracking/google-map/google-map.component';
import { BehaviorSubject } from 'rxjs';
import { Definitions } from 'src/app/definitions';
import { HttpErrorResponse } from '@angular/common/http';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-geozone-form',
  templateUrl: './geozone-form.component.html',
  styleUrls: ['./geozone-form.component.css']
})
export class GeozoneFormComponent implements OnInit {
  @ViewChild('mapComponent', {static: false}) mapComponent: GoogleMapComponent;

  private _equipmentsOfCustomer: Equipment[] = new Array();
  private _routingEquipment: Equipment;
  private _geozone: Geozone;
  private _geometry: Geometry = new Geometry();
  private _descriptions = new Array();

  positionsPermission: boolean = true;

  mapReadyState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  mapType = Definitions.MAP_TYPE_ROADMAP;
  searchBox = true;
  drawingManager = true;
  defaultPolyColor = Definitions.DEFAULT_MAP_POLY_COLOR;

  geozoneFg: FormGroup;
  response: DialogResponse = {
    responseCode: DialogService.RESPONSE_CODE_ERROR,
    data: null
  };

  constructor(private _authService: AuthService,
              @Inject(MAT_DIALOG_DATA) private _data: any,
              private _dialogRef: MatDialogRef<GeozoneFormComponent>,
              private _dialogService: DialogService,
              private _geozoneService: GeozoneService,
              private _infoService: InfoService,
              public globals: Globals,
              private _routingDialog: MatDialog,
              private _validationService: ValidationService) {
                this._geozone = _data.geozone;
                this._descriptions = _data.descriptions;
                this._equipmentsOfCustomer = _data.equipmentsOfCustomer;

                // wait for map fully load to draw zone
                this.mapReadyState.subscribe((ready: boolean) => {
                  if (ready) {
                    this._geometry.setGeozone(this._geozone);
                    this.initializeZoneDrawing();
                    this.mapReadyState.unsubscribe();
                  }
                })
               }

  ngOnInit() {
    this.geozoneFg = new FormGroup({
      description: new FormControl('')
    });

    if (this._geozone && this._geozone.id) {
      this.geozoneFg = new FormGroup({
        description: new FormControl(this._geozone.description, [ Validators.required, Validators.minLength(2) ])
      });
    }

    this.positionsPermission = !this._authService.permissionDenied(Globals.ID_ACL_POSITIONS);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.mapReadyState.unsubscribe();
  }

  /** Add listeners to geozone if zone is edited. */
  private addChangeListenerToGeozone(shape: google.maps.Polygon) {
    if (shape) {
      google.maps.event.addListener(shape.getPath(), 'set_at', () => {
        this.mapComponent.zoneCoordinates = shape.getPath().getArray();
      });

      google.maps.event.addListener(shape.getPath(), 'insert_at', () => {
        this.mapComponent.zoneCoordinates = shape.getPath().getArray();
      });
      this._geometry.setGeozone(this._geozone);
    }
  }

  /** Cancel and kill dialog life cycle */
  public cancel() {
    this.geozoneFg.reset();
    this.response.responseCode = DialogService.RESPONSE_CODE_CANCEL;
    this._dialogRef.close(this.response);
  }

  /** Create geozone object for user input */
  private createGeozoneObject(): Geozone {
    var zone = Geometry.GetGeometryZoneAsString(this.mapComponent.zoneCoordinates, GeometryType.POLYGON);

    var geozone: Geozone = {
      customerId: this._authService.session.customerId,
      description: this.geozoneFg.value.description,
      zone: zone,
      status: Status.ACTIVE,
      createdAt: new Date(),
      createdBy: this._authService.loggedInUser.username
    };

    return geozone;
  }

  /** Store created geozone object in db */
  private async createNewGeozone() {
    if(this.inputValidation()) {
      // create geozone object
      var geozone = this.createGeozoneObject();

      if (geozone) {
        // store geozone object
        await this._geozoneService.geozoneCreate(geozone).toPromise().then((result: Geozone) => {
          if (result) {
            this.response.responseCode = DialogService.RESPONSE_CODE_SUCCESS;
            this.response.data = result;
          }
        }).catch((err: HttpErrorResponse) => {
          this._validationService.validateHttpErrorResponse(err);
        });

        this._dialogRef.close(this.response);
      }
    }
  }

  /** Draw existing polygon from geozone on google map and set listeners for it. */
  private initializeZoneDrawing() {
    if (this._geometry.coordinates && this._geometry.coordinates.length) {
      // todo fit bounds
      // create zoom and center from position list
      // create bounds array to calculate zoom and center of map depending on coordinates
      let bounds = new google.maps.LatLngBounds();

      for(let p of this._geometry.coordinates) {
        bounds.extend(p);
      }

      // Construct the polygon.
      var myPolygon = new google.maps.Polygon({
        paths: this._geometry.coordinates,
        draggable: true, // turn off if it gets annoying
        editable: true,
        strokeColor: this.defaultPolyColor,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: this.defaultPolyColor,
        fillOpacity: 0.35
      });

      myPolygon.setMap(this.mapComponent.map);
      this.addChangeListenerToGeozone(myPolygon);
      this.mapComponent.shapes.push(myPolygon);
      this.mapComponent.zoneCoordinates = this._geometry.coordinates;

      // adjust map center and zoom to polygon bounds
      if (bounds) {
        this.mapComponent.map.fitBounds(bounds);
      }
    }
  }

  /** Validate description field not empty and length < 128 (db limit) and
   *  not already in use andminimum 3 points for polygon are created.
   */
  private inputValidation(): boolean {
    return this._validationService.inputLengthValidation(this.geozoneFg.controls['description'].value, 128)
      && this._validationService.inputAlreadyExistsValidation(this.geozoneFg.controls['description'].value, this._descriptions,
      this._geozone ? this._geozone.description : undefined)
      && this._validationService.geozoneDrawingValidation(this.mapComponent.zoneCoordinates);
  }

  /* Open dialog for routing options for selected equipment */
  public openRoutingDialog() {
    if (this._routingEquipment) {
      const dialogRef = this._routingDialog.open(RoutingComponent, {
        data: {
          id: this._routingEquipment.id,
          licencePlate: this._routingEquipment.licencePlate,
          map: this.mapComponent.map
        }
      });

      dialogRef.afterClosed().subscribe((response: DialogResponse) => {
        console.log(response);
        if (this._dialogService.evaluateResponse(response)) {
          // do nothing - route gets drawed in routing component
        }
      });
    } else {
      this._infoService.showInfoWindow(Globals.TYPE_INFO, this.globals.languageTable_res.get(2059));
    }
  }

  /** Save user input if button is clicked. */
  public save() {
    if (this._geozone == null || this._geozone == undefined) {
      this.createNewGeozone();
    } else {
      this.updateGeozone();
    }
  }

  /**
   * Set equipment for routing.
   */
  public selectRouteEquipment(equipment: Equipment) {
    this._routingEquipment = equipment;
  }

  /**
   * Append user input to already existing geozone and store changes in db.
   */
  private async updateGeozone() {
    if (this.inputValidation()) {
      this._geozone.description = this.geozoneFg.controls['description'].value;
      this._geozone.zone = Geometry.GetGeometryZoneAsString(this.mapComponent.zoneCoordinates, GeometryType.POLYGON);
      this._geozone.modifiedBy = this._authService.loggedInUser.username;
      this._geozone.modifiedAt = new Date();

      await this._geozoneService.geozoneUpdate(this._geozone).toPromise().then((zone: Geozone) => {
        if (zone) {
          this.response.responseCode = DialogService.RESPONSE_CODE_SUCCESS;
          this.response.data = zone;
        }
      }).catch((err: HttpErrorResponse) => {
        this._validationService.validateHttpErrorResponse(err);
      });

      this._dialogRef.close(this.response);
    }
  }

  // getters
  public get equipmentsOfCustomer(): Equipment[] {
    return this._equipmentsOfCustomer;
  }

  public get geozone(): Geozone {
    return this._geozone;
  }
}
